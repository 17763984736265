// 가상요소
@mixin pseudo {
  content: '';
  position: absolute;
  z-index: 1;
}
// text
@mixin ellipse {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@mixin ellipse_2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

// 정렬
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
}
@mixin center_top {
  @include center;
  left: 0;
  transform: translate(0, -50%);
}
@mixin center_left {
  @include center;
  top: 0;
  transform: translate(-50%, 0);
}

// scrollbar
@mixin scroll_custom {
  &::-webkit-scrollbar {
    width: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color_gray16;
    border-radius: 10px;
    background-clip: padding-box;
    border: 8px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
  @media screen and (max-width: 768px) {
    &::-webkit-scrollbar {
      width: 5px;
    }
  }
}
@mixin scroll_custom_btm {
  &::-webkit-scrollbar {
    height: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $color_gray16;
    border-radius: 10px;
    background-clip: padding-box;
    border: 8px solid transparent;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
}

// typo
@mixin typo_display1 {
  font-weight: 700;
  font-size: 80px;
  line-height: 125%;
}
@mixin typo_header1 {
  font-weight: 700;
  font-size: 60px;
  line-height: 125%;
}
@mixin typo_header2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
}
@mixin typo_header3 {
  font-weight: 700;
  font-size: 36px;
  line-height: 120%;
}
@mixin typo_header4 {
  font-weight: 700;
  font-size: 28px;
  line-height: 120%;
}
@mixin typo_header5 {
  font-weight: 700;
  font-size: 24px;
  line-height: 145%;
}
@mixin typo_title1 {
  font-weight: 500;
  font-size: 20px;
  line-height: 160%;
}
@mixin typo_body1 {
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
}
@mixin typo_body2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
}
@mixin typo_body3 {
  font-weight: 400;
  font-size: 12px;
  line-height: 135%;
}
