// font
$en: poppins, sans-serif;
$ko: 'Pretendard Variable', 'Pretendard-Regular', sans-serif;

// colors
$color_white: #ffffff;
$color_black: #000000;

$color_primary: #5a74ce;
$color_secondary: #4876ee;
$color_error: #ef3d3d;

$color_point1: #6cc4e9;
$color_point2: #185cdc;
$color_point3: #3d4543;
$color_point4: #a0bbc0;
$color_point5: #e37363;
$color_point6: #283fb4;
$color_point7: #7c809f;
$color_point8: #3b46ae;
$color_point9: #b9c3d7;

$color_bgray1: #050d1b;
$color_bgray2: #282e3b;
$color_bgray3: #454b5a;
$color_bgray4: #585e6d;
$color_bgray5: #7e8595;
$color_bgray6: #a0a6b7;
$color_bgray7: #c5ccdd;
$color_bgray8: #dae0ee;
$color_bgray9: #e2e7f4;
$color_bgray10: #eff2f9;
$color_bgray11: #f7f8fd;

$color_gray1: #1f1f1f;
$color_gray2: #2d2d2d;
$color_gray3: #404040;
$color_gray4: #5f5f5f;
$color_gray5: #727272;
$color_gray6: #9b9b9b;
$color_gray7: #bbbbbb;
$color_gray8: #dedede;
$color_gray9: #ececec;
$color_gray10: #f4f4f4;
$color_gray11: #fafafa;
$color_gray12: #717171;
$color_gray13: #343434;
$color_gray14: #c7c7c7;
$color_gray15: #4d4d4d;
$color_gray16: #d5d5d5;
$color_gray17: #d9d9d9;
$color_grayDark: #4f555e;
