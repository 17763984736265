.news {
  [data-contain] {
    display: none;
    &.active {
      display: block;
    }
  }

  .active + .active .list_wrap4 {
    border-top: 0;
  }

  .card_cont1 {
    .date {
      margin-top: 8px;
    }
    .desc {
      height: 32px;
      overflow: hidden;
      display: block;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 456px;
      * {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
      @media screen and (max-width: 1600px) {
        font-size: 20px;
        line-height: 160%;
      }
    }
  }
}
