//브랜드 > BYD
.byd {
  background: #000;
  overflow: hidden;
  .contain-byd1 {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    text-align: center;
    overflow: hidden;
    // background: url(../../images/img/img_brand_byd_visual.jpg) no-repeat 50%;
    // background-size: cover;
    .txt-slogun {
      position: absolute;
      bottom: 12%;
      font-size: 80px;
      font-weight: 500;
      line-height: 125%;
      color: #fff;
      z-index: 20;
      .txt_slo_sub {
        display: block;
        font-size: 48px;
        line-height: 125%;
        color: #fff;
      }
    }
    .react-player {
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      z-index: 10;
    }
  }
  .contain-byd2 {
    padding: 120px 0 160px 0;
    background: url(../../images/img/img_brand_byd_bg1.jpg) no-repeat 50% top;
    background-size: 100% auto;
    .tit {
      margin: 0 auto 15px;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
      color: #fff;
    }
    .txt-desc {
      margin: 0 auto 96px;
      max-width: 1000px;
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      text-align: center;
      color: #fff;
    }
    .box-items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: 0 auto;
      max-width: 1548px;
      .item {
        display: flex;
        justify-content: flex-start;
        margin: 10px 10px;
        padding: 40px 42px;
        width: calc(25% - 20px);
        height: 324px;
        text-align: center;
        // background: rgba(255, 255, 255, 0.1);
        border: 1px solid rgba(255, 255, 255, 0.3);
        box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        &.img1 {
          background: url('../../images/img/img_brand_byd_life1.png') no-repeat 50%;
          background-size: cover;
        }
        &.img2 {
          background: url('../../images/img/img_brand_byd_life2.png') no-repeat 50%;
          background-size: cover;
        }
        &.img3 {
          background: url('../../images/img/img_brand_byd_life3.png') no-repeat 50%;
          background-size: cover;
        }
        &.img4 {
          background: url('../../images/img/img_brand_byd_life4.png') no-repeat 50%;
          background-size: cover;
        }
        .txt-item {
          font-size: 20px;
          font-weight: 500;
          line-height: 160%;
          color: #d5d8db;
          text-align: left;
          strong {
            display: block;
            font-size: 28px;
            font-weight: 700;
            line-height: 145%;
            color: #fff;
            white-space: nowrap;
          }
        }
      }
    }
  }
  .contain-byd3 {
    padding: 0 0 160px 0;
    > .tit {
      & br:last-of-type {
        display: none;
      }
      margin: 0 auto 36px;
      padding: 96px 24px 0;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
      color: #fff;
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        margin: 0 0 0 -47px;
        width: 93px;
        height: 56px;
        background: url(../../images/ico/ico_logo_byd4.svg) no-repeat 50%;
        background-size: cover;
      }
    }
    .txt-desc {
      margin: 0 auto 16px;
      padding: 0 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      text-align: center;
      color: #bbb;
    }
    .box-category {
      margin: 0 auto;
      padding: 160px 0 0;
      max-width: 1528px;
      .item {
        display: flex;
        flex-direction: row;
        .img {
          width: 50%;
          height: 498px;
          background: url(../../images/img/img_brand_byd_solution.jpg) no-repeat 50%;
          background-size: cover;
        }
        .desc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 40px 0 120px;
          width: 50%;
          .tit {
            margin-bottom: 12px;
            font-size: 40px;
            font-weight: 500;
            line-height: 135%;
            font-family: $en;
            color: #fff;
          }
          .txt-desc {
            margin: 0 0 20px 0;
            padding: 0;
            font-size: 20px;
            font-weight: 500;
            line-height: 160%;
            text-align: left;
          }
          .detail {
            li {
              position: relative;
              padding: 3px 0 0 110px;
              margin: 14px 0;
              font-size: 16px;
              font-weight: 400;
              line-height: 150%;
              .tag {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 90px;
                height: 32px;
                color: #4876ee;
                text-align: center;
                line-height: 32px;
                white-space: nowrap;
                border: 1px solid #4876ee;
                border-radius: 20px;
              }
            }
          }
        }
        &:nth-child(2) {
          flex-direction: row-reverse;
          .img {
            background: url(../../images/img/img_brand_byd_battery.jpg) no-repeat 50%;
            background-size: cover;
          }
        }
        &:nth-child(3) {
          flex-direction: row;
          .img {
            background: url(../../images/img/img_brand_byd_plant.jpg) no-repeat 50%;
            background-size: cover;
          }
        }
      }
    }
  }
  .contain-byd4 {
    padding: 120px 0 0;
    .tit {
      margin: 0 auto 34px;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
      color: #fff;
    }
    .txt-desc {
      margin: 0 auto 16px;
      padding: 0 24px;
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      text-align: center;
      color: #bbb;
      br:not(:nth-of-type(4)) {
        display: none;
      }
    }
    .box-people {
      position: relative;
      height: 970px;
      background: url('../../images/img/img_brand_byd_people.png') no-repeat 50% top;
      background-size: auto 100%;
      [class^='people'] {
        position: absolute;
        left: 50%;
        top: 0;
        margin: 0;
        span {
          display: block;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          color: rgba(255, 255, 255, 0.6);
          &.name {
            display: block;
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            color: #fff;
          }
        }
      }
      .people1 {
        margin: 544px 0 0 -440px;
      }
      .people2 {
        margin: 410px 0 0 62px;
      }
      .people3 {
        margin: 614px 0 0 390px;
      }
    }
  }
  .contain-byd5 {
    position: relative;
    padding: 120px 0 160px;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 354px;
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.3);
    }
    .tit {
      margin: 0 auto 82px;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
      color: #fff;
    }
    .contain-history {
      display: flex;
      padding: 4px 0 0 calc(100vw / 2 - 768px);
      overflow: auto;
      &::-webkit-scrollbar {
        display: none; /* Chrome, Safari, Opera*/
      }
      .item {
        position: relative;
        width: 305px;
        padding: 20px;
        text-align: center;
        flex-grow: 0;
        flex-shrink: 0;
        &::before {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 0;
          margin-left: -8px;
          width: 16px;
          height: 4px;
          background: #4876ee;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 86px;
          margin-left: -6px;
          width: 12px;
          height: 12px;
          background: #ffffff;
          border-radius: 100%;
        }
        .year {
          margin: 0 0 78px 0;
          font-size: 24px;
          font-weight: 700;
          line-height: 145%;
          color: #fff;
        }
        // .detail {
        //   display: inline-block;
        // }
        .desc {
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          line-height: 150%;
          color: #9b9b9b;
          text-align: left;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .contain-byd1 {
      justify-content: center;
      .txt-slogun {
        font-size: 40px;
        .txt_slo_sub {
          margin-top: 24px;
          font-weight: 500;
          font-size: 28px;
          line-height: 125%;
        }
      }
    }
    .contain-byd2 {
      padding: 87px 0;
      .tit {
      }
      .txt-desc {
      }
      .box-items {
        padding: 0 10px;
        .item {
          padding: 28px 34px;
          width: calc(50% - 20px);
          height: 324px;
          .txt-item {
            font-size: 14px;
            strong {
              font-size: 18px;
            }
          }
        }
      }
    }
    .contain-byd3 {
      padding-bottom: 0;
      > .tit {
        &::before {
        }
      }
      .txt-desc {
      }
      .box-category {
        padding-top: 90px;
        padding-bottom: 30px;
        .item {
          display: block;
          margin-bottom: 40px;
          .img {
            margin-bottom: 40px;
            margin-left: 24px;
            margin-right: 0;
            width: calc(100% - 24px);
            height: 50vw;
            min-height: 216px;
          }
          .desc {
            width: auto;
            padding: 0 24px;
            .tit {
            }
            .txt-desc {
            }
            .detail {
              li {
                .tag {
                }
              }
            }
          }
          &:nth-child(2) {
            .img {
              margin-left: 0;
              margin-right: 24px;
            }
          }
          &:nth-child(3) {
            .img {
              margin-left: 24px;
              margin-right: 0;
            }
          }
        }
      }
    }
    .contain-byd4 {
      .tit {
      }
      .txt-desc {
      }
      .box-people {
        [class^='people'] {
          span {
            &.name {
            }
          }
        }
        .people1 {
        }
        .people2 {
        }
        .people3 {
        }
      }
    }
    .contain-byd5 {
      &::after {
      }
      .tit {
      }
      .contain-history {
        .item {
          &::before {
          }
          &::after {
          }
          .year {
          }
          .detail {
            li {
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .contain-byd2 {
      .tit {
        font-size: 30px;
        font-weight: 700;
        line-height: 125%;
      }
      .txt-desc {
        margin-bottom: 60px;
        font-size: 18px;
        font-weight: 700;
        line-height: 135%;
      }
      .box-items {
        .item {
          padding: 20px;
          height: 204px;
          .txt-item {
            strong {
            }
          }
        }
      }
    }
    .contain-byd3 {
      > .tit {
        & br:last-of-type {
          display: block;
        }
        margin-bottom: 24px;
        font-size: 30px;
        font-weight: 700;
        line-height: 125%;
        &::before {
          width: 66px;
          height: 40px;
          margin-left: -33px;
        }
      }
      .txt-desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
      }
      .box-category {
        padding: 80px 0 0;
        .item {
          display: block;
          margin: 0;
          .img {
            margin-bottom: 24px;
            height: 50vw;
            min-height: 216px;
          }
          .desc {
            padding: 0 24px 80px;
            .tit {
              font-size: 24px;
              font-weight: 500;
              line-height: 135%;
            }
            .txt-desc {
              margin-bottom: 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 165%;
            }
            .detail {
              margin-bottom: 60px;
              li {
                padding: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 165%;
                .tag {
                  position: static;
                  margin: 0 0 6px 0;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 25px;
                  height: 27px;
                }
              }
            }
          }
          &:nth-child(2) {
            .img {
            }
          }
          &:nth-child(3) {
            .img {
            }
          }
        }
      }
    }
    .contain-byd4 {
      padding: 0;
      .tit {
        font-size: 30px;
        font-weight: 700;
        line-height: 125%;
      }
      .txt-desc {
        margin-bottom: 70px;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        br:not(:nth-of-type(4)) {
          display: block;
        }
      }
      .box-people {
        height: auto;
        background: none;
        [class^='people'] {
          display: none;
          span {
            &.name {
            }
          }
        }
        .people1 {
        }
        .people2 {
        }
        .people3 {
        }
      }
    }
    .contain-byd5 {
      padding: 56px 0 95px;
      &::after {
        top: 246px;
      }
      .tit {
        font-size: 30px;
        line-height: 125%;
      }
      .contain-history {
        .item {
          width: 200px;
          &::before {
          }
          &::after {
            top: 61px;
          }
          .year {
            margin-bottom: 56px;
            font-size: 18px;
            line-height: 135%;
          }
          .detail {
            li {
              font-size: 14px;
              line-height: 165%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}

//브랜드 > GDG
.gsg {
  .contain-gsg1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background: url(../../images/img/img_brand_gsg_visual.jpg) no-repeat 50%;
    background-size: cover;
    .txt-slogun {
      font-size: 80px;
      font-weight: 500;
      line-height: 125%;
      color: #fff;
    }
    .txt-desc {
      margin-top: 24px;
      font-size: 28px;
      font-weight: 700;
      line-height: 145%;
      color: #fff;
    }
  }
  .contain-gsg2 {
    margin: 0 auto;
    padding: 120px 80px;
    .txt-desc {
      margin-bottom: 50px;
      font-size: 28px;
      font-weight: 700;
      line-height: 145%;
      text-align: center;
    }
    .box-infor {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      max-width: 1528px;
      .article-rate {
        width: 456px;
        border-top: 1px solid #000;
        .txt-head {
          display: block;
          margin: 30px 0 20px;
          font-size: 20px;
          font-weight: 500;
          line-height: 160%;
          color: #404040;
        }
        .txt-cont {
          display: block;
          font-size: 60px;
          font-weight: 500;
          line-height: 125%;
          font-family: $en;
          strong {
            font-size: 60px;
            font-weight: 500;
            line-height: 125%;
            font-family: $en;
          }
        }
      }
    }
  }
  .contain-gsg3 {
    .box-slogun {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 540px;
      background: url(../../images/img/img_brand_gsg_section2.jpg) no-repeat 50%;
      background-size: cover;
      .txt-slogun {
        font-size: 48px;
        font-weight: 700;
        line-height: 120%;
        color: #fff;
        text-align: center;
      }
    }
    .box-category {
      margin: 0 auto;
      padding: 160px 0;
      max-width: 1528px;
      .item {
        display: flex;
        flex-direction: row-reverse;
        .img {
          width: 50%;
          height: 498px;
          background: url(../../images/img/img_brand_gsg_trading.jpg) no-repeat 50%;
          background-size: cover;
        }
        .desc {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 0 80px;
          width: 50%;
          .tit {
            margin-bottom: 12px;
            font-size: 40px;
            font-weight: 500;
            line-height: 135%;
            font-family: $en;
          }
          .txt-desc {
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: 500;
            line-height: 160%;
          }
          .detail {
            li {
              position: relative;
              padding: 3px 0 0 110px;
              margin: 14px 0;
              font-size: 16px;
              font-weight: 400;
              line-height: 150%;
              word-break: break-all;
              .tag {
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 90px;
                height: 32px;
                color: #4876ee;
                text-align: center;
                line-height: 32px;
                white-space: nowrap;
                border: 1px solid #4876ee;
                border-radius: 20px;
              }
            }
          }
        }
        &:nth-child(2) {
          flex-direction: row;
          .img {
            background: url(../../images/img/img_brand_gsg_mobility.jpg) no-repeat 50%;
            background-size: cover;
          }
        }
        &:nth-child(3) {
          flex-direction: row-reverse;
          .img {
            background: url(../../images/img/img_brand_gsg_green.jpg) no-repeat 50%;
            background-size: cover;
          }
        }
      }
    }
  }
  .contain-gsg4 {
    position: relative;
    padding: 125px 0 115px 0;
    .txt-slogun {
      position: relative;
      z-index: 1;
      margin-bottom: 60px;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
      color: #fff;
    }
    .box-feature {
      position: relative;
      z-index: 1;
      .box-items1 {
        display: flex;
        justify-content: space-between;
        margin: 0 auto 43px;
        width: 1000px;
        .item {
          width: 320px;
          font-size: 28px;
          font-weight: 600;
          line-height: 150%;
          color: #bbb;
          text-align: center;
          font-family: $en;
          opacity: 0.6;
        }
        .space {
          width: 20px;
          height: 40px;
          background: url(../../images/ico/ico_by.png) no-repeat 50%;
          background-size: 14px auto;
        }
      }
      .box-items2 {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 1000px;
        .item {
          padding: 60px 30px 55px;
          width: 320px;
          text-align: center;
          background: #fff;
          box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.08);
          .img-item {
            margin: 0 auto 40px;
            width: 100px;
            height: 100px;
            &.img1 {
              background: url(../../images/img/img_brand_gsg_icon1.png) no-repeat 50%;
              background-size: cover;
            }
            &.img2 {
              background: url(../../images/img/img_brand_gsg_icon2.png) no-repeat 50%;
              background-size: cover;
            }
            &.img3 {
              background: url(../../images/img/img_brand_gsg_icon3.png) no-repeat 50%;
              background-size: cover;
            }
          }
          .txt-item {
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            strong {
              display: block;
              font-size: 28px;
              font-weight: 700;
              line-height: 145%;
            }
          }
        }
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 0;
      margin: 0 0 0 0;
      width: 100%;
      height: 450px;
      background: #050d1b;
    }
  }
  .contain-gsg5 {
    margin-bottom: 60px;
    .tit {
      margin: 0 auto 15px;
      font-size: 48px;
      font-weight: 700;
      line-height: 120%;
      text-align: center;
    }
    .txt-desc {
      margin: 0 auto 175px;
      max-width: 1000px;
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      text-align: center;
    }
    .box-office {
      position: relative;
      margin: 0 auto;
      padding: 0 40px;
      width: 100%;
      max-width: 1528px;
      .globalmap {
        width: 100%;
      }
      .item {
        display: block;
        position: absolute;
        left: 0px;
        bottom: 0px;
        padding: 0 0 56px 0;
        background: url(../../images/ico/ico_pin_map.png) no-repeat 50% bottom;
        background-size: 48px auto;
        .box-pop {
          display: none;
          padding: 12px 20px;
          border-radius: 8px;
          background: #000;
          .tit {
            margin: 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            color: #fff;
            white-space: nowrap;
          }
          .list {
            display: none;
            margin: 5px 0 0 0;
            li {
              font-size: 14px;
              font-weight: 400px;
              color: #fff;
            }
          }
          &:hover {
            .list {
              display: block;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .contain-gsg3 {
      .box-slogun {
        .txt-slogun {
          font-size: 30px;
          font-weight: 700;
          line-height: 125%;
        }
      }
      .box-category {
        padding-bottom: 30px;
        .item {
          display: block;
          margin-bottom: 40px;
          .img {
            margin-bottom: 40px;
            margin-left: 24px;
            margin-right: 0;
            width: calc(100% - 24px);
            height: 50vw;
            min-height: 216px;
          }
          .desc {
            width: auto;
            .tit {
            }
            .txt-desc {
            }
            .detail {
              li {
                .tag {
                }
              }
            }
          }
          &:nth-child(2) {
            .img {
              margin-left: 0;
              margin-right: 24px;
            }
          }
          &:nth-child(3) {
            .img {
              margin-left: 24px;
              margin-right: 0;
            }
          }
        }
      }
    }
    .contain-gsg4 {
      padding: 60px 0;
      .txt-slogun {
        font-size: 30px;
        font-weight: 700;
        line-height: 124%;
      }
      .box-feature {
        .box-items1 {
          width: auto;
          justify-content: center;
          .item {
            width: auto;
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            color: rgba(187, 187, 187, 0.6);
          }
          .space {
            margin: 0 20px;
            height: 20px;
            background-size: 10px auto;
          }
        }
        .box-items2 {
          display: block;
          width: auto;
          .item {
            margin: 0 auto 40px;
            .img-item {
            }
            .txt-item {
              strong {
              }
            }
          }
        }
      }
      &::before {
        bottom: 0;
        height: auto;
      }
    }
    .contain-gsg5 {
      padding: 60px 24px;
      .tit {
      }
      .txt-desc {
        margin-bottom: 30px;
      }
      .box-office {
        .pc_view {
          display: block !important;
        }
        .box-map {
        }
        .globalmap {
        }
        .item {
          .box-pop {
            .tit {
            }
            .list {
              li {
              }
            }
            &:hover {
              .list {
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .contain-gsg1 {
      .txt-slogun {
        margin-bottom: 20px;
        font-size: 40px;
        font-weight: 500;
        line-height: 125%;
      }
      .txt-desc {
        font-size: 18px;
        font-weight: 700;
        line-height: 135%;
      }
    }
    .contain-gsg2 {
      margin: 0 auto;
      padding: 60px 24px;
      .txt-desc {
        margin-bottom: 40px;
        font-size: 18px;
        font-weight: 700;
        line-height: 135%;
        text-align: left;
        word-break: keep-all;
      }
      .box-infor {
        display: block;
        .article-rate {
          position: relative;
          margin: 22px 0;
          padding: 0 0 0 24px;
          width: auto;
          border: none;
          //border-left: 1px solid #000;
          .txt-head {
            margin: 0 0 3px 0;
            padding: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 165%;
            color: rgba(64, 64, 64, 0.8);
          }
          .txt-cont {
            font-size: 36px;
            font-weight: 500;
            line-height: 125%;
            strong {
              font-size: 36px;
              font-weight: 500;
              line-height: 125%;
            }
          }
          &::after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 10px;
            width: 1px;
            background: #000;
          }
        }
      }
    }
    .contain-gsg3 {
      .box-slogun {
        .txt-slogun {
          font-size: 30px;
          font-weight: 700;
          line-height: 125%;
        }
      }
      .box-category {
        padding: 80px 0 0;
        .item {
          display: block;
          margin: 0;
          .img {
            margin-bottom: 24px;
            height: 50vw;
            min-height: 216px;
          }
          .desc {
            padding: 0 24px;
            .tit {
              font-size: 24px;
              font-weight: 500;
              line-height: 135%;
            }
            .txt-desc {
              margin-bottom: 8px;
              font-size: 14px;
              font-weight: 400;
              line-height: 165%;
              word-break: keep-all;
              br {
                display: none;
              }
            }
            .detail {
              margin-bottom: 60px;
              li {
                padding: 0;
                font-size: 14px;
                font-weight: 400;
                line-height: 165%;
                word-break: keep-all;
                .tag {
                  position: static;
                  margin: 0 0 6px 0;
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 25px;
                  height: 27px;
                }
              }
            }
          }
          &:nth-child(2) {
            .img {
            }
          }
          &:nth-child(3) {
            .img {
            }
          }
        }
      }
    }
    .contain-gsg4 {
      padding: 60px 0 40px;
      .txt-slogun {
      }
      .box-feature {
        .box-items1 {
          .item {
          }
          .space {
            width: 3px;
            background: url(../../images/ico/mo_ico_by.png) no-repeat 50%;
            background-size: 3px auto;
          }
        }
        .box-items2 {
          .item {
            margin: 0 auto 20px;
            padding: 36px;
            width: 240px;
            .img-item {
              margin: 0 auto 20px;
              width: 80px;
              height: 80px;
            }
            .txt-item {
              font-size: 14px;
              font-weight: 400;
              line-height: 165%;
              strong {
                font-size: 18px;
                font-weight: 700;
                line-height: 135%;
              }
            }
          }
        }
      }
      &::before {
      }
    }
    .contain-gsg5 {
      padding: 60px 24px 0;
      .tit {
        font-size: 30px;
        font-weight: 700;
        line-height: 125%;
      }
      .txt-desc {
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        word-break: keep-all;
      }
      .box-office {
        padding: 0;
        width: auto;
        .pc_view {
          display: none !important;
        }
        .box-map {
          padding-bottom: 40px;
          //height: 240px;
          // background: url(../../images/img/img_brand_gsg_map.png) no-repeat 50%;
          // background-size: auto 240px;
        }
        .globalmap {
        }
        .item {
          position: static;
          margin: 0 0 10px;
          padding: 0;
          background: none;
          .box-pop {
            display: block;
            padding: 12px 20px;
            background: #fff;
            border: 1px solid #bbb;
            .tit {
              margin: 0 0 10px 0;
              padding: 0 0 8px 0;
              font-size: 16px;
              font-weight: 600;
              line-height: 150%;
              color: #3b46ae;
              text-align: left;
              border-bottom: 1px solid #bbb;
            }
            .list {
              display: block;
              li {
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
                color: #727272;
              }
            }
            &:hover {
              .list {
              }
            }
          }
        }
      }
    }
  }
}
