footer {
  background-color: #111;
  .inner {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .ft_top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    padding: 48px 0 36px;
    width: 100%;
    ul {
      display: flex;
      flex-wrap: wrap;
      gap: 0 60px;
      li {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        position: relative;
        .ft_mu_tlt {
          font-size: 16px;
          font-weight: 600;
          color: $color_gray5;
          .ft_mu_more {
            width: 14px;
            height: 14px;
            background: url('../../images/ico/btn_plus.png') no-repeat 100% center;
            background-size: 14px;

            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            border: 0;
            display: block;
            width: auto;
            height: auto;
            height: 24px;
          }
          & + a {
            margin-top: 24px;
          }
        }
        a {
          font-size: 14px;
          color: $color_gray8;
          line-height: 140%;
          + a {
            margin-top: 8px;
          }
        }
      }
    }
    & + .ft_btm {
      border-top: 1px solid $color_gray3;
    }
  }
  .ft_btm {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 24px 0;
    .logo {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 0 32px;
      .ft_logo_gs {
        width: 90px;
        height: 24px;
        background: url('../../images/ico/ico_logo_gs_wh.svg') no-repeat center center;
        background-size: 86px;
      }
      .ft_logo_byd {
        width: 40px;
        height: 24px;
        background: url('../../images/ico/ico_logo_byd_rd.svg') no-repeat center center;
        background-size: 40px;
      }
    }    
    .info {
      display: flex;
      width: auto;
      [class*='link'] {
        font-size: 13px;
        line-height: 140%;
        color: $color_gray6;
      }
      .link_b {
        font-weight: 700;
        color: $color_gray11;
        & + .link_s {
          margin-left: 12px;
        }
      }
      .link_s {
        + .link_s {
          margin-left: 12px;
          padding-left: 12px;
          border-left: 1px solid rgba($color: $color_gray17, $alpha: 0.3);
        }
      }
    }
    .companyInfo{
      display:flex;
      gap:5px;    
      max-width:700px;
      flex-wrap:wrap; 
      span{
        font-size:13px;
        color: $color_gray11;
        display:flex;
        align-items:center;
      }
      span:not(:forst-child):before {
        content:'|';
        font-size:10px;
        color: $color_gray11;
        opacity:0.5;
      }
    }
  }

  //   tablet
  @media screen and (max-width: 1024px) {
    .ft_top {
      padding-bottom: 53px;
      ul {
        gap: 0 30px;
        li {
          @include typo_body2;
          line-height: 165%;
          .ft_mu_tlt {
            font-size: 14px;
          }
          & + a {
          }
          a {
            font-size: 12px;
          }
        }
      }
      .pc_view {
        display: block !important;
      }
    }
    .ft_btm {
      flex-direction: column;
      gap: 24px 0;
      padding: 40px 0 26px;   
      
      .companyInfo{
        flex-wrap:wrap; 
      }   
    }
  }

  //   mobile
  @media screen and (max-width: 768px) {
    .ft_top {
      padding: 26px 0 40px;
      ul {
        flex-direction: column;
        width: 100%;
        li {
          .ft_mu_tlt {
            @include typo_body1;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            font-weight: 500;
            color: $color_gray8;
          }
          a:not(.btn_radius) {
            display: none;
            height: 0;
            transition: height 0.2s ease-in-out;
          }
          & + a {
            margin-top: 16px;
          }
          a {
            @include typo_body2;
            line-height: 100%;
            color: #646464;
            & + a {
              margin-top: 16px;
            }
          }
          & + li {
            margin-top: 18px;
          }

          &[data-active='true'] {
            .ft_mu_tlt {
              .ft_mu_more {
                background-image: url('../../images/ico/btn_minus.png');
              }
            }
            a:not(.btn_radius) {
              display: block;
              height: 100%;
              transition: height 0.2s ease-in-out;
            }
          }
        }
      }
      .pc_view {
        display: none !important;
      }
    }
    .ft_btm {
      flex-direction: column;
      gap: 24px 0;
      padding: 40px 0 26px;
      .logo {
        gap: 0 24px;
        .ft_logo_gs {
          width: 78px;
          height: 20px;
          background-size: 78px;
        }
        .ft_logo_byd {
          width: 40px;
          height: 24px;
          background-size: 40px;
        }
      }
      .info {
        display: block;
        .link_b {
          & + .link_s {
            display: block;
            margin-top: 16px;
            position: relative;
            z-index: 0;
            margin-left: 0;
            padding-left: 25px;
            padding-left: 0;
            &::before {
              display: none;
              @include pseudo;
              @include center_top;
              width: 1px;
              height: 10px;
              left: 12px;
              z-index: 1;
              background-color: $color_gray17;
              opacity: 0.3;
            }
          }
        }
        .link_s {
          & + .link_s {
            margin-left: 0;
            padding-left: 0;
            border: none;
          }
        }
        a {
          font-size: 12px;
          line-height: 135%;
        }
      }
      .companyInfo{
        display:flex;
        gap:5px;
        flex-wrap:wrap;      
        span{
          font-size:12px;
        }
      }
    }
  }
}
