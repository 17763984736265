.main {
  // main common
  .btnArea {
    display: flex;
    column-gap: 40px;
  }

  .event_line {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 122px;
    background: rgba(34, 37, 46, 0.7);
    .item {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 0 60px;
      height: 100%;
      .img {
        position: relative;
        bottom: 33px;
        left: 0;
        width: 186px;
        height: 120px;
      }
      .txt_wrap {
        max-width: 500px;
        text-align: left;
        .tlt {
          font-weight: 700;
          font-size: 28px;
          line-height: 145%;
          display: inline-block;
          margin-top: 4px;
          width: 100%;
          color: #ffffff;
          .txt_br {
            display: none;
          }
        }
        .info {
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          margin-top: 4px;
          color: rgba(255, 255, 255, 0.6);
        }
      }
      .btn_wrap {
        a {
          display: inline-block;
          background: #3581ff;
          border-color: #3581ff;
        }
        button {
          background: transparent;
          border: 1px solid rgba(255, 255, 255, 0.4);
          i {
            width: 24px;
            height: 24px;
            margin-right: 8px;
            background: url('../../images/ico/ico_event_close.png') no-repeat center;
            background-size: contain;
          }
        }
        .btn_radius {
          height: 46px;
          padding: 11px 24px;
          font-weight: 700;
          font-size: 15px;
          color: #fff;
          & + .btn_radius {
            margin-left: 8px;
          }
        }
      }
    }
    @media screen and (max-width: 1600px) {
      .pc_view {
        display: block !important;
      }
      .item {
        gap: 24px;
      }
    }

    @media screen and (max-width: 1060px) {
      display: none;
      // .pc_view {
      //   display: none !important;
      // }
      // .mo_view {
      //   display: block !important;
      // }
      // height: 205px;
      // padding: 24px 24px 34px 24px;
      // z-index: 100;
      // .item {
      //   display: block;
      //   .ico_24_progress_close {
      //     position: absolute;
      //     right: 0;
      //     top: 0;
      //     width: 56px;
      //     height: 56px;
      //     background: url('../../images/ico/ico_close_popup.png') no-repeat center;
      //     background-size: 20px;
      //   }
      //   .txt_wrap {
      //     .tlt {
      //       font-weight: 600;
      //       font-size: 20px;
      //       line-height: 135%;
      //       .txt_br {
      //         display: block;
      //       }
      //     }
      //     .info {
      //       margin-top: 8px;
      //       font-weight: 400;
      //       font-size: 11px;
      //       line-height: 140%;
      //     }
      //   }
      //   .btn_move {
      //     margin-top: 24px;
      //     padding: 12px;
      //     width: 100%;
      //     border-radius: 8px;
      //     background: #3581ff;
      //     text-align: center;
      //     font-weight: 700;
      //     font-size: 15px;
      //     line-height: 145%;
      //     color: #fff;
      //   }
      // }
    }
  }

  .btn-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
    padding: 16px 0;
    font-size: 20px;
    color: $color_white;
    font-weight: 500;
    border-bottom: 1px solid rgba($color: $color_white, $alpha: 0.4);
    transition: all 0.35s;
    position: relative;
    z-index: 1;
    &::after,
    &::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: block;
      width: 28px;
      height: auto;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      opacity: 0;
      transition: all 0.35s;
    }
    &::before {
      background-image: url('../../images/ico/icon_arrow_mainbtn.png');
      opacity: 1;
    }
    &::after {
      background-image: url('../../images/ico/icon_arrow_mainbtn_bk.png');
    }
    .btn-line {
      position: absolute;
      display: block;
      border: 1px solid rgba($color: $color_white, $alpha: 0.4);
      opacity: 0;
      transition: opacity 0.005s;
      z-index: -1;
      &.right {
        right: 0;
        bottom: 0;
        width: 0;
        height: 0; // transition target
        transition: height 0.05s;
      }
      &.top {
        right: 0;
        top: 0;
        width: 0; // transition target
        height: 0;
        transition: width 0.05s 0.05s;
      }
      &.left {
        left: 0;
        top: 0;
        width: 0;
        height: 0; // transition target
        transition: height 0.1s 0.05s;
      }
      &.full {
        border: none;
        top: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 0; // transition target
        height: 100%;
        background-color: $color_white;
        transition: width 0.15s 0.05s;
      }
    }
    @media screen and (min-width: 1181px) {
      &:hover {
        text-indent: 1.042vw;
        &::before,
        &::after {
          opacity: 1;
          transform: translateX(-1.042vw);
        }
        color: $color_black;
        .btn-line {
          opacity: 1;
          &.right {
            height: 100%;
          }
          &.top {
            width: 100%;
          }
          &.left {
            height: 100%;
          }
          &.full {
            width: 100%; // transition target
          }
        }
      }
      &:not(:hover) {
        .btn-line {
          opacity: 0;
          &.right {
            height: 0%;
            transition: width 0.15s 0.05s;
          }
          &.top {
            width: 0%;
            transition: height 0.1s 0.05s;
          }
          &.left {
            height: 0%;
            transition: width 0.05s 0.05s;
          }
          &.full {
            transition: height 0.05s;
            width: 0%; // transition target
          }
        }
      }
    }
  }
  @keyframes scrollBtn {
    0% {
      opacity: 0;
      transform: translateY(0);
    }
    10% {
      opacity: 1;
      transform: translateY(0);
    }
    75% {
      opacity: 1;
      transform: translateY(0);
    }
    95% {
      opacity: 0.25;
    }
    100% {
      opacity: 0;
      transform: translateY(5px);
    }
  }

  // main keyvisual
  .main-keyviusal {
    display: flex;
    align-items: center;
    position: relative;
    // height: calc(100vh - env(safe-area-inset-bottom));
    height: calc(var(--vh, 1vh) * 100);
    // height: 100%;
    background-color: #111;
    overflow: hidden;
    z-index: 1;
    .inner {
    }
    // background: url('../../images/main/img_main_kyevisual.png') no-repeat center center;
    // background-size: cover;
    .main-logo {
      display: inline-block;
      width: auto;
      vertical-align: baseline;
      position: relative;
      z-index: 1;
    }
    .keyvisual-stit {
      margin-top: -2.5vh; // 시각보정
      @include typo_display1;
      color: $color_white;
      position: relative;
      z-index: 1;
    }

    .btnArea {
      margin-top: 80px;
      position: relative;
      z-index: 1;
    }
    // .btn-scroll {
    //   position: absolute;
    //   left: 0;
    //   right: 0;
    //   bottom: 1.25vw;
    //   margin: auto;
    //   font-size: 0;
    //   line-height: 0;
    //   width: 1.042vw;
    //   height: 2.344vw;
    //   background: url('../../images/ico/btn_scrollmark.png') no-repeat center center;
    //   background-size: contain;
    //   .scroll-wheel {
    //     position: absolute;
    //     left: 0;
    //     right: 0;
    //     top: 0.417vw;
    //     margin: auto;
    //     display: block;
    //     width: 0.417vw;
    //     height: 0.26vw;
    //     background: url('../../images/ico/btn_scrollmark_wheel.png') no-repeat center center;
    //     background-size: contain;
    //     animation-name: scrollBtn;
    //     animation-duration: 3s;
    //     animation-delay: 2s;
    //     animation-timing-function: ease-out;
    //     animation-iteration-count: infinite;
    //   }
    // }
    .react-player {
      width: 100%;
      height: 100vh;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  // main quick
  .main-quick {
    display: flex;
    justify-content: space-between;
    margin: 0 4%;
    padding: 150px 0 240px;
    background: $color_white;
    .main-quick-cont {
      width: 48%;
      padding: 4%;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
      &.left {
        background-image: url('../../images/main/img_main_quick01.jpg');
      }
      &.right {
        background-image: url('../../images/main/img_main_quick02.jpg');
      }
      strong {
        display: block;

        @include typo_header2;
        color: $color_white;
      }
      span {
        display: block;
        @include typo_title1;
        margin-top: 24px;
        color: $color_white;
      }
      .btn-main {
        margin-top: 180px;
      }
    }
  }

  // main overview
  .main-overview {
    padding: 0 4%;
    overflow: hidden;
    position: relative;
    &:after {
      @include pseudo;
      height: 200px;
      left: 0;
      right: 0;
      bottom: 0;
      background: $color_black;
      z-index: -1;
    }
    .overview-inner {
      height: 970px;
      background: #111; // for test
      overflow: hidden;
      position: relative;
      // background: $color_black;
    }
    .overview-btn-group {
      height: auto;
      padding: 0 4.5% 8.2%;
      gap: 80px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      z-index: 10;
      .btn-overview {
        flex: auto;
        position: relative;
        padding: 16px 0;
        color: rgba($color: $color_white, $alpha: 0.4);
        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          width: 100%;
          height: 1px;
          background-color: rgba($color: $color_white, $alpha: 0.4);
          transition: height 0.5s;
        }
        &.active,
        &:hover {
          font-weight: 700;
          color: $color_white;
          &:after {
            background-color: $color_white;
            height: 3px;
          }
        }
      }
    }
    .overview-stit {
      @include typo_title1;
      color: rgba($color: $color_white, $alpha: 0.6);
      position: relative;
    }
    .overview-cont {
      display: none;
      position: relative;
      dl {
        width: 60%;
        word-break: keep-all;
      }
      dt {
        margin: 28px 0 60px;
        @include typo_header1;
        color: $color_white;
      }
      dd {
        @include typo_title1;
        color: $color_white;
        display: inline-flex;
        align-items: center;
        &:after {
          content: '';
          display: block;
          width: 4px;
          height: 4px;
          margin: 0 12px;
          border-radius: 50%;
          background: rgba($color: $color_white, $alpha: 0.6);
        }
        &:last-of-type {
          column-gap: 0;
          margin: 0;
          &:after {
            visibility: hidden;
          }
        }
      }
      &.active {
        display: block;
      }
      .fig-overview00 {
        position: relative;
      }
      .fig-overview01 {
        position: relative;
      }
      .fig-overview02 {
        position: relative;
      }
      .react-player {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        object-fit: cover;
      }
    }
  }
  .main-quick-menu {
    display: flex;
    // padding: 505px 0 200px;
    background: $color_black;

    .inner {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .btn-quick-menu {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 80px;
      flex: auto;
      color: $color_white;
      @include typo_title1;
      &::before {
        content: '';
        display: block;
        width: 80px;
        height: 80px;
        margin-right: 32px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
      }
      @for $qbtn from 1 to 5 {
        &:nth-child(#{$qbtn}):before {
          background-image: url('../../images/ico/icon_quick_main_0' + $qbtn + '.png');
        }
      }
    }
  }
  // main news
  .main-news {
    overflow: hidden;
    position: relative;
    background-color: $color_black;
    .news-stit {
      @include typo_header2;
      color: $color_white;
      em {
        display: block;
        margin: 0 0 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 120%;
        color: #727272;
      }
    }
    .swiper {
      overflow: visible;
    }
    .news-slider {
      margin: 80px 0 0 0;
      .news-slide {
        width: 484px;
        margin: 0 32px 0 0;

        .news-item {
          display: block;
          .news-image {
            background: lightblue;
          }
          .news-texts {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100px;
            margin: 24px 0 0 0;
            .news-title {
              @include typo_title1;
              @include ellipse_2;
              color: $color_white;
            }
            .news-datetime {
              margin-top: auto;
              @include typo_body1;
              color: $color_gray6;
            }
          }
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        width: 65px;
        height: 65px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        border: 1px solid $color_white;
        top: -125px;
        &:after {
          display: none;
        }
      }
      .swiper-button-prev {
        right: calc(65px + 12px);
        left: auto;
        background-image: url('../../images/ico/btn_main_swiper_prev.png');
      }
      .swiper-button-next {
        right: 0;
        left: auto;
        background-image: url('../../images/ico/btn_main_swiper_next.png');
      }
    }
    &.emptyNotice {
      padding-bottom: 4.583vw;
    }
  }
  // main announce
  .main-announce {
    background: $color_black;
    .inner-announce {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 104px;
      padding: 0 40px;
      margin: 0 auto;
      border-radius: 8px;
      color: $color_white;
      background: rgba($color: $color_white, $alpha: 0.12);
      .announce-slider {
        margin: 0;
        height: 100%;
        flex: auto;
        .announce-slide {
          display: flex;
          align-items: center;
          gap: 28px;
          width: calc(100% - 120px);
          .announce-stit {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            width: 84px;
            height: 39px;
            border: 1px solid $color_white;
            border-radius: 50px;
            color: $color_white;
            flex-shrink: 0;
          }
          .announce-url {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            strong {
              margin-right: 1rem;
              @include typo_title1;
              color: $color_white;
              line-height: 1.4;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
            span {
              @include typo_body1;
              color: $color_gray6;
              // margin: 0 0 0 auto;
            }
          }
        }
      }
      .announce-divider {
        display: block;
        width: 1px;
        height: 0.833vw;
        margin: 0 20px;
        background: #dedede;
      }
      .announce-more {
        display: flex;
        width: 80px;
        column-gap: 0;
        @include typo_body1;
        font-weight: 600;
        color: $color_white;
        align-items: inherit;
        white-space: nowrap;
        &::after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          background: url('../../images/ico/btn_main_announce.png') no-repeat center center;
          background-size: contain;
        }
      }
    }
  }
  // main brand
  .main-brand {
    background: url('../../images/main/bg_main_brand.jpg') no-repeat center center;
    background-size: cover;
    .inner {
      padding-top: 240px;
      padding-bottom: 80px;
    }
    .inner > * {
      transition: all 0.4s ease;
    }
    .brand-stit {
      @include typo_header2;
      color: $color_white;
      margin: 0 0 40px;
      word-break: keep-all;
      word-wrap: break-word;
    }
    .brand-txt {
      @include typo_title1;
      color: $color_white;
    }
    .btn-main {
      margin: 160px 0 0 0;
    }
    .brand-link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 210px;
      span {
        display: block;
        font-size: 0;
        line-height: 0;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;
        transition-property: opacity, scale;
        &.brand-gsg {
          opacity: 0;
          transform: scale(0);
          transition-duration: 1s;
          width: 10.729vw;
          height: 2.865vw;
          background-image: url('../../images/main/logo_brand_gsg.png');
        }
        &.brand-byd {
          transition-delay: 2s;
          transition-duration: 2s;
          opacity: 0;
          transform: scale(0);
          width: 4.792vw;
          height: 2.865vw;
          background-image: url('../../images/main/logo_brand_byd.png');
        }
      }
    }
    .brand-divider {
      position: relative;
      height: 1px;
      width: 52.083vw;
      &::after {
        content: '';
        transition: width 1s 1s;
        position: absolute;
        display: block;
        margin: auto;
        left: 0;
        top: 0;
        width: 0;
        height: 100%;
        background: $color_white;
      }
    }
    &.doAnimate {
      [data-trigger] {
        opacity: 1;
        transform: translateY(0);
      }
      .brand-link {
        .brand-gsg,
        .brand-byd {
          opacity: 1;
          transform: scale(1);
        }
        .brand-divider {
          &::after {
            width: 100%;
          }
        }
      }
    }
    &.active {
    }
  }

  @media screen and (min-width: 1200px) {
    .main-keyviusal {
      align-items: baseline;
      .inner {
        position: relative;
        margin: 0;
        padding: 0;
        top: 40%;
        left: 10%;
      }
    }
  }

  // todo 모바일에서 폰트크기만 가져옴
  @media screen and (max-width: 1200px) {
    .btn-main {
      font-size: 14px; // todo 15에서 14로 바꿈
      &::before {
        width: 18px;
      }
    }
    .main-keyviusal {
      // background-image: url('../../images/main/img_main_mo_kyevisual.png');
      .main-logo {
        width: 105px;
      }
      .keyvisual-stit {
        font-size: 48px;
      }
      .btnArea {
        margin-top: 40px;
        column-gap: 20px;
        .btn-main {
        }
      }
      .btn-scroll {
        .scroll-wheel {
        }
      }
    }

    // main quick
    .main-quick {
      // display: block;
      padding-top: 120px;
      padding-bottom: 130px;
      .main-quick-cont {
        padding: 32px 24px;
        strong {
          font-size: 24px;
        }
        span {
          margin-top: 8px;
          font-size: 14px;
        }

        .btn-main {
          width: 100%;
          margin-top: 54px;
        }
        &.left {
        }
        &.right {
          // margin-top: 32px;
        }
        .main-quick-guide {
          &:after {
            content: '';
            display: block;
            background: url('../../images/ico/icon_arrow_mainbtn.png') no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
    // main overview
    .main-overview {
      padding: 0;
      .inner {
        padding-top: 200px;
      }
      .overview-inner {
      }
      .overview-btn-group {
        column-gap: 0;
        .btn-overview {
          width: auto;
          border-bottom: 1px solid #f4f4f4;
          flex: auto;
          &:after {
            height: 3px;
            width: 0;
            bottom: -1px;
            margin: auto;
            background-color: $color_white;
            transition: width 0.5s;
          }
          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
      .overview-stit {
        font-size: 15px;
      }
      .overview-cont {
        dl {
          width: 100%;
        }
        dt {
          margin: 20px 0 40px;
          font-size: 36px;
        }
        dd {
          font-size: 15px;
        }
        .react-player {
        }

        .fig-overview00,
        .fig-overview01,
        .fig-overview02 {
        }
      }
    }
    // main quick menu
    .main-quick-menu {
      padding: 0; // todo desktop
      .inner {
        padding: 80px 0;
        flex-wrap: wrap;
        justify-content: center;
      }
      .btn-quick-menu {
        display: block;
        width: calc(50% - 40px);
        height: auto;
        margin: 20px;
        text-align: center;
        font-size: 15px;
        &::before {
          display: block;
          width: 100%;
          height: 48px;
        }
      }
    }

    // main brand
    .main-brand {
      background-image: url('../../images/main/bg_main_mo_brand.png');
      .inner {
        padding-top: 120px;
        padding-bottom: 40px;
      }
      .brand-stit {
        margin-bottom: 20px;
        font-size: 30px;
      }
      .brand-txt {
        font-size: 15px;
      }
      .btn-main {
        margin-top: 76px;
      }
      .brand-link {
        margin-top: 100px;
        span {
          // &.brand-gsg {
          //   width: 33.333vw; // todo
          //   height: 8.888vw;
          //   background-image: url('../../images/main/logo_brand_gsg_mo.png');
          // }
          // &.brand-byd {
          //   width: 15vw;
          //   height: 8.888vw;
          //   background-image: url('../../images/main/logo_brand_byd_mo.png');
          // }
        }
      }
      .brand-divider {
        // width: 30vw;
      }
    }
  }

  // Mobile Phone
  @media screen and (max-width: 767px) {
    .btn-main {
      // font-size: 15px;
      &::before {
        width: 18px;
      }
    }
    .main-keyviusal {
      // background-image: url('../../images/main/img_main_mo_kyevisual.png');
      .main-logo {
        width: 105px;
      }
      .keyvisual-stit {
        font-size: 48px;
      }
      .btnArea {
        margin-top: 40px;
        column-gap: 20px;
        .btn-main {
        }
      }
      .btn-scroll {
        .scroll-wheel {
          top: 2.222vw;
          width: 0.833vw;
          height: 1.389vw;
        }
      }
    }

    // main quick
    .main-quick {
      display: block;
      padding-top: 120px;
      padding-bottom: 130px;
      .main-quick-cont {
        width: 100%;
        padding: 32px 24px;
        strong {
          font-size: 24px;
        }
        span {
          margin-top: 8px;
          font-size: 14px;
        }

        .btn-main {
          width: 100%;
          margin-top: 54px;
        }
        &.left {
        }
        &.right {
          margin-top: 32px;
        }
        .main-quick-guide {
          &:after {
            content: '';
            display: block;
            background: url('../../images/ico/icon_arrow_mainbtn.png') no-repeat center center;
            background-size: contain;
          }
        }
      }
    }
    // main overview
    .main-overview {
      padding: 0;
      .inner {
        padding-top: 180px;
      }
      .overview-inner {
        height: 95vh;
      }
      .overview-btn-group {
        padding-bottom: 50px;
        .btn-overview {
          width: auto;
          border-bottom: 1px solid #f4f4f4;
          flex: auto;
          font-size: 14px;
          &:after {
            height: 3px;
            width: 0;
            bottom: -1px;
            margin: auto;
            background-color: $color_white;
            transition: width 0.5s;
          }
          &.active {
            &:after {
              width: 100%;
            }
          }
        }
      }
      .overview-stit {
        font-size: 15px;
      }
      .overview-cont {
        dl {
          width: 100%;
        }
        dt {
          margin: 20px 0 40px;
          font-size: 36px;
        }
        dd {
          font-size: 15px;
        }
        .react-player {
          height: auto !important;
        }

        .fig-overview00,
        .fig-overview01,
        .fig-overview02 {
        }
      }
    }
    // main quick menu
    .main-quick-menu {
      padding: 0; // todo desktop
      .inner {
        padding: 80px 0;
        flex-wrap: wrap;
        justify-content: center;
      }
      .btn-quick-menu {
        display: block;
        width: calc(50% - 40px);
        height: auto;
        margin: 20px;
        text-align: center;
        font-size: 15px;
        &::before {
          display: block;
          width: 100%;
          height: 48px;
        }
      }
    }

    // main news
    .main-news {
      .news-stit {
        font-size: 30px;
        em {
          font-size: 15px;
        }
      }
      .news-slider {
        margin-top: 48px;
        .news-slide {
          width: 288px;
          .news-item {
            .news-image {
            }
            .news-title {
              em {
              }
            }
            .news-datetime {
            }
          }
        }
        .swiper-button-prev,
        .swiper-button-next {
          display: none;
        }
      }
      &.emptyNotice {
      }
    }

    // main announce
    .main-announce {
      padding: 56px 0 0;
      .inner {
        padding: 0;
      }

      .inner-announce {
        position: relative;
        align-items: flex-start;
        height: auto;
        flex-direction: column;
        padding: 40px 24px;
        border-radius: 0;
        .announce-slider {
          width: 100%;
          .announce-slide {
            flex-direction: column;
            align-items: flex-start;
            column-gap: 0;
            .announce-stit {
              width: 72px;
              height: 29px;
              font-weight: 700;
              font-size: 13px;
              line-height: 100%;

              letter-spacing: -0.02em;
              text-transform: uppercase;

              color: #ffffff;
            }
            .announce-url {
              width: 100%;
              // height: 42.5vw;
              margin-top: 12px;
              flex-direction: column;
              align-items: stretch;
              justify-content: space-between;
              strong,
              span {
                margin: 0;
              }
              strong {
                height: 48px;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                @include ellipse_2;
                line-height: 150%;
                white-space: normal;
              }
              span {
                margin: 20px 0 0 0;
                padding: 20px 0 0 0;
                border-top: 1px solid rgba($color: $color_white, $alpha: 0.2);
              }
            }
          }
        }
        .announce-divider {
          display: none;
        }
        .announce-more {
          display: flex;
          align-items: center;
          position: absolute;
          right: 24px;
          bottom: 40px;
          z-index: 10;
          background: #1f1f1f;
          &::after {
          }
        }
      }
    }
    // main brand
    .main-brand {
      background-image: url('../../images/main/bg_main_mo_brand.png');
      .inner {
        padding-top: 120px;
        padding-bottom: 40px;
      }
      .brand-stit {
        margin-bottom: 20px;
        font-size: 30px;
      }
      .brand-txt {
        font-size: 15px;
      }
      .btn-main {
        margin-top: 76px;
      }
      .brand-link {
        margin-top: 100px;
        span {
          &.brand-gsg {
            width: 33.333vw; // todo
            height: 8.888vw;
            background-image: url('../../images/main/logo_brand_gsg_mo.png');
          }
          &.brand-byd {
            width: 15vw;
            height: 8.888vw;
            background-image: url('../../images/main/logo_brand_byd_mo.png');
          }
        }
      }
      .brand-divider {
        width: 30vw;
      }
    }
  }
}

body.noscrollMain {
  // scroll-snap-type: y mandatory;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

body.noscrollMain::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
