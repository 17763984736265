@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.6/dist/web/static/pretendard-dynamic-subset.css');

//Loading Indicatot
.loading {
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  .indicator {
    span {
      span {
        width: 10px;
        background: #ffffff !important;
        & + span {
          background: #c1cbe2 !important;
          & + span {
            background: #899ee9 !important;
            & + span {
              background: #5a74ce !important;
              & + span {
                background: #3b46ae !important;
              }
            }
          }
        }
      }
    }
  }
}

// swiper
.swiper-pagination-bullet {
  width: 12px !important;
  height: 2px !important;
  background-color: $color_gray17 !important;
  border-radius: 0 !important;
  opacity: 0.3;
}
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: '' !important;
  width: 36px;
  height: 36px;
  background: url('../../images/ico/btn_swiper_prev.png') no-repeat center center;
  background-size: 36px;
}
.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: '' !important;
  width: 36px;
  height: 36px;
  background: url('../../images/ico/btn_swiper_next.png') no-repeat center center;
  background-size: 36px;
}

[data-action='reveal-content'] {
  opacity: 0;
  transform: translateY(100px);
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
}
[data-trigger] {
  opacity: 0;
  transform: translateY(100px);
}
.pagelist {
  margin-top: -88px;
  height: calc(100vh - 6px);
}
.tablet_view {
  display: none !important;
}
.mo_view {
  display: none !important;
}

.mobile_br {
  display: none;
}
.desktop_br {
  display: block;
}

// 1024px에서 변경
@media screen and (max-width: 1080px) {
  .pc_view {
    display: none !important;
  }
  .tablet_view {
    display: block !important;
  }
  .pagelist {
    margin-top: -56px;
  }
}
@media screen and (max-width: 768px) {
  .pc_ta_view {
    display: none;
  }
  .mobile_br {
    display: block;
  }
  .desktop_br {
    display: inline;
  }

  .pc_view {
    display: none !important;
  }
  .mo_view {
    display: block !important;
  }
  .pagelist {
    margin-top: -56px;
  }
}
.common {
  h1 {
    @include typo_header3;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $color_black;
  }
  > div {
    & + h1 {
      margin-top: 50px;
    }
  }
}
.circle_progress_wrap {
  position: absolute;
  right: 60px;
  bottom: 221px;
  z-index: 1;
  width: 54px;
  height: 54px;
  .CircularProgressbar {
    .CircularProgressbar-trail {
      stroke: rgba($color: $color_gray10, $alpha: 0.3);
    }
    .CircularProgressbar-path {
      stroke: $color_gray10;
    }
  }
  .ctrl {
    @include center;
    width: 12px;
    height: 16px;
    background: url('../../images/ico/btn_video_ctrl.png') no-repeat center center;
    background-size: 12px;
    &.stop {
      width: 13px;
      height: 15px;
      background: url('../../images/ico/btn_video_play.png') no-repeat center center;
      background-size: 15px;
      margin-left: 2px;
    }
  }
}

// typo
.typo_display1 {
  @include typo_display1;
}
.typo_header1 {
  @include typo_header1;
}
.typo_header2 {
  @include typo_header2;
}
.typo_header3 {
  @include typo_header3;
}
.typo_header4 {
  @include typo_header4;
}
.typo_title1 {
  @include typo_title1;
}
.typo_body1 {
  @include typo_body1;
}
.typo_body2 {
  @include typo_body2;
}
.typo_body3 {
  @include typo_body3;
}

// list
[class*='list_wrap'] {
  border-top: 1px solid $color_bgray1;
  .list {
    padding: 28px 0;
    width: 100%;
    border-bottom: 1px solid $color_gray8;
    .tlt_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      .tlt {
        @include typo_title1;
        text-align: left;
        font-weight: 700;
      }
    }
  }
}
.list_wrap1 {
  .list {
    .tlt_wrap {
      .tlt {
        @include ellipse;
        position: relative;
        z-index: 0;
        padding-right: 28px;
        max-width: calc(100% - 98px);
        color: $color_black;
        i {
          @include center_top;
          left: auto;
          right: 0;
          z-index: 1;
        }
      }
      .date {
        color: $color_gray6;
      }
    }
    .desc {
      @include ellipse;
      margin-top: 12px;
      text-align: left;
      width: calc(100% - 24px);
      height: 28px;
      overflow: hidden;
      color: $color_gray3;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      img {
        display: none;
      }
      * {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
  }
  & + .pagination {
    margin-top: 44px;
  }
}
.list_wrap2 {
  display: grid;
  // grid-template-columns: 1fr 1fr 1fr;
  grid-template-columns: repeat(3, 1fr);
  gap: 60px 40px;
  border-top: none;
  & + .pagination {
    margin-top: 40px;
  }
}
.list_wrap3 {
  padding-left: 25px;
  border-top: 0;
  li {
    font-weight: 600;
    color: $color_black;
    text-indent: -25px;
    & + li {
      margin-top: 16px;
    }
  }
}
.list_wrap4 {
  .list {
    padding: 0;
    .tlt_wrap {
      align-items: flex-start;
      padding: 28px 0;
      cursor: pointer;
      .tlt {
        width: calc(100% - 52px);
        color: $color_black;
      }
      i {
        transform: rotate(-180deg);
        opacity: 0.3;
        transition: transform 0.2s ease-in-out;
      }
    }
    .acco_cont {
      @include typo_body1;
      overflow: hidden;
      height: 0;
      background-color: $color_gray10;
      color: $color_gray3;
    }
    &.active {
      .tlt_wrap {
        i {
          transform: rotate(0);
          opacity: 1;
        }
      }
      .acco_cont {
        padding: 24px;
        height: 100%;
      }
    }
  }
}

.list_dl1 {
  dt {
    @include typo_body2;
    color: $color_gray5;
    & + dd {
      margin-top: 4px;
    }
  }
  dd {
    & + dt {
      margin-top: 24px;
    }
  }
}
.list_dl2 {
  dt {
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
  }
  dd {
    color: #4f555e;
    & + dt {
      margin-top: 16px;
    }
  }
}
.list_dl3 {
  dt {
    @include typo_title1;
    color: $color_black;
    & + dd {
      margin-top: 4px;
    }
  }
  dd {
    color: $color_black;
    & + dt {
      margin-top: 16px;
    }
  }
}

// form
.form_wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 0;
  .input_wrap + [class*='btn_'],
  input + [class*='btn_'] {
    padding: 8px 16px;
    margin-left: 8px;
  }
  .label {
    line-height: 76px;
    // color: $color_gray12;
    color: #5f5f5f;
    & + .select,
    & + .select_layout {
      margin-left: 40px;
    }
    & + .input_wrap {
      margin-left: 40px;
    }
    &.require {
      position: relative;
      z-index: 0;
      &::after {
        @include pseudo;
        top: 14px;
        right: -10px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color_error;
      }
    }
  }
  .input_wrap {
    width: 100%;
  }
  input {
    padding: 8px 40px 8px 12px;
    width: 100%;
    height: 42px;
    border: 1px solid $color_gray7;
    border-radius: 4px;
    background-color: $color_white;
    &::placeholder {
      color: $color_gray6;
      color: #9b9b9b;
    }
    &:focus {
      border-color: $color_gray13;
    }
    &:disabled {
      border-color: $color_gray14;
      background-color: $color_gray10;
      color: $color_gray6;
      &::placeholder {
        color: $color_gray6;
      }
    }
    &:read-only:not(:disabled) {
      border-color: $color_gray14;
      background-color: #f4f4f4;
      &::placeholder {
        color: $color_bgray1;
      }
    }
  }

  // select box
  .select_default {
    position: relative;
    z-index: 0;
    padding: 8px 12px;
    border: 1px solid $color_gray14;
    border-radius: 4px;
    background-color: $color_white;
    .input_select {
      @include typo_body1;
      width: 100%;
      background-color: transparent;
    }
    &::after {
      @include pseudo;
      top: 8px;
      right: 10px;
      z-index: 1;
      width: 24px;
      height: 24px;
      background: url('../../images/ico/btn_arrow_select2.png') no-repeat center center;
      background-size: 24px;
    }
  }
  .select_layout2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0 9px;
  }
  .select_layout3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 0 9px;
  }
  .select_layout {
    display: flex;
    flex-wrap: wrap;
    gap: 0 24px;
    width: calc(100% - 100px);
    .select {
      width: calc(50% - 12px);
    }
  }
  .select {
    position: relative;
    z-index: 0;
    padding: 22px 0;
    min-width: 244px;
    &::after {
      @include pseudo;
      @include center_top;
      right: 0;
      left: auto;
      z-index: 1;
      width: 24px;
      height: 24px;
      background: url('../../images/ico/btn_arrow_select.png') no-repeat center center;
      background-size: 24px;
    }
  }
  .input_select {
    position: relative;
    z-index: 2;
    @include typo_title1;
    border: 0;
    background-color: $color_white;
  }
  .input_del {
    @include center_top;
    display: none;
    right: 8px;
    left: auto;
    &.focus {
      display: block;
    }
  }
  .err_msg {
    @include typo_body3;
    margin-top: 4px;
    color: $color_error;
  }
  &.error {
    input {
      border-color: $color_error;
    }
  }

  // underline type
  &.line_ty {
    flex-direction: column;
    input {
      @include typo_title1;
      padding: 22px 0;
      border: none;
      font-weight: 400;
      &::placeholder {
        @include typo_title1;
        font-weight: 400;
      }
    }
    .input_txt {
      padding-right: 34px;
    }
    .input_wrap {
      display: flex;
      align-items: flex-start;
      overflow: hidden;
      border-bottom: 1px solid $color_gray3;
      border-bottom: 1px solid #4f555e;
      .label {
        width: 60px;
        line-height: 76px;
        &.require {
          &::after {
            top: 10px;
          }
        }
        & + input,
        & + .input_select {
          margin-left: 40px;
          width: calc(100% - 100px);
        }
      }
    }
    // select
    .input_select {
      width: 100%;
      background-color: transparent;
    }
  }
}
// input layout
.input_layout {
  display: flex;
  justify-content: center;
  gap: 0 8px;
  .form_wrap {
    width: 200px;
    text-align: left;
    &:last-child {
      width: 400px;
      .input_wrap {
        width: calc(100% - 78px);
      }
      [class*='btn_'] {
        width: 70px;
        color: $color_white;
        background-color: #19254d;
        &:hover {
          border: none;
        }
      }
    }
  }
  &.active {
    display: flex;
    margin-top: 0 !important;
  }
  @media screen and (max-width: 1024px) {
    .input_wrap {
      height: 40px;
      .select_default {
        height: 40px;
      }
      input {
        height: 40px;
        -webkit-appearance: none;
      }
      // ios 태블릿에서 높이값 통일시킴
    }
  }
}

// radio
.input_radio_basic {
  display: flex;
  flex-wrap: nowrap;
  gap: 0 32px;
  .box_radio {
    display: flex;
    gap: 0 8px;
    align-items: center;
    input {
      width: 20px;
      height: 20px;
      background: url('../../images/ico/ico_radio_defult.png') no-repeat center;
      background-size: 20px;
      cursor: pointer;
      background-color: transparent !important;
      &:checked {
        background: url('../../images/ico/ico_radio_select.png') no-repeat center;
        background-size: 20px;
      }
      &:disabled {
        background: url('../../images/ico/ico_radio_disable.png') no-repeat center;
        background-size: 20px;
      }
    }
    .input_radio {
      @include typo_body1;
      color: $color_bgray1;
      cursor: pointer;
    }
  }
}
.input_radio_box {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  .box_radio {
    min-width: 105px;
    input {
      display: none;
      &:checked ~ .input_radio {
        border: 1px solid $color_primary;
        color: $color_primary;
      }
      &:disabled ~ .input_radio {
        border: 1px solid $color_gray7;
        color: $color_gray6;
        background: $color_gray9;
      }
    }
    .input_radio {
      @include typo_body1;
      display: block;
      min-width: 80px;
      padding: 9px 15px;
      color: $color_bgray1;
      text-align: center;
      box-sizing: border-box;
      border: 1px solid $color_gray7;
      border-radius: 100px;
      cursor: pointer;
    }
  }
}

// range
input[type='range'] {
  -webkit-appearance: none;
  width: 100%;
  height: 6px;
  background: #d5d4d3;
  cursor: pointer;
  border-radius: 0; /* iOS */
  transition: background 450ms ease-in;
  &:focus {
    outline: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 12px;
    height: 12px;
    background: #fff;
    border: 1px solid dodgerblue;
    border-radius: 50%;
    cursor: pointer;
  }
  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    box-shadow: none;
    background: #5a74ce;
    background-size: 100% 6px;
    border-radius: 3px;
    border: none;
  }
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background: #fff;
    cursor: pointer;
    border: 5px solid #4876ee;
    height: 20px;
    width: 20px;
    margin-top: -7px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
    border-radius: 100%;
  }
}

// 할부원금에 대한 슬라이더
input[type='range'] {
  &[name='rangePrice'] {
    &::-webkit-slider-runnable-track {
      height: 12px;
      background: #ebebeb;
      background-size: 100% 12px;
      border-radius: 100px;
    }
    &::-webkit-slider-thumb {
      margin-top: 0;
      border: 2px solid #4876ee;
      height: 12px;
      width: 24px;
      border-radius: 100px;
    }
  }
}

// Checkbox
.input_checkbox_basic {
  display: flex;
  flex-wrap: nowrap;
  gap: 0 32px;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 8px;
  }
  .box_checkbox {
    display: flex;
    gap: 0 8px;
    align-items: center;
    input {
      display: block;
      width: 20px;
      height: 20px;
      background: url('../../images/ico/ico_check_defult.png') no-repeat center;
      background-size: 20px;
      background-color: transparent !important;
      cursor: pointer;
      &:checked {
        background: url('../../images/ico/ico_check_select.png') no-repeat center;
        background-size: 20px;
      }
      &:disabled {
        background: url('../../images/ico/ico_check_disable.png') no-repeat center;
        background-size: 20px;
      }
      @media screen and (max-width: 768px) {
        width: 24px;
        height: 24px;
        background-size: 24px;
        &:checked,
        &:disabled {
          background-size: 24px;
        }
      }
    }
    .input_checkbox {
      @include typo_body1;
      color: $color_bgray1;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        @include typo_body2;
        color: $color_bgray1;
      }
    }
  }
}
.input_checkbox_box {
  display: flex;
  flex-wrap: wrap;
  gap: 16px 8px;
  .box_checkbox {
    min-width: 98px;
    input {
      display: none;
      &:checked ~ .input_checkbox {
        background: $color_bgray1;
        border: 1px solid $color_bgray1;
        color: $color_white;
      }
      &:disabled ~ .input_checkbox {
        border: 1px solid $color_gray7;
        color: $color_gray6;
        background: $color_gray9;
      }
    }
    .input_checkbox {
      @include typo_body1;
      display: block;
      min-width: 98px;
      padding: 9px 11px;
      color: $color_bgray1;
      background: $color_white;
      box-sizing: border-box;
      border: 1px solid $color_gray7;
      border-radius: 4px;
      cursor: pointer;
    }
  }
}

// Checkbox_mo
@media screen and (max-width: 768px) {
}

// Pagination
.pagination {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  .page_ctrl {
    & + .page_ctrl {
      margin-left: 8px;
    }
    i {
      display: block;
    }
    & + .page_item {
      margin-left: 20px;
    }
  }
  .page_item {
    @include typo_body1;
    width: 28px;
    height: 28px;
    margin-left: 20px;
    color: $color_gray3;
    &.active {
      color: $color_white;
      background: #4876ee;
      font-weight: 600;
      border-radius: 100%;
    }
    & + .page_ctrl {
      margin-left: 20px;
    }
  }
}
.detail_pagination {
  position: relative;
  z-index: 0;
  text-align: center;
  .prev {
    @include center_top;
    &.disable {
      display: none;
    }
  }
  .next {
    @include center_top;
    left: auto;
    right: 0;
    &.disable {
      display: none;
    }
  }
}

// Table
.table_wrap {
  .com {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    gap: 0 16px;
    margin-bottom: 12px;
    .tlt {
      font-weight: 700;
    }
    .co {
      @include typo_body2;
      color: $color_gray5;
      i {
        margin-right: 4px;
      }
    }
    .down {
      @include typo_body2;
      position: relative;
      z-index: 0;
      padding-left: 16px;
      font-weight: 500;
      color: $color_black;
      a {
        i {
          margin-left: 4px;
          vertical-align: bottom;
        }
      }
      &::before {
        @include pseudo;
        @include center_top;
        width: 1px;
        height: 16px;
        background-color: $color_gray7;
      }
    }
  }
  .table_scroll_wrap {
    @include scroll_custom_btm;
    overflow-x: auto;
    table {
      min-width: 1024px;
      thead {
        th {
          // @include typo_body1;
          // font-weight: 600;
          // -webkit-text-size-adjust: none;
        }
      }
    }
  }
  table {
    width: 100%;
    border-top: 1px solid $color_black;
    th,
    td {
      padding: 12px 24px;
      vertical-align: middle;
      -webkit-text-size-adjust: none;
      color: $color_gray3;

      small {
        display: block;
        margin: 4px 0 0 0;
        font-size: 14px;
        font-weight: normal;
        color: #727272;
      }
    }
    th {
      @include typo_body1;
      background-color: #f6f7f9;
      font-weight: 600;
      color: #111;
      -webkit-text-size-adjust: none;
    }
    .tlt {
      @include typo_title1;
      font-weight: 700;
      & + dl {
        margin-top: 28px;
      }
    }
    .layout {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
    }
    .txt_center {
      text-align: center;
    }
    .txt_right {
      text-align: right;
    }
    .diagonal_layout {
      position: relative;
      z-index: 0;
      svg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      span {
        position: absolute;
        z-index: 1;
        &.b {
          bottom: 16px;
          left: 16px;
        }
        &.t {
          top: 16px;
          right: 16px;
        }
      }
    }
    thead {
      border-bottom: 1px solid $color_gray8;
      th {
        // @include typo_title1;
        // font-weight: 700;
      }
      tr + tr {
        th {
          border-top: 1px solid $color_gray8;
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $color_gray8;
      }
    }
    dl {
      line-height: 150%;
      dt {
        font-weight: 600;
      }
      dd + dt {
        margin-top: 12px;
      }
    }
    & + .info {
      margin-top: 18px;
      & + .info {
        margin-top: 18px;
      }
    }
    & + .pagination {
      margin-top: 44px;
    }
  }
  .table_scroll_wrap + .info {
    margin-top: 18px;
  }
  .table_scroll_wrap + .pagination {
    margin-top: 44px;
  }

  .sub {
    color: $color_gray5;
  }
  .pagination + .info {
    margin-top: 24px;
  }

  // th left type
  &.th_lf {
    table {
      min-width: auto;
      th,
      td {
        padding: 18px 24px;
        text-align: left;
      }
      th {
        @include typo_body1;
        background-color: #f6f7f9;
        font-weight: 600;
        color: #111;
        -webkit-text-size-adjust: none;
        & + td {
          border-left: 1px solid $color_gray8;
        }
      }
      td {
      }
    }
  }
}

// Visual
.visual {
  padding-bottom: 60px;
  text-align: center;
  .tlt {
    @include typo_header1;
    color: $color_black;
  }
  .btn_layout,
  .input_layout {
    margin-top: 40px;
  }
  > .form_wrap {
    margin-top: 60px;
    margin-left: auto;
    width: 240px;
  }
  &.img_bg {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-end;
    padding-bottom: 0;
    height: 100vh;
    overflow: hidden;
    position: relative;
    .bg_img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #ccc;
      animation-name: backscale;
      animation-duration: 1.2s;
      animation-iteration-count: 1;
      animation-direction: alternate;
      animation-fill-mode: forwards;
      animation-delay: 1s;
    }
    > .inner {
      width: 100%;
      text-align: left;
    }

    .tlt {
      color: $color_white;
    }
    .sub_tlt {
      @include typo_title1;
      margin-top: 20px;
      white-space: pre-line;
      color: $color_white;
    }
  }
  &.inner {
    padding-bottom: 20px;
  }
}
.top_cont {
  margin-bottom: 12px;
  text-align: center;
  .btn_layout,
  .input_layout {
  }
  .form_wrap {
    margin-top: 8px;
    margin-left: auto;
    width: 240px;
  }
}

.btn_layout,
.input_layout {
  &.tab {
    display: flex !important;
    justify-content: center;
    flex-wrap: nowrap;
    margin: 40px auto 0;
    > button {
      margin: 0 20px;
      width: 305px;
      max-width: 305px;
      height: 56px;
      font-size: 20px;
      font-weight: 500;
      line-height: 160%;
      border-bottom: 3px solid #fff;
      &.active {
        border-color: #4876ee;
      }
    }
  }
}
.tab-head {
  margin-bottom: 76px;
  text-align: center;
  overflow: auto;
  border-bottom: 1px solid #d5d8db;
  &.outinner {
    margin-bottom: 68px;
    & + .inner {
      padding-top: 0;
    }
  }
  .tab-inner {
    display: inline-block;
    width: 100%;
    max-width: 1528px;
    text-align: center;
    white-space: nowrap;
    button {
      display: inline-block;
      margin: 0 3.3% 0;
      height: 72px;
      font-size: 18px;
      font-weight: 500;
      line-height: 160%;
      white-space: nowrap;
      border-bottom: 2px solid transparent;
      &.active {
        color: #4876ee;
        font-weight: 700;
        border-color: #4876ee;
      }
    }
  }
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }

  // mo
  @media screen and (max-width: 768px) {
    margin-top: 32px;
    margin-bottom: 32px;
    &.outinner {
      margin-bottom: 32px;
    }
    .tab-inner {
      text-align: left;
      button:first-of-type {
        margin-left: 24px;
      }
      button {
        height: 38px;
        margin: 0 20px 0 0;
        font-size: 16px;
        line-height: 150%;
      }
    }
  }
  &.event_tab {
    // margin-bottom: 0;
  }
}

.info {
  @include typo_body2;
  display: inline-block;
  width: 100%;
  color: $color_black;
}

// board
.board {
  .date {
    color: $color_gray5;
    & + .tlt {
      margin-top: 12px;
    }
  }
  .tlt {
    @include typo_header3;
    display: inline-block;
    width: 100%;
  }
  .cont {
    margin-top: 48px;
    .img_wrap {
      & + .desc {
        margin-top: 40px;
      }
      > img {
        // width: auto;
        // max-width: 100%;
      }
    }
    .desc {
      padding-bottom: 40px;
      color: $color_gray3;
      border-bottom: 1px solid $color_gray8;
      img {
        width: 100%;
        height: auto !important;
        max-width: 1528px !important;

        @media screen and (max-width: 1367px) {
          width: 100% !important;
          max-width: none !important;
        }
      }
    }
  }
  & + [class*='pagination'] {
    margin-top: 28px;
  }
}

// relation page
.relation {
  background-color: $color_gray11;
}
.relation_wrap {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 20px;
  @for $list from 1 to 8 {
    li {
      &.relationBg#{$list} {
        background: url('../../images/img/img_relation1_' + $list + '.jpg') no-repeat center center;
        background-size: cover;
      }
    }
  }
  li {
    overflow: hidden;
    border-radius: 4px;
  }
  .page {
    @include typo_title1;
    position: relative;
    z-index: 0;
    padding: 48px 32px;
    width: 100%;
    font-weight: 700;
    color: $color_white;
    &::before {
      @include pseudo;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      // background-color: rgba($color: $color_black, $alpha: 0.4);
    }
  }
  &.inner {
    padding-top: 56px;
    padding-bottom: 56px;
  }
}

// card content
.card_cont1 {
  width: 100%;
  .img_wrap {
    overflow: hidden;
    width: 100%;
    height: 280px;
    background-color: #edf0f4;
    background-image: url('../../images/ico/ico_list_empty.png');
    background-repeat: no-repeat;
    background-size: 120px;
    background-position: center center;
    img {
      width: 100%;
      height: 280px;
      object-fit: cover;
      transition: transform 0.3s ease-in-out;
    }
    & + .tlt {
      margin-top: 28px;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
  .tlt {
    @include typo_title1;
    @include ellipse_2;
    width: 100%;
    font-weight: 700;
    & + .date {
      margin-top: 24px;
    }
    & + .desc {
      margin-top: 8px;
    }
  }
  .desc {
    & + .date {
      margin-top: 24px;
    }
  }
  .date {
    display: inline-block;
    color: $color_gray5;
  }
  &.add_txt {
    .img_wrap {
      position: relative;
      z-index: 0;
      &::after {
        @include center;
        content: '종료된 이벤트';
        padding: 6px 16px;
        color: $color_white;
        background-color: rgba($color: $color_black, $alpha: 0.6);
        border-radius: 1100px;
      }
      &::before {
        @include pseudo;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: $color_black, $alpha: 0.6);
      }
      &:hover {
        img {
          transform: scale(1);
        }
      }
    }
    &:hover {
      cursor: auto;
    }
  }
}
.card_cont2 {
  display: flex;
  flex-wrap: wrap;
  gap: 0 20px;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: $color_gray10;
  .img_wrap {
    width: 85px;
    height: 105px;
    border-radius: 4px;
    overflow: hidden;
    img {
      object-fit: cover;
    }
  }
  .info_wrap {
    width: calc(100% - 105px);
    .tlt {
      color: $color_black;
      font-weight: 600;
    }
    dl {
      display: flex;
      flex-wrap: wrap;
      gap: 4px 8px;
      margin-top: 8px;
      dt,
      dd {
        @include typo_body2;
      }
      dt {
        width: 50px;
        color: $color_gray5;
      }
      dd {
        width: calc(100% - 58px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
.card_cont3 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 0 24px;
  padding: 32px;
  background-color: #f6f7f9;
  border-radius: 4px;
  .cont_l {
    width: calc(100% - 84px);
  }
  .tlt {
    @include typo_header4;
    color: $color_black;
  }
  .desc {
    @include typo_body2;
    margin-top: 8px;
    color: $color_gray5;
  }
}

@media screen and (max-width: 1600px) {
  .visual {
    .tlt {
      // font-size: 48px;
      @include typo_header2;
    }
  }
}

// tablet
@media screen and (max-width: 1024px) {
  //list
  .list_wrap1 {
    .list {
      .tlt_wrap {
        .tlt {
          max-width: calc(100% - 207px);
          line-height: 140%;
        }
      }
      .desc {
        width: calc(100% - 170px);
        margin-top: 6px;
      }
    }
  }
  .visual {
    padding-bottom: 45px;
    .tlt {
      @include typo_header2;
    }

    &.img_bg {
      height: 580px;
      > .inner {
        padding-bottom: 60px;
      }
      .sub_tlt {
        @include typo_body2;
        margin-top: 12px;
        line-height: 165%;
      }
    }
  }

  // board
  .board {
    .tlt {
      font-size: 30px;
      line-height: 140%;
    }
    .cont {
      margin-top: 40px;
    }
  }

  // relation page
  .relation_wrap {
    gap: 0 16px;
    .page {
      @include typo_body1;
      padding: 40px 14px;
      font-weight: 600;
    }
  }
}

// @media (max-width: 1080px) {
//   .table_wrap {
//     .table_scroll_wrap {
//       margin-left: -40px !important;
//       margin-right: -40px;
//     }
//   }
// }

// mobile
@media screen and (max-width: 768px) {
  // visual
  .visual {
    position: relative;
    z-index: 0;
    padding-bottom: 32px;
    text-align: left;
    .tlt {
      font-weight: 700;
      font-size: 30px;
      line-height: 125%;
      text-align: left;
    }
    .btn_layout {
      @include scroll_custom_btm;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-right: -24px;
      [class*='btn'] {
        min-width: fit-content;
      }
    }
    .search_btn {
      position: absolute;
      top: 8px;
      right: 0;
      z-index: 1;
    }
    > .form_wrap {
      margin-top: 32px;
      width: 100%;
    }
  }
  .top_cont {
    position: relative;
    z-index: 0;
    .btn_layout {
      @include scroll_custom_btm;
      display: flex;
      flex-wrap: nowrap;
      overflow-x: auto;
      margin-top: 60px;
      margin-right: -24px;
      [class*='btn'] {
        min-width: fit-content;
      }
    }
    .search_btn {
      position: absolute;
      top: 8px;
      right: 0;
      z-index: 1;
    }
    > .form_wrap {
      margin-top: 32px;
      width: 100%;
    }
  }
  .input_layout {
    display: none;
    flex-direction: column;
    justify-content: flex-start;
    gap: 24px 0;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    padding: 0 24px;
    width: 100%;
    height: 100vh;
    background-color: $color_white;
    .tlt {
      padding: 56px 0 12px;
      font-size: 24px;
      font-weight: 700;
      line-height: 140%;
    }
    .form_wrap {
      width: 100%;
      &:last-child {
        display: flex;
        flex-direction: column;
        gap: 24px 0;
        width: 100%;
        .input_wrap,
        .btn_second_m {
          margin: 0;
          width: 100%;
        }
        .btn_second_m {
          padding: 12px 16px;
          background-color: #222e59;
          border: 0;
        }
      }
    }
    .close_btn {
      position: absolute;
      top: 20px;
      right: 24px;
      z-index: 1;
    }
  }

  // table

  .table_wrap {
    .table_scroll_wrap {
      margin-left: -24px !important;
      margin-right: -24px;
    }
    &.th_lf {
      table {
        th,
        td {
          padding: 12px;
          font-size: 14px;
        }
      }
      &.th_lf {
        table {
          td {
            @include typo_body2;
          }
        }
      }
      .table_scroll_wrap {
        margin-right: -24px;
      }
      .table_scroll_wrap + .pagination {
        margin-top: 20px;
      }
      .pagination + .info {
        margin-top: 60px;
      }
    }
    table {
      .tlt {
        font-size: 18px;
        line-height: 135%;
        & + dl {
          margin-top: 16px;
        }
      }
      thead {
        th {
          @include typo_body1;
          padding: 12px;
          font-weight: 700;
          text-align: center;
        }
      }
      dl {
        dt,
        dd {
          @include typo_body2;
          line-height: 165%;
          font-weight: 600;
        }
        dd {
          font-weight: 400;
        }
      }
    }
  }

  // form
  .form_wrap {
    .err_msg {
      margin-top: 2px;
    }
    .select_default {
      // padding: 12px 16px;
      padding: 12px 8px;
      &::after {
        top: 12px;
        // right: 10px;
        right: 4px;
      }
    }
    input {
      @include typo_body1;
      padding: 12px 16px;
      &::placeholder {
        @include typo_body1;
      }
    }
    .input_select {
      @include typo_body1;
      font-weight: 700;
      background-color: transparent;
    }
    .select_layout {
      position: relative;
      z-index: 0;
      gap: 8px 24px;
      margin-left: 32px;
      width: calc(100% - 92px);
      .select {
        width: 100%;
      }
      &::after {
        @include pseudo;
        top: 45px;
        left: -120px;
        z-index: 1;
        width: calc(100% + 120px);
        height: 1px;
        background-color: $color_gray3;
      }
    }
    .select {
      padding: 12px 0;
      min-width: auto;
    }
    &.line_ty {
      input {
        padding: 12px 0;
        font-size: 18px;
        font-weight: 700;
        line-height: 135%;
        &::placeholder {
          font-size: 18px;
          line-height: 135%;
        }
      }
      .input_wrap {
        .label {
          padding: 12px 0;
          width: 70px;
          line-height: 135%;
          &.require {
            &::after {
              top: 4px;
            }
          }
          & + input {
            margin-left: 32px;
            width: calc(100% - 102px);
          }
        }
        [class*='btn_'] {
          padding: 0;
          border: 0;
          &:hover {
            background-color: transparent;
          }
        }
      }
    }
  }

  // pagination
  .pagination {
    .page_ctrl {
      & + .page_item {
        margin-left: 10px;
      }
    }
    .page_item {
      @include typo_body2;
      margin-left: 12px;
      line-height: 165%;
      & + .page_ctrl {
        margin-left: 10px;
      }
      & + .active {
        font-weight: 600;
      }
    }
  }
  .detail_pagination {
    span {
      display: none;
    }
    i {
      margin: 0;
    }
    .prev {
      @include center_top;
    }
    .next {
      @include center_top;
      left: auto;
      right: 0;
    }
  }

  // list
  [class*='list_wrap'] {
    .list {
      padding: 20px 0 52px;
      .tlt_wrap {
        .tlt {
          font-weight: 700;
          font-size: 18px;
          line-height: 135%;
        }
      }
    }
  }
  .list_wrap1 {
    .list {
      position: relative;
      z-index: 0;
      .tlt_wrap {
        .tlt {
          max-width: 100%;
        }
        .date {
          @include typo_body2;
          position: absolute;
          bottom: 20px;
          left: 0;
          z-index: 1;
          line-height: 165%;
        }
      }
      .desc {
        @include typo_body2;
        margin-top: 4px;
        line-height: 165%;
        width: 100%;
      }
    }
    & + .pagination {
      margin-top: 20px;
    }
  }
  .list_wrap2 {
    grid-template-columns: 1fr;
    gap: 40px 0;
    & + .pagination {
      margin-top: 20px;
    }
  }
  .list_wrap3 {
    padding-left: 22px;
    li {
      @include typo_body2;
      font-weight: 600;
      line-height: 165%;
      text-indent: -22px;
    }
  }
  .list_wrap4 {
    border-top: 0;
    .list {
      padding: 0;
      .tlt_wrap {
        padding: 20px 0;
        .tlt {
          line-height: 32px;
          font-size: 16px;
          line-height: 145%;
        }
      }
      &.active {
        .acco_cont {
          padding: 20px;
        }
      }

      .acco_cont {
        @include typo_body2;
        line-height: 165%;
      }
    }
  }
  .list_dl1 {
    dt {
      @include typo_body3;
      line-height: 165%;
    }
    dd {
      @include typo_body2;
      line-height: 165%;
    }
  }
  .list_dl3 {
    dt {
      @include typo_body1;
      font-weight: 500;
    }
    dd {
      @include typo_body2;
      line-height: 165%;
    }
  }
  .info {
    font-size: 11px;
    > li {
      padding-left: 8px;
      text-indent: -8px;
    }
  }

  // board
  .board {
    .date {
      @include typo_body2;
      & + .tlt {
        margin-top: 9px;
      }
    }
    .tlt {
      font-size: 24px;
    }
    .cont {
      margin-top: 32px;
      .img_wrap {
        img {
          width: auto;
          max-width: 100%;
        }
        & + .desc {
          margin-top: 20px;
        }
      }
      .desc {
        @include typo_body2;
        padding-bottom: 32px;
        line-height: 165%;
      }
    }

    & + [class*='pagination'] {
      margin-top: 20px;
    }
  }

  // relation page
  .relation_wrap {
    grid-template-columns: 1fr;
    gap: 14px 0;
    .page {
      padding: 40px 20px;
      font-weight: 500;
    }
    &.inner {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  // card content
  .card_cont1 {
    .img_wrap {
      width: 100%;
      height: 200px;
      img {
        height: 200px;
      }
      & + .tlt {
        margin-top: 16px;
      }
    }
    .tlt {
      @include typo_body1;
      font-weight: 700;
      & + .date {
        margin-top: 8px;
      }
    }
    .date {
      @include typo_body2;
    }
    &.add_txt {
      .img_wrap {
        position: relative;
        z-index: 0;
        &::after {
          @include center;
          content: '종료된 이벤트';
          padding: 6px 16px;
          color: $color_white;
          background-color: rgba($color: $color_black, $alpha: 0.6);
          border-radius: 1100px;
        }
        &::before {
          @include pseudo;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba($color: $color_black, $alpha: 0.6);
        }
      }
    }
  }
  .card_cont2 {
    .info_wrap {
      .tlt {
        font-weight: 700;
      }
      dl {
        flex-direction: column;
        gap: 0;
        dt,
        dd {
          @include typo_body3;
        }
        dd + dt {
          margin-top: 8px;
        }
      }
    }
  }
  .card_cont3 {
    padding: 30px 24px;
    .tlt {
      font-size: 18px;
      line-height: 135%;
    }
    .desc {
      @include typo_body3;
    }
  }
}
body.popup {
  overflow: hidden;
}
@keyframes bgscale {
  0% {
    scale: 100%;
  }
  100% {
    scale: 96%;
  }
}
@keyframes backscale {
  0% {
    scale: 100%;
  }
  100% {
    scale: 110%;
  }
}
body {
  padding-bottom: env(safe-area-inset-bottom);
}

// scroll top button
.btnTop {
  position: fixed;
  right: 14px;
  bottom: 30px;
  width: 44px;
  height: 44px;
  background: rgba($color: #fff, $alpha: 0.8) url('../../images/ico/btn_top.png') no-repeat center;
  background-size: 14px auto;
  box-shadow: 0px 2px 5px rgba(79, 85, 94, 0.25);
  border-radius: 100px;
  // z-index: 0;
  opacity: 0;
  z-index: 100;
  transition: opacity 0.15s ease;
  &.visible {
    opacity: 1;
  }

  @media screen and (min-width: 769px) and (max-width: 1080px) {
    right: 22px;
    bottom: 22px;
  }

  @media screen and (max-width: 768px) {
    right: 10px;
    bottom: 22px;
  }
}

.after-loader {
  background: #111;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10000;
  transition: all 1.5s ease;
}

.after-loader[data-load='true'] {
  transform: translateX(100%);
}

.spinner1212 {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}

.spinner1212 > div {
  background-color: #333;
  height: 100%;
  width: 5px;
  margin: 0 2px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner1212 .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner1212 .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner1212 .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner1212 .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
