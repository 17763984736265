.reservdoc {
  break-after: page;
}

.reservdoc + .reservdoc {
  margin-top: 40px;
}

@media print {
  .reservdoc {}
  .reservdoc .page {
    padding: 0;
  }
  .reservdoc .page .inner .doc_content {
    position: relative;
    padding: 80px 0 40px;
    height: 308mm;
  }
  .reservdoc .page .inner .doc_header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
  .reservdoc .page .inner .doc_footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .reservdoc + .reservdoc {
    margin-top: 0;
  }
}

@page {
  size: A4;
  margin: 20mm;
}
