header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  overflow: hidden;
  width: 100%;
  height: 72px;
  transition: height, top 0.15s linear;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
  &.showDown {
    top: -72px;
  }
  &.showUp {
    top: 0;
  }
  .top_menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0 40px;
    background-color: $color_white;
    transition: background-color 300ms 150ms ease-in-out;
    .logo {
      display: block;
      width: 57px;
      height: 17px;
      background: url(../../images/ico/ico_logo_bk.svg) no-repeat 50%; // todo navbar-height / background -> image
      background-size: cover;
    }
  }
  nav {
    line-height: 72px;
  }
  // logo x logo
  .logo_wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 0 63px;
    .logo {
      &:nth-child(1) {
        width: 100px;
        height: 32px;
        background: url('../../images/ico/logo_t4k.svg') no-repeat center center;
        background-size: 100px;
      }
      &:nth-child(2) {
        position: relative;
        z-index: 0;
        width: 150px;
        height: 40px;
        background: url('../../images/ico/ico_logo_gs.svg') no-repeat center center;
        background-size: 150px;
        &::before {
          @include pseudo;
          @include center_top;
          left: -38px;
          width: 15px;
          height: 15px;
          background: url('../../images/ico/ico_logo_between.png') no-repeat center center;
          background-size: 15px;
        }
      }
    }
  }
  .main_menu {
    display: flex;
    flex-wrap: wrap;
    font-weight: 600;
    > li {
      width: 142px;
      text-align: center;
      color: $color_black;
      a {
        font-size: 18px;
        cursor: default;
      }
    }
  }
  .btn_radius {
    font-size: 15px;
    font-weight: 600;
    background: none;
    border: 1px solid rgba(213, 213, 213, 0.5);
    background: #fff;
    button {
      color: inherit;
    }
  }

  .snsWrap { position:absolute; right:255px; top:2px; }
  .snsIcon { width:36px; height:36px; text-indent:-1000px; font-size:0; 
    background: url('../../images/ico/snsIcoSet_72x72.png') no-repeat; background-size:500%; }  
  .snsIcon.youtube { background-position:0 0; }
  .snsIcon.insta { background-position:25% 0; }
  .snsIcon.blog { background-position:50% 0; }
  .snsIcon.kakao { background-position:75% 0; }


  .ham_menu {
    width: 56px;
    height: 56px;
    background: url('../../images/ico/mo_ico_gnb_bk.png') no-repeat center center;
    background-size: 56px;
  }
  .sub_menu {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
    padding-top: 24px;
    padding-right: 152px;
    height: 296px;
    background-color: $color_white;
    .sub_mu_tlt {
      @include typo_header4;
      font-size: 24px;
      text-align: left;
    }
    .ham_menu_del {
      padding: 1.75rem;
      position: absolute;
      top: 0;
      right: 0.5rem;
      z-index: 1;
      background: url('../../images/ico/ico_close.png') no-repeat center center;
      background-size: 20px;
    }
    > li {
      @include typo_body2;
      display: flex;
      flex-direction: column;
      width: 142px;
      text-align: center;
      color: $color_black;
      a:not(.btn_radius) {
        font-size: 16px;
        line-height: 140%;
        transition: all 0.2s;
        padding-bottom: 8px;
        &:hover {
          font-weight: 700;
          color: #19254d;
        }
      }
      .snsWrap {display:block !important; position:relative; right:auto; top:auto; z-index:100000; margin-bottom:10px; margin-left:10px;
        .snsIcon { display:inline-block; }
        .snsIcon { width:36px; height:36px; text-indent:-1000px; font-size:0; 
          background: url('../../images/ico/snsIcoSet_72x72.png') no-repeat; background-size:500%; }  
        .snsIcon.youtube { background-position:0 33.3333%; }
        .snsIcon.insta { background-position:25% 33.3333%; }
        .snsIcon.blog { background-position:50% 33.3333%; }
        .snsIcon.kakao { background-position:75% 33.3333%; }
      }
      a + a {
        padding-top: 8px;
      }
    }
  }
  &.active {
    height: 369px;
    transition: height 300ms 150ms ease-in-out;

    .snsIcon.youtube { background-position:0 33.3333%; }
    .snsIcon.insta { background-position:25% 33.3333%; }
    .snsIcon.blog { background-position:50% 33.3333%; }
    .snsIcon.kakao { background-position:75% 33.3333%; }
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 72px;
    width: 100%;
    height: 1px;
    background: #d5d8db;
  }

  //   mobile
  @media screen and (max-width: 1080px) {
    display: block;
    height: 56px;
    &.active {
      height: 56px;
    }
    .top_menu {
      padding: 0 8px 0 16px;
      .logo {
        width: 56px;
        height: 18px;
        background-size: contain;
      }
    }
    // logo x logo
    .logo_wrap {
      gap: 0 38px;
      .logo {
        width: 45px;
        height: 18px;
        &:nth-child(1) {
          width: 60px;
          height: 20px;
          background-size: 60px;
        }
        &:nth-child(2) {
          width: 90px;
          height: 24px;
          background-size: 90px;
          &::before {
            left: -24px;
            width: 9px;
            height: 9px;
            background-size: 9px;
          }
        }
      }
    }
    .sub_menu {
      &[data-show='true'] {
        transform: translateX(0);
        transition: transform 300ms 150ms ease-in-out;
      }
      height: auto;
      flex-direction: column;
      justify-content: flex-start;
      gap: 24px;
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 200;
      padding: 56px 40px 32px;
      width: 100%;
      transform: translateX(100%);
      transition: transform 300ms 150ms ease-in-out;
      overflow-y: auto;
      -ms-overflow-style: none; /* 인터넷 익스플로러 */
      scrollbar-width: none; /* 파이어폭스 */
      z-index: 100;
      &::-webkit-scrollbar {
        display: none; // 크롬
      }
      > li {
        width: auto;
        text-align: left;
        gap: 8px;
        > button {
          margin-bottom: 16px;
        }
        a:not(.btn_radius) {
          @include typo_body1;
          display: none;
          height: 0;
          // margin-top: 10px;
          margin: 0;
          padding: 0;
          font-weight: 500;
          color: $color_black;
          transition: height 0.2s ease-in-out;

          &.active {
            display: block;
            height: 100%;
            transition: height 0.2s ease-in-out;
          }
        }

        & + li {
          // margin-top: 20px;
        }
        &:last-child {
          margin-top: auto;
        }
      }
    }
    &::after {
      content: none;
    }
  }

  &.bg_tr {
    .top_menu {
      background-color: transparent;
      transition: background-color 300ms 150ms ease-in-out;
    }

    .logo {
      background: url('../../images/ico/ico_logo_wh.svg') no-repeat center center;
      background-size: 66px;
      transition: background 300ms 150ms ease-in-out;
    }
    .main_menu {
      > li {
        a {
          color: $color_white;
          transition: color 300ms 150ms ease-in-out;
        }
      }
    }
    .sub_menu {
      background-color: transparent;
      transition: background-color 300ms 150ms ease-in-out;
    }

    // hover
    &.active {
      .top_menu {
        background-color: $color_white;
        transition: background-color 300ms 150ms ease-in-out;
      }
      .logo {
        background-image: url('../../images/ico/ico_logo_bk.svg');
        background-size: 66px;
        transition: background 300ms 150ms ease-in-out;
      }
      .main_menu {
        > li {
          a {
            color: $color_black;
            transition: color 300ms 150ms ease-in-out;
          }
        }
      }
      .sub_menu {
        background-color: $color_white;
        transition: background-color 300ms 150ms ease-in-out;
      }
    }
  }
  &.scrollFix {
    .top_menu {
      background-color: $color_white;
      .logo {
        background-image: url(../../images/ico/ico_logo_bk.svg);
      }
    }
    .main_menu {
      > li {
        color: $color_black;
      }
    }
  }
}
// 브랜드 페이지 예외 + 메인 예외
// #root:has(.gsg, .byd, .main, .introduce) header {
#root:has(.gsg, .byd, .main) header {
  // position: absolute;
  box-shadow: none;
  .top_menu {
    background: none;
    //transition: background-color 0ms 0ms ease-in-out;
    .main_menu {
      > li a {
        color: #fff;
        transition: all 1.5s;
      }
    }
    .logo {
      background: url(../../images/ico/logo_t4k.svg) no-repeat 50%;
      background-size: cover;
      // transition: background-image 1.5s;
    }
  }
  &::after {
    background: transparent;
  }
  &.active {
    &::after {
      background: #d5d8db;
    }
    .top_menu {
      background: #fff;
      .main_menu {
        > li a {
          color: #050d1b;
          transition: all 1.5s;
        }
      }
      .logo {
        background: url(../../images/ico/ico_logo_bk.svg) no-repeat 50%;
        background-size: cover;
        transition: all 1.5s;
      }
    }
  }

  .ham_menu {
    background: url(../../images/ico/mo_ico_gnb.png) no-repeat 50%;
    background-size: 56px;
  }

  &.showUp {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    .top_menu {
      background: $color_white;
      .main_menu {
        > li a {
          color: $color_black;
        }
      }
    }
  }
  &.showDown,
  &.showUp {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    .logo {
      background-image: url(../../images/ico/ico_logo_bk.svg);
    }
    .ham_menu {
      background-image: url('../../images/ico/mo_ico_gnb_bk.png');
    }
  }

  &.scrollFix {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
    .top_menu {
      background-color: $color_white;
      .logo {
        background-image: url(../../images/ico/ico_logo_bk.svg);
      }

      .ham_menu {
        background-image: url('../../images/ico/mo_ico_gnb_bk.png');
      }
    }
    .main_menu {
      > li a {
        color: $color_black;
      }
    }
  }
}
#root:has(.gsg, .byd, .main) .wrap .content {
  margin-top: 0;
}
@media screen and (max-width: 1080px) {
  #root:has(.gsg, .byd, .main, .introduce) header {
    &.active {
      .top_menu {
        background: none;
        .logo {
          background: url(../../images/ico/logo_t4k.svg) no-repeat 50%;
          background-size: cover;
        }
      }
    }
    &.showDown,
    &.showUp {
      .logo {
        background-image: url(../../images/ico/ico_logo_bk.svg);
      }
    }
  }
}

@media screen and (min-width: 1079px) and (max-width: 1280px) {
  header {
    .snsWrap { top:73px; right:50px; z-index:1000; }
  }
}

//   tablet
// @media screen and (min-width: 769px) and (max-width: 1080px) {
//   header {
//     .sub_menu {
//       // position: relative;
//       width: 55vw;
//       right: -14px;
//       box-shadow: -1vw 0 1vw rgba(0, 0, 0, 0.1);
//       // &.show {
//       //   transform: translateX(20vw);
//       // }
//     }
//   }
// }
