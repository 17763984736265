.gallery {
  overflow: hidden;

  .gallery_wrap {
    .gall_main {
      position: relative;
      z-index: 0;
      height: 750px;
      img {
        height: 100%;
      }
      .player-wrapper {
        height: 750px;
      }
      .circle_progress_wrap {
        @include center;
        background-color: rgba($color: $color_gray10, $alpha: 0.3);
        border-radius: 50%;
      }
      .tlt {
        @include typo_header2;
        position: absolute;
        bottom: 44px;
        left: 50px;
        max-width: 720px;
        color: $color_white;
      }
      &.type01{
        height:auto;
        .player-wrapper {
          height:auto;
          img{
            max-width:100%;
          }
        }
      }
    }
    
    .gall_list {
      margin-top: 60px;
      padding-bottom: 30px;

      .swiper {
        overflow: visible;
      }
      .swiper-wrapper {
        padding-right: 32px;
      }
      .swiper-pagination {
        display: flex;
        bottom: -36px;
      }
      .swiper-pagination-bullet {
        height: 4px !important;
        margin: 0;
        flex: auto;
      }
      .swiper-pagination-bullet-active {
        background: #4876ee !important;
      }
      .swiper-slide {
        width: 433px;
        height: 211px;
        margin-right: 32px;
      }
      [class*='item'] {
        display: block;
        width: 100%;
        height: 100%;
        .tlt {
          @include typo_body1;
          @include ellipse;
          position: absolute;
          bottom: 24px;
          left: 24px;
          // max-width: 216px;
          width: calc(100% - 48px);
          font-weight: 600;
          text-align: left;
          color: $color_white;
        }
      }
      @for $gall from 0 to 2 {
        .item_#{$gall} {
          //background: url('../../images/img/img_gallery' + $gall + '.png') no-repeat center center;
          background-size: cover;
        }
      }
    }
  }

  @media screen and (max-width: 1080px) {
    .gallery_wrap {
      &.pc_view {
        display: block !important;
        .gall_main {
          .tlt {
            // color: #000;
            left: 30px;
            font-size: 32px;
          }
        }
      }
    }
  }
  //   mobile
  @media screen and (max-width: 768px) {
    .gallery_wrap {
      &.pc_view {
        display: none !important;
        .gall_main {
          .tlt {
            color: #000;
          }
        }
      }
      &.mo_view {
        position: relative;
        z-index: 0;
        margin: 0 -24px;
        padding-bottom: 52px;
        .gall_main {
          width: 100%;
          // height: 500px;
          height: auto;
          .player-wrapper {
            // height: 500px;
            height: auto;
          }
          .tlt {
            @include ellipse;
            display: block;
            bottom: 24px;
            left: 24px;
            width: calc(100% - 48px);
            font-size: 18px;
            line-height: 135%;
          }
        }
        // swiper
        .swiper {
          position: static;
        }
        .swiper-button-prev,
        .swiper-button-next {
          top: auto;
          bottom: 0;
          z-index: 50;
          width: 36px;
          height: 36px;
        }
        .swiper-button-prev {
          left: 24px;
        }
        .swiper-button-next {
          right: 24px;
        }
      }
    }
  }
}
