.faq {
  .visual {
    // background: url('../../images/img/img_visual_faq.jpg') no-repeat center center;
    // background-size: cover;
    .bg_img {
      background: url(../../images/img/img_visual_faq.jpg) no-repeat 50%;
      background-size: cover;
    }
  }
  .top_cont {
    margin-bottom: 40px;
  }
  [data-contain] {
    display: none;
    &.active {
      display: block;
    }
  }

  .active + .active .list_wrap4 {
    border-top: 0;
  }

  // mobile
  @media screen and (max-width: 768px) {
    .visual {
      // background: url('../../images/img/mo_img_visual_faq.jpg') no-repeat center center;
      // background-size: cover;
      .bg_img {
        background: url(../../images/img/mo_img_visual_faq.jpg) no-repeat 50%;
        background-size: cover;
      }
    }
    .top_cont {
      margin-bottom: 0;
    }
  }
}
.tab-head.type01{
  border-bottom:0;
  margin-bottom:0;
  .tab-inner{
    display:flex;
    justify-content:center;
    gap:20px;
    margin:0 auto;
    z-index:10;
    button{
      margin:0;
      height:50px;
      line-height:50px;
      padding:0 50px;
      border-radius:25px;
      border:0;
      background:#f4f4f4;
    }
    button.active{
      border:0;
      background:#4876ee;
      color:#fff;
    }
  }

  @media screen and (max-width: 768px) {
    .tab-inner{
     justify-content:left;
     margin-left:24px;
     flex-wrap:wrap;
     gap:5px 5px;
     button{
      height:40px;
      line-height:40px;
      padding:0 25px;
      border-radius:20px;
     }
    }
  }  
}

.tab-head.type01 + .inner {
  padding-top:60px;
  @media screen and (max-width: 768px) {
    padding-top:30px;
  }
}