.form {
  .input_radio_box {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    & + .form_wrap {
      margin-top: 46px;
    }
  }
  .form_wrap + .form_wrap {
    margin-top: 34px;
  }
  .form_wrap .input_wrap .input_txt2{
    width:auto !important;
  }
  .form_wrap .input_wrap .button{
    width:max-content;
  }
  .input_checkbox_basic {
    margin-top: 24px;
    .box_checkbox {
      .input_checkbox {
        color: $color_bgray3;
      }
      label {
        margin-left: -30px;
        padding-left: 30px;
      }
    }
    & + .input_checkbox_basic {
      margin-top: 12px;
    }
  }
  .btn_primary_l {
    margin-top: 40px;
    width: 100%;
  }
  
  //   mobile
  @media screen and (max-width: 768px) {
    .form_wrap {
      .input_wrap {
        .label, .label2 {
          @include typo_body2;
          line-height: 165%;
        }
      }
      &.line_ty {
        input {
          @include typo_body1;
          width: calc(100% - 92px);
          font-weight: 700;
          &::placeholder {
            @include typo_body1;
          }
        }
      }
    }
  }
}
// 구매예약
.buy_reservation {
  .visual.img_bg {
    position: relative;
    background: url(../../images/img/img_visual_reservation.jpg) no-repeat 50%;
    background-size: cover;
    height: auto;
    .inner {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      height: calc(100vh - 240px);
      min-height: 890px;
    }
  }
  .reserv_input {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 0 60px;
    .img_wrap {
      width: 60%;
    }
    .form {
      width: calc(40% - 60px);
    }
  }
  .cont_wrap {
    display: flex;
    justify-content: space-between;
    padding: 70px 0;
    .txt-slogun {
      margin-top: 40px;
      font-size: 28px;
      font-weight: 700;
      line-height: 145%;
      color: #fff;
      strong {
        display: block;
        margin-top: 8px;
        font-size: 48px;
        font-weight: 700;
        line-height: 120%;
        color: #fff;
      }
    }
  }
  .contain-reservation {
    width: 496px;
    height: 580px;
    padding: 52px 40px 40px;
    background: #fff;
    box-shadow: 0px 0px 40px rgba(155, 155, 155, 0.12);
    border-radius: 12px;
  }
  .reserv_input {
    .form {
      width: auto;
      .input_radio_box {
        margin-bottom: 20px;
        .box_radio {
          .input_radio {
            font-weight: 400;
            background: #edf0f4;
            border: none;
          }
          input:checked ~ .input_radio {
            color: #fff;
            font-weight: 700;
            background: #4876ee;
          }
        }
      }
      .form_wrap.line_ty {
        margin-top: 5px;
        input {
          padding: 16px 0;
        }
        .input_wrap {
          // align-items: flex-end;
          align-items: center;
          .label {
            margin-top: 20px;
            margin-bottom: 12px;
            line-height: 36px;
          }
          .label2{
            @extend .label;
            margin-top: 14px;
            margin-bottom: 8px
          }
        }
      }
      .select_layout {
        margin: 0 0 4px 40px;
        flex-wrap: nowrap;
        width: auto;
        .select {
          padding: 12px 0;
          min-width: 80px;
          width: 150px;
        }
      }
      .btn_primary_l {
        margin-top: 60px;
      }
    }
  }
  .contain-temppos {
    position: relative;
    z-index: 1;
    margin: -890px auto 0;
    padding: 0 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    max-width: 1608px;
    height: 890px;
  }
  .popup_wrap {
    // align-items: flex-end;
    [class*='pop'][class*='inner'][class*='m'] {
      width: auto;
      // height: calc((var(--vh, 1vh) * 100) - 56px);
      .complete {
        position: static;
      }
    }
  }
  //   tablet
  @media screen and (max-width: 1024px) {
    background-position: 50% 160px;
    // .reserv_input {
    //   flex-direction: column;
    //   .img_wrap,
    //   .form {
    //     width: 100%;
    //   }
    // }
  }
  @media screen and (max-width: 768px) {
    padding: 0;
    .visual.img_bg {
      transform: scale(1);
      background: none;
      .tlt {
        transform: scale(1, 1) !important;
        margin-bottom: 0;
        color: #000;
        animation-play-state: paused;
      }
      .sub_tlt {
        display: none;
      }
      .inner {
        display: block;
        padding: 40px 24px 0;
        height: auto;
        min-height: 0;
      }
    }
    .contain-reservation {
      width: 100%;
      height: auto;
      padding: 40px 0 0;
      background: #fff;
      border-radius: 0;
      box-shadow: none;
    }
    .reserv_input {
      display: block;
    }
    .reserv_input {
      .form {
        & .form_wrap.line_ty:nth-of-type(3) {
          .input_wrap {
            align-items: flex-start;
            .label, .label2 {
              flex-shrink: 0;
            }
          }
        }
        .form_wrap.line_ty {
          .input_wrap {
            // align-items: flex-start;
            .label, .label2 {
              margin: 0;
              line-height: 30px;
            }
          }
          .select_layout {
            flex-wrap: wrap;
            width: 100%;
            .select {
              width: 100%;
            }
            &::after {
              top: 50px;
            }
          }
        }
      }
    }
    .contain-temppos {
      position: static;
      margin-top: 0;
      padding: 0 24px;
      height: auto;
    }
  }
  .popup_wrap {
    // align-items: flex-end;
    [class*='pop'][class*='inner'][class*='m'] {
      width: auto;
      // height: calc((var(--vh, 1vh) * 100) - 56px);
      height: 690px;
      .complete {
        position: static;
      }
      .pop_header {
      }
      .logo_wrap {
        margin-top: 0;
      }
      // .pop_cont {
      //   &.complete {
      //     img {
      //       margin-top: 60px;
      //       width: calc(100% - 80px);
      //       height: 160px;
      //       object-fit: cover;
      //     }
      //     .tlt {
      //       margin-top: 40px;
      //     }
      //     .conf_footer {
      //       margin-top: 56px;
      //     }
      //   }
      // }
    }
    @media screen and (max-width: 768px) {
      [class*='pop'][class*='inner'][class*='m'] {
        .pop_header {
          height: 90px;
        }
        .logo_wrap {
          margin-top: 75px;
        }
        .pop_cont {
          &.complete {
            img {
              margin-top: 56px;
              width: 100%;
              height: 160px;
              object-fit: cover;
            }
            .tlt {
              margin-top: 24px;
              font-size: 30px;
            }
            .desc {
              margin-top: 24px;
              font-size: 16px;
              line-height: 145%;
            }
            .conf_footer {
              margin-top: 56px;
            }
          }
        }
      }
    }
  }
}

// 시승예약
.drive_reservation {
  @extend .buy_reservation;
  .contain-reservation {
    width: 496px;
    //height: 580px;
    height: auto;
    padding: 32px 40px 40px;
    background: #fff;
    box-shadow: 0px 0px 40px rgba(155, 155, 155, 0.12);
    border-radius: 12px;
    .post_wrap {
      position:fixed;
      z-index: 400;      
      .pop_l_inner{
        box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
        width:100%;
        max-width:700px;
        padding:20px;
        height:auto;
        .pop_header{
          padding:0;
          margin-bottom:20px;
          h2{
            font-size:28px;            
            font-weight:700;            
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      width: 100%;
      height: auto;
      padding: 40px 0 0;
      background: #fff;
      border-radius: 0;
      box-shadow: none;
      .post_wrap {
        top:0;
        bottom:0;
        width:100%;
        height:auto;
        left:0;
        right:0; 
        .pop_header{
          padding:0 24px !important;
          height:56px !important;
          position:relative;
          }
        .pop_header + div {
          height:100vh !important;
          }  
        }        
      }     
  }
  .reserv_input {
    .form {
      .btn_primary_l {
        margin-top: 20px;
      }
    }
  }
  .reserv_input {
    .form {
      & .form_wrap.line_ty:nth-of-type(4) {
        .input_wrap {
          border-bottom:0 !important;
          //align-items: flex-start;
          .label, .label2 {
            flex-shrink: 0;
          }
          .input_txt.input_txt2{
            width:calc(100% - 174px) !important;
          }
          .postBtn {
            flex-shrink: 0;
            height:42px;
            line-height:42px;
            background:#ccc;
            border-radius:3px;
            margin-left:3px;
            text-align:center;
            width:70px;
            font-size:14px;
          }
        }        
      }
    }
  }  
  .reserv_input {
    .form {
      & .form_wrap.line_ty.addInput{
        margin-top:0 !important;
        .input_wrap {
          border-bottom:0;
        }        
      }
    }
  }
}

.estimatebar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: 24px 20px;
  width: 640px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  box-shadow: 0px 0px 20px #dce5f0;
  background-color: $color_white;
  &.footerfix {
    position: absolute;
  }
  .bar_open {
    @include center_left;
    z-index: 100;
    top: -44px;
    width: 124px;
    height: 68px;
    background: url('../../images/ico/btn_fold_close_bts.png') no-repeat center center;
    background-size: 124px;
    transition: background 0.3s ease-in-out;
    &::after {
      @include pseudo;
      @include center_left;
      z-index: 1;
      top: 44px;
      width: 76px;
      height: 14px;
      background-color: $color_white;
    }
    &.active {
      background: url('../../images/ico/btn_fold_open_bts.png') no-repeat center center;
      background-size: 124px;
    }
  }
  .detail {
    overflow: hidden;
    padding: 0 20px;
    width: 100%;
    height: 0;
    transition: height 0.2s ease-in;
    > li {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      padding: 24px 0;
      &:first-child {
        padding-top: 0;
      }
      & + li {
        border-top: 1px solid $color_gray8;
      }
    }
    .de_tlt {
      @include typo_title1;
      width: 100%;
      font-weight: 700;
      color: $color_black;
    }
    .de_name {
      width: calc(40% - 8px);
      color: $color_gray3;
    }
    .de_price {
      @include typo_title1;
      position: relative;
      z-index: 0;
      width: 60%;
      font-weight: 700;
      text-align: right;
      color: $color_black;
      .discount {
        @include typo_body2;
        position: absolute;
        top: -12px;
        right: 0;
        z-index: 1;
        color: $color_primary;
      }
    }
    .info {
      padding: 12px 16px;
      margin-top: 8px;
      background-color: $color_gray10;
      border-radius: 4px;
      > li {
        color: $color_gray5;
      }
    }
    &.active {
      height: 100%;
    }
  }
  .sub_tlt {
    display: inline-block;
    padding-left: 20px;
    width: 170px;
    color: $color_gray5;
  }
  .price {
    @include typo_title1;
    display: inline-block;
    padding-right: 20px;
    width: calc(100% - 190px);
    font-weight: 700;
    text-align: right;
    color: $color_black;
    span {
      @include typo_header3;
      margin-right: 4px;
      line-height: 135%;
    }
    .price_package {
      display: block;
      margin: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      color: #5a74ce;
    }
  }
  .btn_layout {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    width: 100%;
    button {
      position: relative;
      z-index: 0;
      width: calc(50% - 4px);
      i {
        @include center_top;
        left: 24px;
      }
      &:last-child {
        i {
          right: 24px;
          left: auto;
        }
      }
    }
  }
  .discount {
    flex-grow: 1;
    width: 100%;
    text-align: right;
    padding: 0 20px;
    font-size: 16px;
    font-weight: 600;
    line-height: 150%;
    color: #4876ee;
  }

  // tablet
  @media screen and (max-width: 1024px) {
    padding: 24px;
    width: 100%;
    .sub_tlt {
      @include typo_body2;
      padding-left: 0;
    }
    .price {
      @include typo_body1;
      font-weight: 700;
      span {
        font-size: 24px;
      }
      .price_package {
        font-size: 11px;
        font-weight: 400;
        line-height: 140%;
      }
    }
    .btn_layout {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 16px -24px -24px;
      width: calc(100% + 48px);
      button {
        margin: 0;
        width: 100%;
      }
      [class*='btn'] {
        border-radius: 0;
        border-color: $color_gray5;
        text-align: center;
        span {
          font-size: 18px;
          font-weight: 700;
        }
        & + [class*='btn'] {
          margin-left: 0;
        }
      }
    }
  }
}
[data-tablet] {
  display: none;
}
// 견적
.estimate {
  // margin-top: -88px;
  .popup_wrap {
    .pop_l_inner {
      width: auto;
      .pop_cont {
        max-height: calc(100vh - 80px - 58px) !important;
      }
    }
  }

  // content
  .selectestimate {
    height: 840px;
    .tit {
      position: relative;
      z-index: 1;
      margin: 40px 0 60px;
      font-size: 60px;
      font-weight: 700;
      text-align: center;
      line-height: 125%;
    }
    .wrap_contain {
      display: flex;
      justify-content: center;
      margin-bottom: 35px;
      .box_model {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        margin: 0 16px;
        min-width: 436px;
        height: 485px;
        border-radius: 8px;
        &.disable {
          &:hover::after {
            position: absolute;
            top: 0;
            right: 0;
            padding: 13px 23px;
            content: '출시예정';
            display: block;
            width: 35px;
            height: 54px;
            font-weight: 700;
            font-size: 20px;
            line-height: 135%;
            background: #4876ee;
            color: #fff;
            border-radius: 0 8px 0 0;
          }
        }
        &:hover {
          box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.08);
          // .img {
          //   .first-img {
          //     opacity: 0;
          //   }
          //   .hover-img {
          //     opacity: 1;
          //   }
          // }
          // 23.03.08 고객사 요청으로 주석처리
        }
        .img {
          position: relative;
          width: 100%;
          height: 280px;
          z-index: 0;
          img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 436px;
            height: 280px;
            z-index: 1;
            // transition: opacity 0.2s ease-in-out;
            // &.first-img {
            //   opacity: 1;
            //   transition: 0.3s ease-out;
            // }
            // &.hover-img {
            //   opacity: 0;
            //   transition: 0.2s ease-out;
            // }
            // 23.03.08 고객사요청으로 주석처리
          }
        }
        .name {
          font-size: 36px;
          font-weight: 700;
          line-height: 135%;
          text-align: center;
          .price {
            display: block;
            font-size: 20px;
            font-weight: 500;
            line-height: 160%;
            color: #404040;
          }
        }
        .btn_layout {
          display: flex;
          margin-top: auto;
          padding: 0 40px 45px;
          flex-wrap: nowrap;
          [class^='btn'] {
            width: 100%;
          }
        }
      }
    }
    .box_information {
      position: relative;
      z-index: 1;
      padding: 40px 0 80px;
      border-top: 1px solid #dedede;
      .info {
        dt {
          margin: 0 0 6px 0;
          font-size: 20px;
          font-weight: 500;
          line-height: 150%;
          [class*='ico'][class*='24'] {
            margin: 0 8px 4px 0;
          }
        }
        dd {
          margin: 4px 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          color: #727272;
        }
      }
    }
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 460px;
      height: 668px;
      background: #f4f4f4;
    }
  }
  .model_cho {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    .model {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      position: relative;
      margin-left: 40px;
      padding: 20px;
      //width: calc(25% - 30px);
      max-width: 352px;
      height: 506px;
      height: 480px; // 가격 숨김
      border: 1px solid #dedede;
      background: #fff;
      border-radius: 12px;
      overflow: hidden;
      &:hover {
        border-color: #fff;
        background-color: $color_white;
        box-shadow: 0 0 40px rgba($color: $color_black, $alpha: 0.08);
      }
      &:first-child {
        margin-left: 0;
      }
      .img_wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: -20px -20px 0;
        //padding: 20px;
        //width: 100%;
        width: inherit;
        height: auto;
        //background-color: $color_gray10;
        img {
          object-fit: contain;
          height: 100%;
          width: 100%;
        }
      }
      .category {
        @include typo_header4;
        //margin-top: 20px;
        line-height: 145%;
      }
      .tlt {
        //@include typo_title1;
        margin-top: 4px;
        font-size: 16px;
        font-weight: 600;
        line-height: 150%;
        color: $color_black;
        & + .desc {
          margin: 12px 0 0 20px;
        }
      }
      .caption {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: #404040;
        white-space: pre-line;
      }
      .price {
        @include typo_title1;
        display: block;
        position: absolute;
        right: 28px;
        bottom: 75px;
        margin: 0 0 8px;
        //width: 100%;
        font-weight: 700;
        text-align: right;
        color: $color_black;
        span {
          @include typo_header4;
          margin-right: 4px;
          color: $color_black;
        }
      }
      > .btn_txt {
        margin-top: 8px;
        span {
          color: #727272;
        }
      }
      > .btn_primary_l {
        position: absolute;
        left: 20px;
        bottom: 20px;
        padding: 6px 0;
        width: calc(100% - 40px);
        height: 40px;
        font-size: 16px;
        border-radius: 4px;
        //margin-top: 20px;
        //width: 100%;
      }
      &:disabled {
        position: relative;
        z-index: 0;
        &:hover {
          cursor: auto;
        }
        &::before {
          content: '준비중이에요';
          position: absolute;
          top: 40px;
          right: 40px;
          z-index: 1;
          padding: 6px 16px;
          color: $color_white;
          border-radius: 1100px;
          background-color: $color_point5;
        }
        &::after {
          @include pseudo;
          width: 100%;
          height: 100%;
          background-color: $color_white;
          opacity: 0.8;
        }
      }
    }
    & + .info {
      margin-top: 40px;
      .sub {
        color: $color_gray5;
      }
    }
  }
  .color_cho {
    position: relative;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //height: calc(100vh - 88px);
    height: 100vh;
    .car_detail {
      position: relative;
      z-index: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(100% - 640px);
      padding: 128px 40px 40px;
      height: 100%;
      .wrap_img {
        width: 100%;
      }
      .info {
        position: absolute;
        bottom: 40px;
        left: 40px;
        z-index: 1;
        color: $color_gray5;
      }
    }
    .detail_info {
      position: relative;
      top: 0;
      padding: 120px 40px 190px;
      width: 640px;
      height: 100%;
      box-shadow: 0px 0px 20px #dce5f0;
      background-color: $color_white;
      //max-height: calc(100% - 254px);
      overflow: auto;
      &::-webkit-scrollbar {
        width: 20px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: $color_gray16;
        border-radius: 10px;
        background-clip: padding-box;
        border: 8px solid transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      .tlt {
        @include typo_header4;
        line-height: 145%;
      }
      .car_name {
        padding-bottom: 24px;
        border-bottom: 1px solid $color_gray8;
        .sub_tlt {
          @include typo_title1;
          margin-top: 4px;
          margin-bottom: 8px;
          font-weight: 700;
        }
      }
      .color {
        padding-top: 40px;
        padding-bottom: 20px;
        //height: 100%;
        .cho_option {
          display: flex;
          flex-wrap: wrap;
          gap: 1px;
          margin-top: 24px;
          border: 1px solid $color_gray7;
          border-radius: 8px;
          .option {
            width: calc(50% - 1px);
            height: 90px;
            input {
              width: 100%;
              height: 90px;
              overflow: hidden;
              border-radius: 7px;
              border: 5px solid $color_white;
              &[value='WHITE'] {
                background: linear-gradient(280.83deg, #f0f0f0 8.68%, #ffffff 105.15%);
              }
              &[value='rolandsilver'] {
                background: linear-gradient(98.97deg, #e3e3ec 22.54%, #9f9ebe 107.91%);
              }
              &:hover {
                cursor: pointer;
              }
              &:checked {
                position: relative;
                z-index: 0;
                box-shadow: 0 0 0 1px $color_black;
                &::after {
                  @include pseudo;
                  @include center;
                  width: 36px;
                  height: 36px;
                  background: url('../../images/ico/ico_check.png') no-repeat center center;
                  background-size: 36px;
                }
              }
            }
          }
          .name_wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 20px 24px;
            width: 100%;
            border-top: 1px solid $color_gray7;
            .color_name {
              @include typo_title1;
              font-weight: 700;
              color: $color_black;
            }
          }
        }
      }
      .package_cho {
        height: calc(100% - 60px);
        .input_radio_basic {
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: center;
          margin-top: 24px;
          & + .input_radio_basic {
            margin-top: 10px;
          }
          .input_radio {
            @include typo_title1;
            font-weight: 700;
          }
          .info {
            margin-top: 8px;
            width: 100%;
            color: $color_gray5;
          }
        }
        .check_list {
          display: flex;
          flex-direction: column;
          gap: 8px;
          margin-top: 24px;

          .list {
            display: flex;
            align-items: center;
            position: relative;
            //z-index: 0;
            padding: 34px 24px 34px 156px;
            border-radius: 8px;
            input {
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              width: 100%;
              height: 100%;
              border-radius: 8px;
              border: 1px solid $color_gray7;
              &:checked {
                border-color: $color_black;
                &::after {
                  @include pseudo;
                  @include center_top;
                  left: 42px;
                  z-index: 1;
                  width: 36px;
                  height: 36px;
                  background: url('../../images/ico/ico_check_option.png') no-repeat center center;
                  background-size: 36px;
                }
              }
              &:disabled {
                &::after {
                  @include pseudo;
                  width: 100%;
                  height: 100%;
                  top: 0;
                  left: 0;
                  z-index: 10;
                  background-color: rgba($color: $color_gray10, $alpha: 0.7);
                }
              }
              // value값에 따라 css 설정
              &[value='1'] {
                background: url('../../images/img/img_buy_package1.jpg') no-repeat left center;
                background-size: 120px;
              }
              &[value='2'] {
                background: url('../../images/img/img_buy_package2.jpg') no-repeat left center;
                background-size: 120px;
              }
              &[value='3'] {
                background: url('../../images/img/img_buy_package3.jpg') no-repeat left center;
                background-size: 120px;
              }
              &[value='4'] {
                background: url('../../images/img/img_buy_package4.jpg') no-repeat left center;
                background-size: 120px;
              }
              &[value='5'] {
                background: url('../../images/img/img_buy_package5.jpg') no-repeat left center;
                background-size: 120px;
              }
              &[value='6'] {
                background: url('../../images/img/img_buy_package6.jpg') no-repeat left center;
                background-size: 120px;
              }
            }
            .tlt {
              @include typo_title1;
              display: flex;
              flex-wrap: wrap;
              gap: 8px;
              position: relative;
              //z-index: 0;
              font-weight: 700;
            }
            .tooltip {
              position: relative;
              z-index: 2;
              &:hover {
                .tool_desc {
                  display: block;
                  opacity: 1;
                }
              }
              .tool_desc {
                @include typo_body1;
                display: none;
                opacity: 0;
                position: absolute;
                bottom: 32px;
                left: 0;
                z-index: 1;
                padding: 16px 20px;
                min-width: 280px;
                color: $color_white;
                background-color: rgba($color: $color_black, $alpha: 0.9);
                border-radius: 4px;
                transition: opacity 0.2s ease-in-out;
              }
            }
            .price {
              margin-left: auto;
            }
          }
        }
      }
    }
  }
  .complet {
    position: relative;
    z-index: 0;
    .circle {
      &.finish {
        position: relative;
        z-index: 0;
        &::after {
          @include pseudo;
          @include center;
          width: 52px;
          height: 52px;
          background-image: url('../../images/ico/ico_complete.png');
          background-repeat: no-repeat;
          background-position: center center;
          background-size: 22px;
          background-color: #4876ee;
          border-radius: 50%;
        }
      }
    }
    .compl_msg {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 80px;
      .tlt {
        @include typo_header3;
        margin-top: 26px;
        line-height: 135%;
      }
      .sub_tlt {
        @include typo_title1;
        color: $color_black;
        line-height: 160%;
      }
      .CircularProgressbar {
        width: 68px;
        .CircularProgressbar-trail {
          stroke: none;
        }
        .CircularProgressbar-path {
          stroke: $color_gray8;
        }
      }
    }
    .acco_desc {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      gap: 4px;
      padding: 24px;
      background-color: $color_bgray11;
      dt,
      dd {
        width: calc(50% - 2px);
      }
      dt {
        color: $color_gray5;
        &.bold {
          font-weight: 600;
          color: $color_black;
          & + dd {
            @include typo_title1;
            font-weight: 700;
          }
        }
      }
      dd {
        text-align: right;
        &.minus {
          color: #5a74ce;
          span {
            color: #5a74ce;
          }
        }
      }
      & + .acco_desc {
        position: relative;
        z-index: 0;
        &::after {
          @include pseudo;
          position: absolute;
          top: 0;
          left: 24px;
          z-index: 1;
          width: calc(100% - 48px);
          height: 1px;
          background-color: $color_gray8;
        }
      }
    }
    .my_desc {
      width: 54%;
      .info {
        margin-top: 24px;
        dt {
          @include typo_title1;
          margin-bottom: 8px;
          i {
            margin-right: 8px;
          }
        }
        dd {
          margin-top: 4px;
          padding-left: 10px;
          color: $color_gray5;
          text-indent: -10px;
        }
      }
      .cho_check {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        .section {
          width: 100%;
          height: 90px;
          overflow: hidden;
          transition: height 0.2s ease-in, overflow 0.2s ease-in;
          & + .section {
            margin-top: 35px;
          }
          .tlt_wrap {
            display: flex;
            justify-content: space-between;
            padding: 24px 0;
            border-bottom: 1px solid $color_black;
            .tlt {
              @include typo_header4;
              line-height: 145%;
              color: $color_black;
              [class*='btn'] {
                @include typo_body1;
                margin-left: 12px;
                font-weight: 600;
              }
            }
            .price {
              @include typo_title1;
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              font-weight: 700;
              color: $color_black;
              .category {
                @include typo_body1;
                font-weight: 600;
                color: $color_gray5;
                & + .num {
                  margin-left: 12px;
                }
              }
              .num {
                @include typo_header4;
                margin-right: 4px;
                line-height: 145%;
              }
              button {
                margin-left: 20px;
              }
              i {
                transform: rotate(-180deg);
                transition: transform 0.2s ease-in-out;
              }
            }
          }
          .cho_car_wrap {
            text-align: right;
            [class*='btn'] {
              margin-top: 12px;
            }
          }
          .cho_car_list {
            text-align: left;
            li {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              padding: 24px 0;
              border-bottom: 1px solid $color_gray8;
              .img_cont {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 0 20px;
                .img_wrap {
                  overflow: hidden;
                  width: 100px;
                  height: 80px;
                  border-radius: 8px;
                  img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                  }
                }
                .txt_wrap {
                  display: flex;
                  flex-direction: column;
                  .category {
                    color: $color_gray5;
                  }
                  .tlt {
                    @include typo_title1;
                  }
                  .btn_txt {
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 150%;
                    text-align: left;
                    span {
                      color: #727272;
                    }
                  }
                }
              }
              .price_cont {
                margin-top: 20px;
                .discount {
                  font-size: 14px;
                  color: $color_primary;
                  span {
                    color: $color_primary;
                  }
                  & + .price {
                    margin-left: 8px;
                  }
                }
                .price {
                  font-weight: 600;
                  color: $color_black;
                }
              }
            }
          }
          .sec_cont {
            padding: 40px 0;
            text-align: right;
            //border-bottom: 1px solid $color_gray8;
            .form_wrap {
              flex-direction: row;
              justify-content: flex-start;
              gap: 0 25px;
              &.line_ty .input_wrap {
                position: relative;
                z-index: 0;
                width: auto;
                .label, .label2 {
                  width: 80px;
                  text-align: left;
                }
              }
              .select_layout {
                gap: 0 16px;
                width: auto;
              }
              & + .form_wrap {
                margin-top: 18px;
              }
            }
            .info {
              margin-top: 20px;
              text-align: left;
              li {
                color: $color_gray5;
              }
            }
            .btn_layout {
              display: grid;
              grid-template-columns: 1fr 1fr;
            }
            .acco_desc {
              display: none;
              margin-top: 20px;
              text-align: left;
              & + .acco_desc {
                margin-top: 0;
              }
              & + [class*='btn'] {
                display: none;
                margin-top: 12px;
              }
              &.active {
                display: flex;
                & + [class*='btn'] {
                  display: inline-block;
                }
              }
            }
            .affiliate {
              margin-top: 40px;
              text-align: left;
              .tlt {
                @include typo_title1;
                font-weight: 700;
                & + .card_cont {
                  margin-top: 12px;
                }
              }
              .aff_tlt {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 32px;
                padding-bottom: 16px;
                border-bottom: 1px solid #dedede;
                .desc {
                  @include typo_body2;
                  color: $color_gray5;
                  word-break: keep-all;
                }
                & + .tlt {
                  font-size: 16px;
                  line-height: 150%;
                }
              }
              .aff_list {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                gap: 32px 8px;
                margin-top: 12px;
                .tlt {
                  font-size: 16px;
                  line-height: 150%;
                }
                .card_cont {
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  align-items: center;
                  padding: 20px 24px;
                  background-color: #f6f7f9;
                  &.tem {
                    justify-content: center;
                    .name {
                      font-weight: 600;
                      font-size: 16px;
                      line-height: 40px;
                      color: #aeb3b8;
                    }
                  }
                  .name {
                    font-weight: 700;
                    i {
                      margin-right: 12px;
                    }
                  }
                }
              }
            }
          }
          &.active {
            height: 100%;
            .tlt_wrap .price i {
              transform: rotate(0);
            }
          }
        }
      }
    }
    .my_option {
      position: sticky;
      top: 80px;
      right: 0;
      // top: 422px;
      // right: calc(50vw - 764px);
      padding: 24px;
      width: 46%;
      min-width: 600px;
      border-radius: 24px;
      background-color: $color_white;
      box-shadow: 0px 0px 40px rgba($color: $color_black, $alpha: 0.08);
      .option_visual {
        display: flex;
        flex-wrap: wrap;
        gap: 0 24px;
        img {
          width: 252px;
          height: 180px;
          object-fit: contain;
        }
        dl {
          display: flex;
          flex-direction: column;
          width: calc(100% - 276px);
        }
        dt {
          @include typo_title1;
          margin-bottom: 12px;
          font-weight: 700;
          color: $color_black;
          span {
            @include typo_header4;
            display: block;
          }
        }
        dd {
          color: $color_gray5;
        }
      }
      .price_info {
        .tlt_wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px 0;
          .tlt {
            font-weight: 700;
          }
          &.total {
            @include typo_title1;
            font-weight: 700;
            border-bottom: 1px solid $color_black;
            .price {
              color: $color_primary;
              span {
                @include typo_header4;
                line-height: 145%;
                color: $color_primary;
              }
            }
          }
          &.active {
            border-bottom: 0;
          }
        }
        > li {
          &[data-accordion] {
            overflow: hidden;
            height: 64px;
            transition: height 0.2s ease-in-out;
            .tlt {
              i {
                margin-left: 8px;
                transform: rotate(-180deg);
                transition: transform 0.2s ease-in-out;
              }
            }
            .price {
              @include typo_title1;
              font-weight: 700;
            }
            &.active {
              height: 100%;
              .tlt {
                i {
                  transform: rotate(0deg);
                }
              }
            }
          }
          &:nth-child(2) {
            .tlt_wrap {
              border-bottom: 1px solid$color_gray8;
            }
          }
        }
      }
      .btn_layout {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        background: #fff;
        position: sticky;
        bottom: 0;
        margin: 0 -12px;
        padding: 12px;
        [class*='btn'] {
          &:nth-child(1) {
            width: 40%;
            text-align: center;
          }
          &:nth-child(2) {
            width: calc(60% - 8px);
            text-align: center;
          }
        }
      }
      @media screen and (max-width: 768px) {
        min-width: inherit !important;
      }
    }

    .list_option {
      display: flex;
      margin: 0 auto;
      max-width: 848px;
      dt {
        min-width: 80px;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%;
        color: $color_gray5;
      }
      dd {
        margin-left: 20px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: $color_gray5;
        strong {
          color: #000;
          font-weight: 700;
        }
        &:last-child {
          margin-left: auto;
          text-align: right;
        }
      }
      &.head {
        dt {
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          color: #000;
        }
        dd {
          font-size: 16px;
          font-weight: 700;
          line-height: 150%;
          color: #000;
        }
      }
    }
    .summary {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      padding: 0 0 30px 0;
      max-width: 848px;
      border-bottom: 1px solid #dedede;
      .img {
        display: block;
        width: 404px;
        height: auto;
      }
      .total {
        display: flex;
        flex-direction: column;
        padding: 0;
        width: 43%;
        .model {
          margin-bottom: 36px;
          font-size: 16px;
          font-weight: 600;
          line-height: 150%;
          color: #404040;
          strong {
            display: block;
            font-size: 28px;
            font-weight: 700;
            line-height: 145%;
          }
        }
        .price {
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          color: #727272;
          strong {
            display: block;
            font-size: 20px;
            font-weight: 700;
            line-height: 160%;
            color: #000;
          }
        }
      }
    }
    .section_detail1 {
      margin: 12px auto;
      padding: 12px 0 0;
      max-width: 848px;
      .list_option {
        position: relative;
        dt {
          font-size: 16px;
        }
        dd {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 16px;
          white-space: nowrap;
          .condition1 {
            font-size: 14px;
            color: #727272;
            font-weight: 400;
          }
          .condition2 {
            font-size: 14px;
            color: #5a74ce;
            font-weight: 400;
          }
          ul {
            margin: 6px 0;
            li {
              font-size: 14px;
              font-weight: 400;
              line-height: 140%;
              color: #727272;
            }
          }
          &.name {
            position: static;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            color: #000;
          }
        }
      }
    }
    .section_detail2 {
      margin: 12px auto;
      padding: 12px 0 0;
      max-width: 848px;
      border-top: 1px solid #000;
      .list_option {
        margin: 3px 0;
        dd {
          font-size: 16px;
          font-weight: 600;
          color: #000;
          .subsidy {
            color: #5a74ce;
          }
        }
        &.price {
          margin: 0 0 10px 0;
          padding: 5px 0 15px;
          border-bottom: 1px solid #dedede;
          dt {
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            color: #000;
          }
          dd {
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            color: #000;
          }
        }
        &.total {
          margin-top: 10px;
          padding: 20px 0 15px;
          border-top: 1px solid #000;
          border-bottom: 1px solid #dedede;
          dt {
            font-size: 16px;
            font-weight: 700;
            line-height: 160%;
            color: #000;
          }
          dd {
            font-size: 20px;
            font-weight: 700;
            color: #5a74ce;
          }
        }
      }
    }
    .conf_footer {
      display: flex;
      margin: 30px auto 0;
      max-width: 848px;
      [class*='btn'] {
        width: 100%;
        text-align: center;
        &[class*='ghost'] {
          flex-grow: 1;
          flex-shrink: 0;
          width: 240px;
        }
        & + [class*='btn'] {
          margin-left: 8px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .summary {
        position: relative;
        padding: 130px 0 15px 0;
        .img {
          width: 100%;
        }
        .total {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          .model {
            margin-bottom: 20px;
            strong {
            }
          }
          .price {
            display: flex;
            justify-content: space-between;
            line-height: 130%;
            strong {
              line-height: 100%;
            }
          }
        }
      }
      .section_detail1 {
        padding: 5px 0 0 0;
        .list_option {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0 0 15px 0;
          padding: 0 0 15px 0;
          border-bottom: 1px solid #dedede;
          &.package {
            border: none;
            dd {
              position: absolute;
              right: 0;
              top: 70px;
              strong {
                font-size: 14px;
              }
              &.name {
                position: static;
                margin-top: -8px;
                .optionimg {
                  margin-top: 0;
                }
              }
              .condition2 {
                display: block;
                font-size: 12px;
              }
            }
          }
          dt {
            margin-bottom: 5px;
            font-size: 12px;
          }
          dd {
            position: static;
            // margin: -20px 0 0 0;
            margin: 4px 0 0 0;
            font-size: 14px;
            &.name {
              display: flex;
              align-items: flex-start;
              flex-wrap: wrap;
              margin: 0;
              padding: 8px 0 0 0;
              font-size: 16px;
              .img {
                margin: -8px 20px 0 0;
                width: 108px;
              }
              .selectcolorW {
                margin: -8px 20px 0 0;
                width: 108px;
                height: 60px;
                border-radius: 4px;
                overflow: hidden;
                background: url(../../images/img/img_buy_color2.jpg) no-repeat 50%;
              }
              .selectcolorR {
                margin: -8px 20px 0 0;
                width: 108px;
                height: 60px;
                border-radius: 4px;
                overflow: hidden;
                background: url(../../images/img/img_buy_color.jpg) no-repeat 50%;
              }
              .optionimg {
                margin: -8px 20px 0 0;
                width: 108px;
                height: 60px;
                border-radius: 4px;
                overflow: hidden;
                background: url(../../images/img/img_buy_package1.jpg) no-repeat 50%;
                background-size: cover;
              }
              ul {
                margin-top: 15px;
                li {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      .conf_footer {
        gap: 8px;
        [class*='btn'][class*='ghost'] {
          border-radius: 8px;
          width: 50%;
        }
        [class*='btn'] + [class*='btn'] {
          margin-left: 0;
          width: auto;
          border-radius: 8px;
        }
      }
    }
  }
  .my_option_wrap.estimatebar {
    [data-tablet] {
      display: block;
    }
    .my_option {
      position: static;
      padding: 0;
      width: 100%;
      border-radius: 0;
      box-shadow: none;
      &.active {
        & + .sub_tlt {
          display: none;
          & + .price {
            display: none;
          }
        }
        .tlt_wrap {
          .tlt {
            button {
            }
          }
          .price {
          }
        }
      }
    }
    .btn_layout {
      .btn_ghost_l,
      .btn_primary_l {
        padding: 15px 19px;
        height: 56px;
      }
      .btn_ghost_l {
        display: flex;
        flex-wrap: nowrap;
        justify-content: center;
        button {
          width: auto;
        }
      }
    }
  }

  // max-width
  @media screen and (max-width: 1608px) {
    .complet {
      .my_desc {
        .cho_check {
          & .section:last-of-type {
            .select_layout {
              &::after {
                display: none;
              }
            }
          }
          .section {
            .tlt_wrap {
              .tlt {
                display: flex;
                flex-direction: column;
                [class*='btn'] {
                  margin-left: 0;
                }
              }
            }
            .cho_car_list {
              li {
                .img_cont {
                  align-items: flex-start;
                  width: 100%;
                }
                .price_cont {
                  margin-top: -22px;
                  width: 100%;
                  text-align: right;
                }
              }
            }
            .sec_cont {
              .form_wrap {
                flex-direction: column;
                gap: 16px;
                .select_layout {
                  width: 100%;
                  .select {
                    width: 100%;
                  }
                  &::after {
                    @include pseudo;
                    @include center;
                    width: 100%;
                    height: 1px;
                    background-color: $color_gray3;
                  }
                }
                &.line_ty {
                  .input_wrap {
                    position: relative;
                    z-index: 0;
                  }
                }
              }
              .affiliate {
                .aff_tlt {
                  flex-direction: column;
                  align-items: flex-start;
                  gap: 6px;
                }
                .aff_list {
                  grid-template-columns: 1fr;
                  gap: 20px;
                }
              }
              .form_wrap.line_ty {
                .input_wrap {
                  width: 100%;
                  .label, .label2 {
                    width: 100px;
                  }
                  .select {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .my_option {
        // width: 100%;
        .option_visual {
          flex-direction: column;
          gap: 16px 0;
          img {
            order: 2;
            width: 100%;
          }
          dl {
            order: 1;
            width: 100%;
          }
          dt {
            margin-bottom: 4px;
            span {
              margin-bottom: 4px;
            }
          }
        }
        .btn_layout {
          display: grid;
          grid-template-columns: 1fr 1fr;
          [class*='btn'] {
            padding: 16px;
            &:nth-child(1),
            &:nth-child(2) {
              width: auto;
            }
            span {
              font-size: 18px;
              line-height: 135%;
            }
          }
        }
      }
    }
  }

  // tablet
  @media screen and (max-width: 1024px) {
    .estimatebar {
      position: fixed;
    }
    .selectestimate {
      height: auto;
      .wrap_contain {
        display: block;
        .box_model {
          margin: 0 auto 35px;
          overflow: hidden;
          .btn_layout {
            margin-top: 24px;
            padding: 0 0 0;
          }
          &:hover {
            box-shadow: none;
          }
          &.disable {
            border-radius: 0;
            &.disable:hover::after {
              border-radius: 0;
            }
            &::after {
              position: absolute;
              top: 0;
              right: 0;
              padding: 13px 23px;
              content: '출시예정';
              display: block;
              width: 35px;
              height: 54px;
              font-weight: 700;
              font-size: 20px;
              line-height: 135%;
              background: #4876ee;
              color: #fff;
            }
          }
        }
      }
      .box_information {
        padding-bottom: 0;
        border: none;
      }
      &::after {
        content: none;
      }
    }

    .model_cho {
      flex-direction: column;
      gap: 24px 0;
      .model {
        margin-left: 0;
        padding-bottom: 140px;
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }

    .color_cho {
      flex-direction: column;
      padding-bottom: 180px;
      height: 100%;
      .car_detail {
        padding: 78px 24px 0;
        width: 100%;
        //height: 480px;
        .info {
          bottom: 12px;
          left: 24px;
          width: auto;
        }
      }
      .detail_info {
        padding: 40px 24px;
        width: 100%;
        box-shadow: none;
        .color {
          padding-bottom: 40px;
        }
      }
    }

    .complet {
      position: static;
      padding-bottom: 210px;
      .acco_desc {
        padding: 20px;
        dt {
          @include typo_body2;
          line-height: 165%;
        }
        dd {
          @include typo_body2;
          line-height: 165%;
          font-weight: 600;
        }
      }
      .circle.finish {
        &::after {
          width: 30px;
          height: 30px;
          background-size: 12px;
        }
      }
      .compl_msg {
        margin-bottom: 40px;
        .CircularProgressbar {
          width: 46px;
        }
        .tlt {
          margin-top: 18px;
          font-size: 24px;
          line-height: 140%;
        }
        .sub_tlt {
          @include typo_body1;
          margin-top: 4px;
          text-align: center;
        }
      }
      .my_desc {
        width: 100%;
        .cho_check {
          .section {
            position: relative;
            z-index: 0;
            height: 62px;
            & + .section {
              margin-top: 80px;
            }
            .sec_cont {
              padding: 16px 0 40px;
              border-bottom: 0;
              .form_wrap {
                .select_layout {
                  .select {
                    border-bottom: 0;
                  }
                }
              }
              .acco_desc {
                margin-top: 16px;
                & + [class*='btn'] {
                  margin-top: 16px;
                  padding: 12px;
                  width: 100%;
                }
              }
              .info {
                margin-top: 16px;
                li {
                  padding-left: 8px;
                  text-indent: -8px;
                  & + li {
                    margin-top: 6px;
                  }
                }
              }
              .affiliate {
                .tlt {
                  @include typo_body1;
                  font-weight: 700;
                }
                .desc {
                  @include typo_body3;
                  margin-top: 6px;
                }
                .aff_list {
                  margin-top: 16px;
                  gap: 16px;
                  .tlt {
                    & + .card_cont {
                      margin-top: 16px;
                    }
                  }
                  .card_cont {
                    padding: 12px 20px;
                    border-radius: 4px;
                    .name {
                      @include typo_body2;
                      font-weight: 700;
                      line-height: 165%;
                      i {
                        width: 32px;
                        height: 32px;
                        background-size: 32px;
                      }
                    }
                    &.tem {
                      .name {
                        font-size: 14px;
                        line-height: 32px;
                      }
                    }
                  }
                }
              }
            }
            .tlt_wrap {
              padding: 16px 0;
              .tlt {
                font-size: 18px;
                line-height: 135%;
                [class*='btn'] {
                  display: none;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  transition: display 0.3s ease-in-out;
                }
              }
              .price {
                display: block;
                position: relative;
                z-index: 0;
                font-size: 18px;
                line-height: 135%;
                .num {
                  font-size: 18px;
                  line-height: 135%;
                }
                .category {
                  @include typo_body3;
                  position: absolute;
                  top: -14px;
                  right: 38px;
                  z-index: 1;
                }
                button {
                  margin-left: 6px;
                  margin-top: -50%;
                }
              }
            }
            .cho_car_list {
              li {
                padding: 44px 0 22px;
                .img_cont {
                  position: relative;
                  z-index: 0;
                  .txt_wrap {
                    .category {
                      @include typo_body3;
                      position: absolute;
                      top: -22px;
                      left: 0;
                      z-index: 1;
                    }
                    .tlt {
                      @include typo_body1;
                      font-weight: 500;
                    }
                  }
                  .img_wrap {
                    width: 80px;
                    height: 64px;
                  }
                }
                .price_cont {
                  display: flex;
                  flex-direction: column;
                  gap: 4px;
                  margin-top: -42px;
                  .discount {
                    @include typo_body3;
                  }
                }
              }
            }
            .cho_car_wrap {
              [class*='btn'] {
                margin-top: 16px;
                padding: 12px;
                width: 100%;
              }
            }
            &.active {
              .tlt_wrap {
                .tlt {
                  [class*='btn'] {
                    display: block;
                  }
                }
              }
            }
          }
        }
        > .info {
          margin-top: 0;
          padding-top: 16px;
          border-top: 1px solid $color_gray8;
          dt {
            @include typo_body1;
            font-weight: 500;
          }
        }
      }
    }
  }

  // mobile
  @media screen and (max-width: 768px) {
    .estimatebar {
      .detail {
        padding: 0;
        .de_tlt,
        .de_price {
          @include typo_body1;
          font-weight: 700;
        }
        .de_name {
          @include typo_body2;
        }
        .de_price {
          .discount {
            @include typo_body3;
          }
        }
      }
      .discount {
        padding-right: 0;
      }
      .sub_tlt {
        width: 120px;
      }
      .price {
        padding-right: 0;
        width: calc(100% - 130px);
      }
    }

    .selectestimate {
      .tit {
        margin-bottom: 32px;
        font-size: 30px;
        text-align: left;
      }
      .wrap_contain {
        margin-bottom: 0;
        .box_model {
          height: auto;
          min-width: auto;
          .name {
            font-size: 24px;
            line-height: 140%;
            .price {
              font-size: 16px;
              line-height: 150%;
            }
          }
          .btn_layout {
            margin-top: 40px;
          }
          .img {
            width: auto;
            height: 47.222vw;
            img {
              width: 100%;
              height: auto;
            }
          }
          &.disable {
            border-radius: 0;
            &.disable:hover::after {
              padding: 8px 16px;
              width: 32px;
              height: 48px;
              font-size: 18px;
              line-height: 135%;
            }
            &::after {
              padding: 8px 16px;
              width: 32px;
              height: 48px;
              font-size: 18px;
              line-height: 135%;
            }
          }
        }
      }
      .box_information {
        padding-top: 0;
        .info {
          dt {
            font-size: 16px;
            line-height: 150%;
          }
          dd {
            font-size: 11px;
          }
        }
      }
    }

    .model_cho {
      flex-direction: column;
      gap: 24px 0;
      .model {
        overflow: hidden;
        width: 100%;
        border: 1px solid $color_gray8;
        .img_wrap {
          margin: -20px;
          width: calc(100% + 40px);
        }
        .category {
          //margin-top: 40px;
          font-size: 24px;
        }
        .tlt {
          @include typo_body1;
          font-weight: 700;
        }
        > .btn_txt {
          display: flex;
          align-items: center;
          margin-top: 12px;
          span {
            @include typo_body2;
            font-weight: 600;
            line-height: 165%;
          }
        }
        .price {
          font-size: 18px;
          line-height: 135%;
          span {
            font-size: 24px;
            line-height: 140%;
          }
        }
        > .btn_primary_l {
          padding: 12px 22px;
          margin-top: 16px;
          height: 48px;
          border-radius: 6px;
          span {
            @include typo_body1;
            font-weight: 500;
          }
        }
      }
      & + .info {
        margin-top: 24px;
      }
    }

    .color_cho {
      .car_detail {
        padding: 0 24px;
        //height: 240px;
      }
      .detail_info {
        padding: 12px 24px 0;
        .tlt {
          font-size: 18px;
        }
        .car_name {
          .sub_tlt {
            @include typo_body1;
            font-weight: 700;
          }
        }
        .color {
          padding-top: 24px;
          padding-bottom: 0;
          .cho_option {
            border: 0;
            margin-top: 30px;
            .name_wrap {
              padding: 18px 0;
              border-top: 0;
              .color_name {
                @include typo_body1;
                font-weight: 700;
              }
              .color_price {
                @include typo_body2;
              }
            }
            .option {
              input {
                height: 68px;
              }
            }
          }
        }
        .package_cho {
          .input_radio_basic {
            .input_radio {
              @include typo_body1;
              font-weight: 700;
            }
            .info {
              margin-top: 12px;
            }
            .price {
              @include typo_body2;
            }
          }
          .check_list {
            .list {
              height: 80px;
              align-items: baseline;
              position: relative;
              //z-index: 0;
              padding: 16px 20px 16px 118px;
              input {
                // value값에 따라 css 설정
                &[value='1'],
                &[value='2'],
                &[value='3'],
                &[value='4'],
                &[value='5'],
                &[value='6'] {
                  background-size: 98px;
                }
                &:checked {
                  &::after {
                    left: 33px;
                  }
                }
              }
              .tlt {
                @include typo_body1;
                position: static;
                width: 100%;
                font-weight: 700;
              }
              .tooltip {
                .tool_desc {
                  @include typo_body2;
                  @include center_left;
                  line-height: 165%;
                  opacity: 0;
                  top: auto;
                  bottom: 32px;
                  padding: 16px 20px;
                  min-width: 170px;
                }
              }
              .price {
                @include typo_body2;
                text-align: right;
                position: absolute;
                right: 1rem;
                bottom: 1rem;
              }
            }
          }
        }
      }
    }

    .complet {
      .my_option {
        .option_visual {
          dt {
            @include typo_body3;
            line-height: 165%;
            font-weight: 600;
            span {
              font-size: 24px;
            }
          }
          dd {
            @include typo_body3;
            line-height: 165%;
          }
        }
        .price_info {
          .tlt_wrap {
            border-bottom: 0;
            .tlt {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              font-weight: 500;
              white-space: nowrap;
            }
            &.total {
              .tlt {
                @include typo_body1;
                font-weight: 700;
              }
              .price {
                span {
                  font-size: 18px;
                  line-height: 135%;
                }
              }
            }
          }
          .price {
            span {
              font-size: 18px;
              line-height: 135%;
            }
          }
          > li[data-accordion] .price {
            @include typo_body1;
            font-weight: 700;
            width: auto;
          }
        }
      }
      .my_desc .cho_check .section .sec_cont .form_wrap .select_layout {
        width: calc(100% - 140px);
        &::after {
          top: 45px;
          left: -140px;
          width: calc(100% + 140px);
          transform: none;
          // display: none;
        }
      }
    }
  }
}

.checkout-container {
  display: flex;
  justify-content: space-between;
  // todo...
  gap: 4.5%;
}

@media (max-width: 1430px) {
  .estimate .complet {
    .my_desc {
      width: fit-content;
    }
    .my_option {
      width: 100%;
      min-width: 460px;
    }
  }
}

@media screen and (min-width: 769px) and (max-width: 1080px) {
  .estimate {
    .selectestimate {
      .wrap_contain {
        .box_model {
          .img {
            width: auto;
            height: 47.222vw;
            img {
              width: auto;
              height: 100%;
              left: 0;
              right: 0;
              margin: auto;
            }
          }
        }
      }
    }
  }
}

//프로그래스 바
.estimate_prog_header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  padding: 0 40px;
  width: 100%;
  height: 72px;
  background-color: $color_bgray1;
  .tit {
    display: flex;
    align-items: center;
    .tit_home_logo {
      &::before {
        content: '';
        display: block;
        width: 108px;
        height: 32px;
        background: url('../../images/ico/logo_t4k.svg') no-repeat left center;
        background-size: 57px auto;
      }
    }
  }
  .open_choose {
    position: relative;
    margin: 2px auto 0 0;
    text-align: right;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    i {
      margin-left: 8px;
      transition: transform 0.2s ease-in-out;
    }
    &.active {
      i {
        transform: rotate(180deg);
      }
    }
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -20px;
      top: 3px;
      width: 1px;
      height: 16px;
      background: rgba(255, 255, 255, 0.3);
    }
  }
  .state_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 0 166px;
    .item {
      font-size: 18px;
      line-height: 120%;
      font-weight: 500;
      color: $color_gray5;
      span {
        font-size: 18px;
        line-height: 120%;
        font-weight: 500;
        color: $color_gray5;
      }
      & + .item {
        position: relative;
        z-index: 0;
        &::before {
          @include pseudo;
          @include center_top;
          left: -80px;
          width: 6px;
          height: 12px;
          background: url('../../images/ico/ico_progress_arrow.png') no-repeat center center;
          background-size: 6px;
        }
      }
      &.active {
        font-weight: 700;
        color: $color_white;
        span {
          font-weight: 700;
          color: $color_white;
        }
      }
    }
  }
  .link_wrap {
    display: flex;
    [class*='btn'] span {
      font-size: 16px;
      line-height: 135%;
      font-weight: 600;
    }
    [class*='btn'] + [class*='btn'] {
      margin-left: 24px;
    }

    .btn_txt_wh {
      display: flex;
      align-items: center;
    }
  }
  .per {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 25%;
    height: 4px;
    background-color: $color_primary;
    transition: all 0.4s ease;
    &.part1 {
      width: 33.33%;
    }
    &.part2 {
      width: 66.66%;
    }
    &.part3 {
      width: 100%;
    }
    &.part4 {
      width: 100%;
    }
  }
  .choose {
    position: fixed;
    top: 78px;
    left: 20px;
    z-index: 310;
    overflow: hidden;
    height: 0;
    background-color: $color_white;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba($color: $color_black, $alpha: 0.16);
    transition: height 0.2s ease-in-out;
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 14px 24px;
      width: 280px;
      height: 84px;
      opacity: 0.3;
      &.on {
        opacity: 1;
      }
      // & + .item {
      //   border-top: 1px solid $color_gray8;
      // }

      .name {
        font-size: 20px;
        font-weight: 700;
        color: #000;
        line-height: 160%;
        // display: inline-block;
        // width: 65px;
        // height: 21px;
        // background: url('../../images/ico/logo_t4k_01.svg') no-repeat center center;
        // background-size: 65px;
      }
      .img {
        width: 104px;
        height: auto;
      }
    }
    // @for $item from 1 to 4 {
    //   .item:nth-child(#{$item}) {
    //     background: url('../../images/img/img_t4k_0' + $item + '.png')
    //       no-repeat
    //       right
    //       20px
    //       center;
    //     background-size: 60px;
    //     .logo {
    //       display: inline-block;
    //       width: 65px;
    //       height: 21px;
    //       background: url('../../images/ico/logo_t4k_0' + $item + '.svg') no-repeat center center;
    //       background-size: 65px;
    //     }
    //   }
    // }
    &.active {
      height: 168px;
    }
  }
}
@media screen and (max-width: 1024px) {
  .estimate_prog_header {
    padding: 0 24px;
    height: 56px;
    .state_wrap {
      display: none;
      .item {
        @include typo_body1;
        span {
          display: none;
        }
        & + .item {
          &::before {
            left: -22px;
          }
        }
      }
    }
    .link_wrap {
      [class*='btn'] {
        span {
          display: none;
        }
        i {
          margin: 0;
          width: 28px;
          height: 28px;
          background-size: 28px;
        }
      }
    }
    .per {
      height: 5px;
    }
  }
}
//TCO
.tco {
  .visual {
    margin-bottom: 60px;
    // background: url('../../images/img/img_visual_tco.jpg') no-repeat center center;
    // background-size: cover;
    .bg_img {
      background: url(../../images/img/img_visual_tco.jpg) no-repeat 50%;
      background-size: cover;
    }
  }
  @media screen and (max-width: 768px) {
    .visual {
      margin-bottom: 36px;
    }
  }
  .tlt_wrap {
    margin-bottom: 40px;
    text-align: center;
    .tlt {
      @include typo_header4;
      color: $color_black;
      word-break: keep-all;
    }
    .desc {
      @include typo_body1;
      margin-top: 8px;
      word-break: keep-all;
    }
    @media screen and (max-width: 768px) {
      margin-bottom: 24px;
      .tlt {
        font-size: 18px;
        text-align: left;
      }
      .desc {
        font-size: 14px;
        text-align: left;
      }
    }
  }
  .calc_tco {
    .item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      max-width: 848px;
      min-height: 67px;
      border-bottom: 1px solid #d5d8db;
      &:first-child {
        // border-bottom-color: $color_black;
        border-top: 2px solid $color_black;
      }

      &.charge {
        margin-top: 48px;
        border-top: 2px solid $color_black;
      }
      dt {
        @include typo_body1;
        color: $color_gray4;
        font-weight: 600;
        white-space: nowrap;
        margin-right: 40px;
        color: #4f555d;
      }
      dd {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        font-size: 20px;
        line-height: 110%;
        .form_wrap {
          .input_wrap {
            border: none;
            .input_txt {
              height: inherit;
              padding: 0;
              text-align: right;
            }
          }
          .select {
            min-width: 0;
            padding-right: 40px;
            padding-top: 1rem;
            padding-bottom: 1rem;
            &::after {
              // transform: translate(-50%, -50%);
            }
            .input_select {
              text-align: right;
            }
          }
        }
        > span {
          width: 70px;
          text-align: right;
        }
      }
      &.ext1 {
        dd {
          > span {
            margin: 0 0 0 -40px;
          }
        }
      }
    }
    .btn_primary_l {
      display: block;
      margin: 50px auto 0;
      width: 400px;
    }
    @media screen and (max-width: 768px) {
      .item {
        min-height: 48px;
        dt {
          margin-right: 10px;
          width: 110px;
          font-size: 14px;
          font-weight: 600;
        }
        dd {
          .form_wrap {
            justify-content: center;
            .input_wrap {
              .input_txt {
                font-size: 14px;
                &::placeholder {
                  font-size: 14px;
                }
              }
            }
            .input_select {
              font-weight: 600;
              font-size: 14px;
            }
            .select {
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 0 30px 0 0;
              .inputz-select {
                font-weight: 600;
              }
            }
          }
          > span {
            width: 60px;
            font-size: 14px;
          }
        }
        &.ext1 {
          dd {
            > span {
              margin: 0 0 0 -32px;
            }
          }
        }
      }
      .btn_primary_l {
        margin-top: 40px;
        width: 100%;
      }
    }
  }
  .box_links {
    display: flex;
    justify-content: space-between;
    margin: 78px 0 49px;
    & a:first-of-type {
      background: url(../../images/ico/ico_buy_ev1.png) no-repeat;
      background-size: 70px auto;
      background-position: 24px 50%;
    }
    & a:last-of-type {
      background: url(../../images/ico/ico_buy_ev2.png) no-repeat;
      background-size: 70px auto;
      background-position: 24px 50%;
    }
    a {
      display: flex;
      align-items: center;
      padding: 0 24px 0 100px;
      font-size: 20px;
      font-weight: 700;
      color: $color_black;
      width: calc(50% - 20px);
      height: 111px;
      border: 1px solid #d5d8db;
      border-radius: 4px;
      word-break: keep-all;
      .ico_move {
        display: inline-block;
        margin-left: 12px;
        width: 16px;
        height: 20px;
        vertical-align: text-top;
        background-size: contain;
      }
    }
    @media screen and (max-width: 768px) {
      display: block;
      margin: 31px 0;
      & a:first-of-type {
        background-size: 54px auto;
      }
      & a:last-of-type {
        background-size: 54px auto;
      }
      a {
        margin: 16px 0;
        padding-left: 92px;
        width: auto;
        height: 90px;
        font-size: 18px;
        background-size: 54px auto;
      }
    }
  }
  .box_information {
    padding: 40px 0 0 0;
    border-top: 1px solid $color_gray8;
    .info {
      dt {
        margin-bottom: 5px;
        font-size: 20px;
        font-weight: 500;
        line-height: 160%;
        i {
          margin-right: 6px;
        }
      }
      dd {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%;
        color: #727272;
      }
    }
    @media screen and (max-width: 768px) {
      padding: 0;
      border: none;
      .info {
        dt {
          font-size: 16px;
        }
        dd {
          font-size: 12px;
        }
      }
    }
  }
}

//견적서 보기
.reservdoc {
  //margin-top: -120px;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  .page {
    width: 21cm;
    min-height: 29.7cm;
    margin: 0 auto;
    // padding: 1cm 0cm;
    padding: 0;
    background: white;
    box-sizing: border-box;
    .inner {
      padding: 0cm;
      height: 256mm;
      box-sizing: border-box;
      .doc_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 16px;
        margin-top: 0;
        height: auto;
        border-bottom: 1px solid $color_black;
        .tit {
          img.logo {
            display: block;
            width: 74px;
            height: auto;
            background: url('../../images/ico/ico_logo_bk.svg') no-repeat center center;
            background-size: 100% auto;
          }
        }
        .txt_date {
          font-size: 19px;
          font-weight: 400;
          line-height: 160%;
          color: $color_black;
        }
      }
      .doc_content {
        position: relative;
        z-index: 0;
        //min-height: 256mm;
        & + .doc_content {
          margin-top: 18px;
        }
        &::before {
          content: '';
          display: block;
          position: absolute;
          z-index: -99;
          top: 0;
          left: 0;
          width: 195px;
          height: 204px;
          background: url('../../images/ico/bg_print1.png') no-repeat center center;
          background-size: 100% auto;
        }
        &::after {
          content: '';
          display: block;
          position: absolute;
          z-index: -99;
          bottom: 0;
          right: 0;
          width: 336px;
          height: 350px;
          background: url('../../images/ico/bg_print2.png') no-repeat center center;
          background-size: 100% auto;
        }
        .model_img {
          display: block;
          margin: 0 auto !important;
          width: 356px;
          height: auto;
        }
        .list_option {
          display: flex;
          dt {
            min-width: 80px;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            color: #000;
          }
          dd {
            margin-left: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
            color: $color_gray5;
            white-space: nowrap !important;
            strong {
              color: #000;
              font-weight: 700;
            }
            &:last-child {
              margin-left: auto;
              text-align: right;
            }
          }
          &.head {
            dt {
              font-size: 16px;
              font-weight: 600;
              line-height: 150%;
              color: #000;
            }
            dd {
              font-size: 16px;
              font-weight: 700;
              line-height: 150%;
              color: #000;
            }
          }
        }
        .summary {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          .doc_tlt {
            display: block;
            margin: 10px auto 0;
            font-weight: 700;
            font-size: 28px;
            line-height: 120%;
          }
          .img {
            display: block;
            margin: 0 auto;
            width: 480px;
            height: auto;
          }
          .total {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 37px;
            .model {
              text-align: center;
              font-size: 14px;
              font-weight: 400;
              line-height: 140%;
              color: $color_gray3;
              .model_name {
                font-size: 24px;
                font-weight: 400;
                line-height: 160%;
                strong {
                  font-weight: 700;
                  margin-right: 8px;
                }
              }
            }
            .price {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              padding: 10px 0;
              font-size: 18px;
              font-weight: 400;
              line-height: 160%;
              color: $color_black;
              border-top: 1px solid $color_gray7;
              border-bottom: 1px solid $color_gray7;
              strong {
                display: block;
                font-size: 24px;
                font-weight: 700;
                line-height: 120%;
                color: #3b46ae;
              }
            }
          }
        }
        .section_detail1 {
          margin-top: 40px;
          .txt_tit {
            padding: 0 0 8px 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            color: #000;
            border-bottom: 1px solid #000;
            & + .list_option.head {
              margin-top: 0;
              padding-bottom: 12px;
              border: none;
            }
          }
          .list_option_wrap {
            padding: 16px 0;
            .list_option {
              position: relative;
              & + .list_option {
                margin-top: 4px;
              }
              dt {
                width: 120px;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: $color_black;
              }
              dd {
                position: absolute;
                right: 0;
                top: 0;
                font-size: 16px;
                white-space: nowrap;
                .condition1 {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 135%;
                  color: $color_gray5;
                }
                .condition2 {
                  font-size: 15px;
                  font-weight: 400;
                  line-height: 135%;
                  color: #3b46ae;
                }
                strong {
                  margin-left: 16px;
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 160%;
                }
                ul {
                  margin-top: 12px;
                  li {
                    margin-bottom: 4px;
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 150%;
                    color: #727272;
                  }
                }
                &.name {
                  position: static;
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 155%;
                  color: #000;
                }
              }
            }
          }
          .price {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            font-size: 10px;
            font-weight: 400;
            line-height: 155%;
            border-top: 1px solid #bbbbbb;
            border-bottom: 1px solid #bbbbbb;
            dt {
              font-size: 16px;
              color: $color_black;
            }
            dd {
              font-weight: 700;
              font-size: 18px;
              line-height: 160%;
            }
          }
        }
        .section_detail2 {
          margin: 0;
          padding: 0;
          border: 0;
          & + .section_detail3 {
            margin-top: 32px;
          }
          .txt_tit {
            padding: 0 0 8px 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 120%;
            color: #000;
            border-bottom: 1px solid #000;
          }
          .list_option_wrap {
            padding: 16px 0;
            .list_option {
              margin: 0;
              & + .list_option {
                margin-top: 4px;
              }
              dd {
                font-size: 18px;
                font-weight: 500;
                line-height: 160%;
                color: #000;
              }
              &.price {
                margin: 0 0 10px 0;
                padding: 5px 0 10px;
                border-bottom: 1px solid #dedede;
                dt {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 150%;
                  color: #000;
                }
                dd {
                  font-size: 16px;
                  font-weight: 700;
                  line-height: 150%;
                  color: #000;
                }
              }
            }
          }
          .price {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 150%;
            border-top: 1px solid #bbbbbb;
            border-bottom: 1px solid #bbbbbb;
            dt {
              color: $color_black;
            }
            dd {
              font-weight: 700;
              font-size: 18px;
              line-height: 160%;
            }
          }
        }
        .section_detail3 {
          // margin: 12px -1cm 12px -1cm;
          // padding: 20px 1cm 30px;
          // width: calc(100% + 2cm);
          & + .box_information {
            margin-top: 32px;
          }
          .txt_tit {
            padding: 0 0 8px 0;
            font-weight: 700;
            font-size: 24px;
            line-height: 160%;
            color: #000;
            border-bottom: 1px solid #000;
            & + .flex_box {
              padding: 16px 0 12px 0;
            }
          }
          .flex_box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            padding: 8px 0 12px 0;
            border-bottom: 1px solid #dedede;
            .list_option_wrap {
              width: calc(50% - 33px);
            }
            .list_option {
              justify-content: space-between;
              & + .list_option {
                margin-top: 4px;
              }
              &.head {
                margin: 0;
                width: calc(50% - 33px);
                dt {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  color: #000;
                }
                dd {
                  font-weight: 500;
                  font-size: 18px;
                  line-height: 160%;
                  color: #000;
                }
                & + .head {
                  margin-top: 0;
                  padding-bottom: 4px;
                }
              }
              dt {
                width: calc(100% - 110px);
                font-weight: 400;
                font-size: 15px;
                line-height: 150%;
                color: $color_gray5;
              }
              dd {
                margin: 0;
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: $color_black;
              }
            }
          }
        }
        .box_information {
          // padding: 10px 0 30px 0;
          padding-bottom: 71px;
          .info {
            dt {
              font-weight: 500;
              font-size: 19px;
              line-height: 160%;
              i.ico_info {
                display: inline-block;
                margin: 0 8px 0 0;
                width: 24px;
                height: 24px;
                background-size: 100% auto;
              }
              & + dd {
                margin-top: 8px;
              }
            }
            dd {
              font-weight: 400;
              font-size: 14px;
              line-height: 150%;
              color: #727272;
              & + dd {
                margin-top: 4px;
              }
            }
          }
        }
      }
      .doc_footer {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0;
        padding: 0 16px;
        height: 40px;
        &::after {
          content: '';
          position: absolute;
          z-index: -90;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          width: 100%;
          border-top: 40px solid #f4f4f4;
        }
        .foot_list {
          display: flex;
          flex-wrap: nowrap;
          justify-content: flex-start;
          gap: 20px;
          .item {
            position: relative;
            z-index: 10;
            font-weight: 500;
            font-size: 14px;
            line-height: 135%;
            color: #000;
            &:not(:first-child)::before {
              content: '';
              display: inline-block;
              position: absolute;
              left: -10px;
              top: 0;
              width: 1px;
              height: 15px;
              vertical-align: middle;
              background: #bbb;
            }
            .tit {
              margin-right: 12px;
              font-weight: 400;
              font-size: 13px;
              line-height: 135%;
              color: #727272;
            }
          }
        }
        .txt_copy {
          display: inline-block;
          font-weight: 300;
          font-size: 13px;
          line-height: 135%;
          color: #727272;
        }
      }
    }
    @page {
      size: A4 landscape;
      margin: 0;
      /*size: landscape;*/
    }
    @media print {
      .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
      }
    }
  }
}

// 프린트시 헤더/푸터 가리기
@media print {
  header,
  footer {
    display: none;
  }
}

//구매예약
.docreserv {
  .inner {
    max-width: 848px;
  }
  .tit_head {
    margin-bottom: 60px;
    font-size: 20px;
    font-weight: 400;
    line-height: 160%;
    color: #404040;
    text-align: center;
    strong {
      font-size: 36px;
      font-weight: 700;
      line-height: 135%;
      color: #000;
    }
  }
  .section_mychoice {
    margin-bottom: 60px;
    .head_list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 80px;
      font-size: 20px;
      font-weight: 700;
      line-height: 160%;
      color: #000;
      border-top: 1px solid #000;
      border-bottom: 1px solid #dedede;
      i {
        margin-left: 20px;
        -webkit-transform: rotate(-180deg);
        transform: rotate(-180deg);
        transition: -webkit-transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out;
        transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
      }
    }
    .body_list {
      height: 0;
      overflow: hidden;
      .list_option {
        display: flex;
        dt {
          min-width: 80px;
          font-size: 14px;
          font-weight: 400;
          line-height: 150%;
          color: $color_gray5;
        }
        dd {
          margin-left: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          color: $color_gray5;
          strong {
            color: #000;
            font-weight: 700;
          }
          &:last-child {
            margin-left: auto;
            text-align: right;
          }
        }
        &.head {
          dt {
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            color: #000;
          }
          dd {
            font-size: 16px;
            font-weight: 700;
            line-height: 150%;
            color: #000;
          }
        }
      }
      .summary {
        display: flex;
        justify-content: space-between;
        padding: 30px 0;
        .img {
          display: block;
          width: 234px;
          height: auto;
        }
        .total {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 0;
          width: 58%;
          .model {
            font-size: 16px;
            font-weight: 600;
            line-height: 150%;
            color: #404040;
            strong {
              display: block;
              font-size: 28px;
              font-weight: 700;
              line-height: 145%;
            }
          }
          .price {
            font-size: 14px;
            font-weight: 400;
            line-height: 140%;
            color: #727272;
            strong {
              display: block;
              font-size: 20px;
              font-weight: 700;
              line-height: 160%;
              color: #000;
            }
          }
        }
      }
      .section_detail1 {
        margin: 12px 0;
        padding: 12px 0 0;
        .list_option {
          position: relative;
          dt {
            font-size: 16px;
          }
          dd {
            position: absolute;
            right: 0;
            top: 0;
            font-size: 16px;
            white-space: nowrap;
            .condition1 {
              font-size: 14px;
              color: #727272;
              font-weight: 400;
            }
            .condition2 {
              font-size: 14px;
              color: #5a74ce;
              font-weight: 400;
            }
            ul {
              margin: 6px 0;
              li {
                font-size: 14px;
                font-weight: 400;
                line-height: 140%;
                color: #727272;
              }
            }
            &.name {
              position: static;
              font-size: 16px;
              font-weight: 400;
              line-height: 150%;
              color: #000;
            }
          }
        }
      }
      .section_detail2 {
        margin: 12px 0;
        padding: 12px 0 0;
        border-top: 1px solid #000;
        .list_option {
          margin: 3px 0;
          dd {
            font-size: 16px;
            font-weight: 600;
            color: #000;
            .subsidy {
              color: #5a74ce;
            }
          }
          &.price {
            margin: 0 0 10px 0;
            padding: 5px 0 15px;
            border-bottom: 1px solid #dedede;
            dt {
              font-size: 16px;
              font-weight: 700;
              line-height: 150%;
              color: #000;
            }
            dd {
              font-size: 16px;
              font-weight: 700;
              line-height: 150%;
              color: #000;
            }
          }
          &.total {
            margin-top: 10px;
            padding: 20px 0 15px;
            border-top: 1px solid #000;
            border-bottom: 1px solid #dedede;
            dt {
              font-size: 16px;
              font-weight: 700;
              line-height: 160%;
              color: #000;
            }
            dd {
              font-size: 20px;
              font-weight: 700;
              color: #5a74ce;
            }
          }
        }
      }
    }
    &.active {
      .head_list {
        i {
          -webkit-transform: rotate(0);
          transform: rotate(0);
        }
      }
      .body_list {
        height: auto;
      }
    }
  }
  .section_infor {
    .form_wrap.line_ty .input_wrap .label {
      width: 71px !important; // 텍스트 깨짐.. todo 임시
    }
    .tit_user {
      margin-bottom: 20px;
      font-size: 20px;
      font-weight: 700;
      line-height: 160%;
      color: #000;
    }
    .desc {
      border-top: 1px solid #000;
    }
    .input_checkbox_basic {
      margin-top: 24px;
      & + .input_checkbox_basic {
        margin-top: 10px;
      }
      label {
        margin-left: -30px;
        padding-left: 30px;
      }
    }
    .form {
      .conf_cont {
        .desc {
          .form_wrap.line_ty {
            .input_wrap {
              align-items: center;
            }
          }
        }
      }
    }
  }
  .conf_footer {
    display: flex;
    flex-wrap: nowrap;
    flex-grow: 0;
    flex-shrink: 1;
    margin-top: 40px;
    gap: 8px;
    [class*='btn'] {
      width: 100%;
      &[class*='ghost'] {
        flex-grow: 1;
        flex-shrink: 0;
        width: 240px;
      }
      & + [class*='btn'] {
        margin-left: 8px;
      }
    }
    .btn_primary_l {
      margin-top: 0;
    }
  }
  .AlartPopUp_wrap {
    .conf_footer {
      padding: 0 24px 32px;
      .btn_layout {
        display: flex;
        [class^='btn'] {
          flex-grow: 1;
          flex-shrink: 1;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .popup_wrap {
      top: 0;
    }
    .inner {
      padding-top: 40px;
    }
    .tit_head {
      margin-bottom: 40px;
      font-size: 16px;
      strong {
        font-size: 24px;
      }
    }
    .section_mychoice {
      margin-bottom: 30px;
      .head_list {
        position: relative;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        height: 96px;
        font-size: 16px;
        .option_name {
          margin-top: 6px;
          font-size: 18px;
          > button {
            position: absolute;
            right: 0px;
            top: 34%;
          }
        }
      }
      .body_list {
        .section_detail1 {
          .list_option {
            flex-wrap: wrap;
            margin-bottom: 10px;
            dt {
              width: 100%;
              font-size: 14px;
              line-height: 220%;
            }
            dd {
              top: auto;
              bottom: 0;
              line-height: 170%;
              &.name {
                margin: 0;
                font-size: 14px;
              }
              .condition1 {
                display: block;
                font-size: 12px;
                line-height: 180%;
              }
              .condition2 {
                display: block;
                font-size: 12px;
              }
            }
            &.package {
              dd {
                top: 0px;
                bottom: auto;
                font-size: 14px;
                > strong {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
    .section_infor {
      margin-bottom: 16px;
      .tit_user {
        margin-bottom: 14px;
        font-size: 16px;
      }
      .form {
        .conf_cont {
          & .form_wrap.line_ty:nth-of-type(3) {
            .input_wrap {
              align-items: flex-start;
            }
          }
          .form_wrap.line_ty {
            .input_wrap {
              margin-top: 20px;
              .label, .label2 {
                font-size: 14px;
              }
            }
            input {
              font-size: 16px;
              &::placeholder {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .conf_footer {
      [class*='btn'] {
        margin-left: 0;
        border-radius: 8px;
        & + [class*='btn'] {
          margin-left: 0;
        }
      }
      [class*='btn'][class*='ghost'] {
        width: 50%;
      }
    }
  }
}

//구매완료
.section_detail1 {
  // margin: 12px 0;
  // padding: 12px 0 0;
  .list_option {
    position: relative;
    dt {
      font-size: 16px;
    }
    dd {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 16px;
      white-space: nowrap;
      .condition1 {
        font-size: 14px;
        color: #727272;
        font-weight: 400;
      }
      .condition2 {
        font-size: 14px;
        color: #5a74ce;
        font-weight: 400;
      }
      ul {
        margin: 6px 0;
        li {
          font-size: 14px;
          font-weight: 400;
          line-height: 140%;
          color: #727272;
        }
      }
      &.name {
        position: static;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        color: #000;
      }
    }
  }
}
.section_detail2 {
  margin: 12px 0;
  padding: 12px 0 0;
  border-top: 1px solid #000;
  .list_option {
    margin: 3px 0;
    dd {
      font-size: 16px;
      font-weight: 600;
      color: #000;
      .subsidy {
        color: #5a74ce;
      }
    }
    &.price {
      margin: 0 0 10px 0;
      padding: 5px 0 15px;
      border-bottom: 1px solid #dedede;
      dt {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        color: #000;
      }
      dd {
        font-size: 16px;
        font-weight: 700;
        line-height: 150%;
        color: #000;
      }
    }
    &.total {
      margin-top: 10px;
      padding: 20px 0 15px;
      border-top: 1px solid #000;
      border-bottom: 1px solid #dedede;
      dt {
        font-size: 16px;
        font-weight: 700;
        line-height: 160%;
        color: #000;
      }
      dd {
        font-size: 20px;
        font-weight: 700;
        color: #5a74ce;
      }
    }
  }
}

//구매보조금
.subven {
  .visual {
    // background: url('../../images/img/img_visual_subven.jpg') no-repeat center center;
    // background-size: cover;
    .bg_img {
      background: url(../../images/img/img_visual_subven.jpg) no-repeat 50%;
      background-size: cover;
    }
    & ~ .inner {
      margin-top: 68px;
    }
  }
  .inner {
    .cont {
      .tlt_wrap {
        display: block;
        .tlt {
          display: block;
          margin-bottom: 24px;
          @include typo_header2;
          font-size: 36px;
          color: #000;
        }
        .desc {
          @include typo_title1;
          color: #000;
          font-weight: 400;
          word-break: keep-all;
          .btn_wrap {
            display: flex;
            justify-content: space-between;
            gap: 24px;
            margin-top: 30px;
            .btn_text {
              display: inline-block;
              padding: 40px 100px;
              width: 50%;
              @include typo_title1;
              font-weight: 700;
              color: #000;
              border: 1px solid #d5d8db;
              border-radius: 4px;
              background: url(../../images/ico/ico_subven_won.png) no-repeat;
              background-size: 70px auto;
              background-position: 24px 50%;
              .ico_move {
                display: inline-block;
                margin-left: 8px;
                width: 16px;
                height: 20px;
                vertical-align: text-top;
                background-size: contain;
              }
            }
          }
        }
      }
    }
    // 다이어그램
    .contain_subven {
      margin-top: 70px;
      & + .contain_subven {
        margin-top: 80px;
      }
      .tit {
        @include typo_header4;
        line-height: 145%;
        & + .box_diagram,
        & + .table_wrap {
          margin-top: 24px;
        }
      }
      .box_diagram {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: 176px 0;
        padding: 20px;
        border: 1px dashed $color_gray7;
        border-radius: 150px;
        .diagram_item {
          @include typo_title1;
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          gap: 4px 0;
          align-items: center;
          justify-content: center;
          width: 22%;
          min-width: 180px;
          height: 200px;
          position: relative;
          z-index: 0;
          font-weight: 700;
          text-align: center;
          background: #edf0f4;
          border-radius: 150px;
          &:last-child {
            width: 416px;
            background: #4876ee;
            .item_txt {
              color: #fff;
              br {
                display: none;
              }
              strong {
                color: #fff;
              }
              & + p {
                @include typo_body1;
                color: $color_bgray7;
                margin-top: 4px;
              }
            }
          }
          .point_txt {
            color: #4876ee;
            font-weight: 500;
          }
          strong {
            @include typo_header4;
            color: #4876ee;
          }
        }
        .ico_diagram_plus,
        .ico_diagram_equal {
          display: block;
          width: 40px;
          height: 40px;
        }
        .ico_diagram_plus {
          background: url('../../images/ico/ico_diagram_plus.png') no-repeat center center;
          background-size: contain;
        }
        .ico_diagram_equal {
          background: url('../../images/ico/ico_diagram_equal.png') no-repeat center center;
          background-size: contain;
        }
      }
    }
    // 테이블
    .contain_subven {
      .table_wrap {
        table {
          th:not(:last-child),
          td:not(:last-child) {
            border-right: 1px solid $color_gray8;
          }
        }
        .info {
          margin-top: 24px;
          .sub {
            & + .sub {
              margin-top: 4px;
            }
          }
        }
      }
    }
  }

  // tablet
  @media screen and (max-width: 1024px) {
    .inner {
      .cont {
        .tlt_wrap {
          gap: 12px;
          .desc {
            .btn_wrap {
              margin-top: 24px;
            }
          }
        }
      }
      // 다이어그램
      .contain_subven {
        & + .contain_subven {
          margin-top: 60px;
        }
        .box_diagram {
          .diagram_item {
            width: 200px;
            height: 200px;
            line-height: 140%;
            &:last-child {
              width: 240px;
              .item_txt {
                br {
                  display: block;
                }
                strong {
                }
                & + p {
                  @include typo_body1;
                  color: $color_bgray7;
                  margin-top: 4px;
                }
              }
            }
            .point_txt {
              font-weight: 500;
            }
            strong {
              @include typo_header4;
            }
          }
          .ico_diagram_plus,
          .ico_diagram_equal {
            display: block;
            width: 16px;
            height: 16px;
          }
          .ico_diagram_plus {
            background: url('../../images/ico/ico_diagram_plus_mo.png') no-repeat center center;
            background-size: contain;
          }
          .ico_diagram_equal {
            background: url('../../images/ico/ico_diagram_equal_mo.png') no-repeat center center;
            background-size: 16px 6px;
          }
        }
      }
      .contain_subven {
        .table_wrap {
          table {
            td {
              font-size: 14px;
              line-height: 165%;
            }
          }
        }
      }
    }
  }
  // mo
  @media screen and (max-width: 768px) {
    .visual {
      & ~ .inner {
        margin-top: 32px;
      }
      .inner {
        .tlt {
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
        .sub_tlt {
          margin-top: 5px;
        }
      }
    }
    .inner {
      .cont {
        .tlt_wrap {
          .tlt {
            font-weight: 700;
            font-size: 30px;
            line-height: 125%;
          }
          .desc {
            @include typo_body1;
            font-weight: 500;
            .btn_wrap {
              flex-direction: column;
              gap: 16px;
              .btn_text {
                position: relative;
                z-index: 0;
                padding: 25px 34px;
                width: 100%;
                font-weight: 700;
                font-size: 16px;
                line-height: 145%;
                text-indent: 48px;
                background-size: 60px auto;
                .ico_move {
                  position: absolute;
                  z-index: 1;
                  right: 34px;
                  top: 50%;
                  transform: translate(-50%, -50%);
                }
              }
            }
          }
        }
      }
      .contain_subven {
        margin-top: 60px;
        .tit {
          font-weight: 700;
          font-size: 18px;
          line-height: 135%;
          word-break: keep-all;
        }
        .box_diagram {
          flex-wrap: wrap;
          flex-direction: column;
          gap: 34px;
          padding: 20px;
          border: 1px dashed $color_gray7;
          border-radius: 80px;
          .diagram_item {
            &,
            &:last-child {
              width: 100%;
              height: 140px;
              font-size: 16px;
              line-height: 145%;
              .item_txt {
                br {
                  display: none;
                }
              }
            }
          }
        }
      }
      .contain_subven {
        .table_wrap {
          table {
            min-width: 560px;
          }
        }
      }
    }
  }
}

//구매가이드
.buyguide {
  overflow: hidden;
  .visual {
    // background: url(../../images/img/img_visual_buyguide.jpg) no-repeat center center;
    // background-size: cover;
    .bg_img {
      background: url(../../images/img/img_visual_buyguide.jpg) no-repeat 50%;
      background-size: cover;
    }
  }
  .affiliate {
    margin-top: 30px;
    text-align: left;
    .tlt {
      @include typo_title1;
      font-weight: 700;
      & + .card_cont {
        margin-top: 12px;
      }
    }
    .aff_tlt {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .desc {
        @include typo_body2;
        color: $color_gray5;
      }
    }
    .aff_list {
      grid-template-columns: 1fr 1fr 1fr;
      margin-top: 12px;
      @media screen and (max-width: 1024px) {
        grid-template-columns: 1fr;
        gap: 16px;
      }
    }
  }
  .aff_list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px 8px;
    margin-top: 0;
    .card_cont {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 16px 20px;
      background-color: $color_gray10;
      &.tem {
        justify-content: center;
        .name {
          color: #aeb3b8;
          font-size: 16px;
          font-weight: 600;
          line-height: 40px;
        }
      }
      .name {
        font-weight: 700;
        i {
          margin-right: 12px;
        }
      }
    }
  }
  .popup_wrap [class*='pop'][class*='inner'] {
    .pop_header {
      padding-left: 24px;
      padding-bottom: 0;
      padding-right: 24px;
      .tlt {
        margin: 24px 0 0;
        font-size: 28px;
        font-weight: 700;
        line-height: 40px;
      }
    }
    .pop_cont {
      padding-top: 20px;
      padding-left: 26px;
      padding-right: 26px;
      padding-bottom: 40px;
      width: 100%;
      .affiliate {
        margin-top: 0;
      }
      .desc {
        display: block;
        margin-top: 20px;
        font-size: 14px;
        line-height: 140%;
        color: #4f555e;
      }
    }
  }
}

// popup
.popup_wrap[popup-cont='detail'] {
  .info_tlt {
    text-align: center;
    .tlt {
      font-size: 36px;
      font-weight: 700;
      line-height: 135%;
      color: $color_black;
    }

    .price {
      > span {
        color: $color_gray5;
      }
      .won {
        @include typo_header4;
        margin-left: 4px;
        font-weight: 700;
        span {
          @include typo_title1;
          margin-left: 4px;
          font-weight: 700;
        }
      }
    }

    @media screen and (max-width: 768px) {
      text-align: left;
      .tlt {
        font-size: 24px;
      }
    }
  }
  .cont {
    .sub_tlt_wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      padding: 16px 32px;
      margin-top: 40px;
      background: #f6f7f9;
      border-top: 2px solid #222e59;
    }
    .sub_tlt {
      font-weight: 700;
      font-size: 16px;
      line-height: 150%;
      //border-bottom: 1px solid #000;
      & + .list_dl2 {
        //border-top: none;
      }
    }

    .txt_notice {
      // margin: -36px 0 26px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      color: #4876ee;
      text-align: right;
      i {
        width: 16px;
        height: 16px;
        background-size: 16px;
        margin-right: 4px;
      }
    }
    .list_dl2 {
      margin: 40px 0;
      padding: 0 32px;
      //margin-top: 12px;
      //border-top: 1px solid #dedede;
      & + .sub_tlt {
        margin-top: 62px;
        padding-top: 24px;
        border-top: 2px solid #222e59;
      }
      dt {
        margin-bottom: 16px;

        // flex-shrink: 1;
        // flex-grow: 1;
        // &::before {
        //   content: '';
        //   display: inline-block;
        //   margin: 0 8px 2px 0;
        //   width: 4px;
        //   height: 4px;
        //   vertical-align: middle;
        //   background: #5a74ce;
        // }
      }
      dd {
        width: 100%;
        line-height: 189%;
        color: #4f555e;
      }
    }
    .my_list {
      margin-top: 12px;
      counter-reset: numbering;
      list-style-type: none;
      li {
        margin: 0 0 4px 0;
        font-size: 16px;
        font-weight: 400;
        line-height: 150%;
        color: #404040;
        word-break: keep-all;
        // &::before {
        //   content: '';
        //   display: inline-block;
        //   margin: 0 8px 2px 0;
        //   width: 4px;
        //   height: 4px;
        //   vertical-align: middle;
        //   background: #5a74ce;
        // }
        &:before {
          display: inline-block;
          position: relative;
          top: -2px;
          counter-increment: numbering;
          content: counter(numbering);
          margin-right: 10px;
          width: 18px;
          height: 18px;
          font-size: 11px;
          line-height: 18px;
          color: #fff;
          text-align: center;
          border-radius: 100%;
          background: #4876ee;
        }
      }
    }
    @media screen and (max-width: 768px) {
      .list_dl2 {
        margin: 32px 0;
        padding: 0 0;
        dt {
          font-size: 15px;
        }
        dd {
          font-size: 15px;
        }
      }
      .sub_tlt_wrap {
        background: none;
        padding-left: 0;
        padding-right: 0;
        padding-top: 32px;
        padding-bottom: 0;
        .sub_tlt {
          font-size: 18px;
          line-height: 135%;
        }
      }
      .txt_notice {
        margin: 0;
        font-size: 13px;
      }
    }
  }
}

.box_radio_type1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    margin: 8px 0 8px 8px;
    width: 136px;
    height: 44px;
    line-height: 100%;
    font-size: 16px;
    font-weight: 400;
    border: 1px solid #edf0f4;
    border-radius: 22px;
    background: #edf0f4;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    &.checked {
      color: #fff;
      background-color: #4876ee;
      border-color: #4876ee;
    }
  }
  input {
    position: absolute;
    top: -100px;
    left: 0;
  }
}
@media screen and (max-width: 768px) {
  .box_radio_type1 {
    label {
      &:first-child {
      }
      &.checked {
      }
    }
    input {
    }
  }
}

// Buyguide
.buyguide {
  .tab-head {
    margin-bottom: 0;
    @media screen and (max-width: 768px) {
      margin-top: 32px;
    }
  }
  .visual {
    background: url('../../images/img/img_visual_buyguide.jpg') no-repeat center center;
    background-size: cover;
    & ~ .inner {
      @media screen and (max-width: 768px) {
      }
    }
  }
  // 1. 구매절차 및 보조금
  .buyguide-information {
    margin-top: 68px;
    & .cont_information:first-of-type {
      margin-top: 80px;
      .scroll_wrap {
        .scroll_cont1 {
          .item {
            .item_img {
              width: 328px;
              height: 164px;
            }
            .step1 {
              background: url('../../images/img/img_buyguide_info1.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step2 {
              background: url('../../images/img/img_buyguide_info2.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step3 {
              background: url('../../images/img/img_buyguide_info3.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step4 {
              background: url('../../images/img/img_buyguide_info4.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step5 {
              background: url('../../images/img/img_buyguide_info5.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step6 {
              background: url('../../images/img/img_buyguide_info6.jpg') no-repeat center center;
              background-size: 328px auto;
            }
          }
        }
      }
    }
    & .cont_information:nth-of-type(2) {
      padding: 120px 0;
      & .cont_box:last-of-type {
        padding-top: 80px;
        border-top: 1px solid #d5d8db;
      }
    }
    & > .tlt {
      & + .desc {
        margin-top: 24px;
        font-weight: 500;
        font-size: 20px;
        line-height: 145%;
        color: #4f555d;
        word-break: keep-all;
      }
      @include typo_header3;
      line-height: 135%;
      color: $color_black;
    }
    .cont_information {
      position: relative;
      margin-top: 120px;
      .tlt_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .tit {
          font-weight: 700;
          font-size: 28px;
          line-height: 145%;
        }
        .btn_wrap {
          .btn_box {
            & + .btn_box {
              margin-left: 8px;
            }
            display: inline-block;
            width: 40px;
            height: 40px;
            border: 1px solid #4f555d;
          }
          .btn_box.prev {
            background: url('../../../assets/images/ico/ico_btnbox_prev.png') no-repeat center
              center;
            background-size: 10px auto;
          }
          .btn_box.next {
            background: url('../../../assets/images/ico/ico_btnbox_next.png') no-repeat center
              center;
            background-size: 10px auto;
          }
        }
      }
      .tlt {
        display: block;
        & + .tit {
          display: block;
          margin-top: 80px;
        }
        & + .desc {
          margin-top: 24px;
          font-weight: 400;
          font-size: 16px;
          line-height: 150%;
        }
        font-weight: 700;
        font-size: 36px;
        line-height: 135%;
        color: #111;
      }
      .desc {
        font-weight: 500;
        font-size: 20px;
        line-height: 145%;
        color: #4f555d;
      }
      .tit {
        font-weight: 700;
        font-size: 28px;
        line-height: 145%;
      }
      .info {
        margin-top: 56px;
        .sub {
          color: #4f555d;
        }
      }
      .cont_box {
        margin-top: 80px;
        .desc {
          margin-top: 8px;
          font-size: 16px;
          line-height: 150%;
          color: #4f555d;
        }
        .card_layout {
          .card_cont3 {
            &:nth-child(1) {
              background: #fff url('../../../assets/images/ico/ico_buy_guide7.png') no-repeat bottom
                62px right 35px;
              background-size: 42px auto;
            }
            &:nth-child(2) {
              background: #fff url('../../../assets/images/ico/ico_buy_guide8.png') no-repeat bottom
                52px right 35px;
              background-size: 48px auto;
            }
            &:nth-child(3) {
              background: #fff url('../../../assets/images/ico/ico_buy_guide9.png') no-repeat bottom
                62px right 35px;
              background-size: 48px auto;
            }
            @media screen and (max-width: 768px) {
              &:nth-child(1),
              &:nth-child(2),
              &:nth-child(3) {
                background-position: bottom 60px right 24px;
                background-size: 40px;
              }
            }
          }
        }
      }
    }
    .card_wrap {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 24px;
      margin-top: 40px;
      .card_item {
        display: flex;
        align-items: center;
        padding: 40px 24px;
        width: calc(50% - 12px);
        text-align: left;
        border: 1px solid #d5d8db;
        border-radius: 4px;
        &:first-child {
          justify-content: flex-start;
          background: #fff url('../../../assets/images/ico/ico_subven_won.png') no-repeat center
            left 24px;
          background-size: 70px;
        }
        &:last-child {
          justify-content: space-between;
          background: #fff url('../../../assets/images/ico/ico_subven_call.png') no-repeat center
            left 24px;
          background-size: 70px;
        }
        span {
          padding-left: 78px;
          font-weight: 700;
          font-size: 20px;
          line-height: 160%;
        }
        i {
          margin-left: 8px;
          background-size: 14px auto;
          vertical-align: sub;
        }
      }
    }

    // tb
    @media screen and (max-width: 1024px) {
      .card_wrap {
        flex-direction: column;
        gap: 16px;
        .card_item {
          width: 100%;
        }
      }
    }

    // mobile
    @media screen and (max-width: 768px) {
      margin-top: 32px;
      & > .tlt {
        font-size: 24px;
        line-height: 140%;
        & + .desc {
          margin-top: 12px;
          font-weight: 600;
          font-size: 14px;
          line-height: 165%;
        }
      }
      & .cont_information:nth-of-type(2) {
        & .cont_box:last-of-type {
          padding-top: 40px;
        }
      }
      .cont_information {
        &:first-of-type {
          margin-top: 40px;
          .scroll_wrap {
            .scroll_cont1 {
              .item {
                .item_img {
                  width: 100%;
                  height: 140px;
                  background-size: 100%;
                }
              }
            }
          }
        }
        &:nth-of-type(2) {
          padding: 60px 0 48px;
        }
        margin-top: 60px;
        .tlt_wrap {
          display: block;
          .tit {
            font-size: 20px;
            line-height: 135%;
          }
        }
        .info {
          margin-top: 24px;
          .sub {
            font-size: 12px;
            line-height: 140%;
          }
        }
        .tlt {
          font-size: 24px;
          line-height: 140%;
          & + .desc {
            font-weight: 600;
            font-size: 14px;
            line-height: 165%;
          }
        }
        .cont_box {
          margin-top: 40px;
          .tit {
            font-size: 20px !important;
            line-height: 135%;
            & + .desc {
              font-weight: 400;
              font-size: 14px;
              line-height: 165%;
            }
          }
          .box_diagram {
            & + .info {
              .sub {
                color: #727272;
              }
            }
          }
          .card_wrap {
            .card_item {
              justify-content: space-between;
              width: 100%;
              height: 80px;
              padding: 20px;
              &:first-child,
              &:last-child {
                background-position: center left 16px;
                background-size: 40px;
              }
              span {
                padding-left: 45px;
                font-weight: 600;
                font-size: 14px;
                line-height: 165%;
                .ico_24_move {
                  text-align: right;
                }
              }
            }
          }
        }
      }
    }
  }
  // 2.차량등록
  .buyguide-regist {
    margin-top: 68px;
    & > .tlt {
      @include typo_header3;
      line-height: 135%;
      color: $color_black;
    }
    .scroll_wrap {
      padding-bottom: 18px;
    }
    .swiper {
      overflow: visible;
    }
    .list_stage {
      margin-top: 24px;
      .item_stage {
        margin-right: 24px;
        .item_img {
          background-size: 100% auto;

          background-repeat: no-repeat;
          background-position: center;
        }
        .step1 {
          background-image: url('../../images/img/img_buyguide_regist1.jpg');
        }
        .step2 {
          background-image: url('../../images/img/img_buyguide_regist2.jpg');
        }
        .step3 {
          background-image: url('../../images/img/img_buyguide_regist3.jpg');
        }
        .step4 {
          background-image: url('../../images/img/img_buyguide_regist4.jpg');
        }
        .step5 {
          background-image: url('../../images/img/img_buyguide_regist5.jpg');
        }
      }
    }

    .cont_regist {
      margin-top: 85px;
      .tit {
        @include typo_header4;
        line-height: 145%;
      }
      .table_wrap {
        margin-top: 24px;
        th + th,
        td + td {
          border-left: 1px solid #dedede;
        }
        th {
          @include typo_body1;
          font-weight: 600;
          color: #000;
        }
        td {
          line-height: 165%;
          color: #4f555d;
        }
      }
    }

    // tb
    @media screen and (max-width: 1024px) {
      .scroll_wrap {
        padding-bottom: 0;
        .list_stage {
          .item_stage {
            .item_img {
            }
          }
        }
      }
    }

    // mobile
    @media screen and (max-width: 768px) {
      margin-top: 32px;
      & > .tlt {
        font-weight: 700;
        font-size: 24px;
        line-height: 140%;
      }
      .list_stage {
        .item_stage {
          .item_img {
          }
        }
      }
      .cont_regist {
        margin-top: 40px;
        .tit {
          font-weight: 700;
          font-size: 18px;
          line-height: 135%;
        }
        .table_wrap {
          th {
            font-size: 14px;
            line-height: 165%;
            font-weight: 600;
            color: #000;
            white-space: nowrap;
          }
          td {
            font-size: 14px;
            color: #4f555d;
            white-space: nowrap;
          }
        }
      }
    }
  }

  //3. 세제 혜택
  .buyguide-benefits {
    & > .cont_box {
      .tlt {
        @include typo_header3;
        line-height: 135%;
        color: $color_black;
      }
      padding: 68px 0 80px;
    }
    .card_layout {
      .card_cont3 {
        &:nth-child(1) {
          background: #fff url('../../../assets/images/ico/ico_buy_guide1.png') no-repeat bottom
            45px right 35px;
          background-size: 80px;
        }
        &:nth-child(2) {
          background: #fff url('../../../assets/images/ico/ico_buy_guide2.png') no-repeat bottom
            45px right 35px;
          background-size: 80px;
        }
        &:nth-child(3) {
          background: #fff url('../../../assets/images/ico/ico_buy_guide3.png') no-repeat bottom
            45px right 35px;
          background-size: 80px;
        }
        &:nth-child(4) {
          background: #fff url('../../../assets/images/ico/ico_buy_guide4.png') no-repeat bottom
            45px right 35px;
          background-size: 80px;
        }
        &:nth-child(5) {
          background: #fff url('../../../assets/images/ico/ico_buy_guide5.png') no-repeat bottom
            45px right 35px;
          background-size: 80px;
        }
        &:nth-child(6) {
          background: #fff url('../../../assets/images/ico/ico_buy_guide6.png') no-repeat bottom
            45px right 35px;
          background-size: 80px;
        }
      }
    }
    .info {
      margin-top: 56px;
      .sub {
        font-size: 16px;
        line-height: 150%;
        color: #727272;
      }
    }
    .cont_benefits {
      margin-top: 120px;
      .tlt {
        @include typo_header3;
        line-height: 135%;
        color: $color_black;
      }
      .cont_box {
        margin-top: 80px;
        .sub_tlt {
          @include typo_header4;
          line-height: 145%;
        }
        .sub {
          margin-top: 24px;
          font-size: 14px;
          line-height: 140%;
          color: #727272;
        }
        dl {
          margin-top: 24px;
          dt {
            & + dd {
              margin-top: 16px;
            }
            font-weight: 700;
            font-size: 20px;
            line-height: 160%;
          }
          dd {
            & + dt {
              margin-top: 40px;
            }
            font-size: 16px;
            line-height: 150%;
            color: #4f555d;
          }
        }
      }
      .tit {
        br {
          display: none;
        }
        & + .desc {
          margin-top: 24px;
        }
        @include typo_header3;
        line-height: 135%;
        color: $color_black;
      }
      .desc {
        font-weight: 700;
        font-size: 20px;
        line-height: 160%;
      }
      .table_wrap {
        & + p {
          margin-top: 40px;
        }
        & + .info {
          margin-top: 46px;
        }
        margin-top: 24px;
        th + th,
        td + td {
          border-left: 1px solid #dedede;
        }
      }
    }
    @media screen and (max-width: 768px) {
      & > .cont_box {
        padding: 32px 0 60px;
        & > .tlt {
          font-size: 24px;
          line-height: 140%;
        }
      }
      .card_layout {
        & + .info {
          margin-top: 24px;
        }
        .card_cont3 {
          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(5),
          &:nth-child(6) {
            background-size: 60px;
          }
        }
      }
      .info {
        .sub {
          font-size: 12px;
          line-height: 140%;
        }
      }
      .cont_benefits {
        margin-top: 60px;
        .tlt {
          font-size: 24px;
          line-height: 140%;
        }
        .cont_box {
          & + .cont_box {
            margin-top: 52px;
          }
          margin-top: 32px;
          .sub_tlt {
            font-size: 18px;
            line-height: 135%;
          }
          .desc {
            & + .table_wrap {
              margin-top: 16px;
            }
            margin-top: 24px;
            font-size: 18px;
            line-height: 135%;
          }
          .sub {
            font-size: 12px;
            line-height: 140%;
          }
        }
        .tit {
          br {
            display: block;
          }
          font-size: 24px;
          line-height: 140%;
          // & + .desc {
          //   margin-top: 32px;
          //   font-size: 16px;
          //   line-height: 145%;
          // }
        }
        .desc {
          margin-top: 32px;
          font-size: 16px;
          line-height: 145%;
        }
        .table_wrap {
          margin-top: 16px;
          &.th_lf {
            th {
              width: 40%;
              text-align: center;
            }
          }
          .table_scroll_wrap {
            .txt_center {
              min-width: 100%;
              & + p {
                margin-top: 32px;
              }
              & + .info {
                margin-top: 24px;
              }
              td {
                font-size: 14px;
                line-height: 165%;
                padding: 12px 10px;
              }
            }
          }
        }
        .desc_box {
          margin-top: 16px;
          height: 140px;
        }
      }
    }
  }
  //4. 공채
  .buyguide-fund {
    margin-top: 68px;
    & > .tlt {
      font-weight: 700;
      font-size: 36px;
      line-height: 135%;
    }
    .cont_box {
      margin-top: 80px;
      .sub_tlt {
        font-weight: 700;
        font-size: 28px;
        line-height: 145%;
      }
      .desc_list,
      .sub {
        margin-top: 16px;
      }
      .desc_list {
        .list_item {
          & + .list_item {
            margin-top: 4px;
          }
          &::before {
            content: '';
            display: inline-block;
            margin-right: 8px;
            width: 3px;
            height: 3px;
            vertical-align: middle;
            background: #4f555d;
            border-radius: 100px;
          }
          font-size: 16px;
          line-height: 150%;
          color: #4f555d;
        }
      }
      .sub {
        font-size: 16px;
        line-height: 150%;
        color: #4f555d;
      }
      .tlt_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: end;
        .sub {
          font-size: 14px;
          line-height: 140%;
          color: #9b9b9b;
          margin-top: 0;
        }
      }
      .table_wrap {
        margin-top: 24px;
        th {
          font-weight: 600;
        }
        th:not(:last-child),
        td:not(:last-child) {
          border-right: 1px solid #dedede;
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 32px;
      & > .tlt {
        font-size: 28px;
        line-height: 145%;
      }
      .cont_box {
        & + .cont_box {
          margin-top: 40px;
        }
        margin-top: 32px;
        .sub_tlt {
          font-size: 20px;
          line-height: 160%;
        }
        .desc_list {
          .list_item {
            font-size: 14px;
            line-height: 165%;
          }
        }
        .sub {
          font-size: 14px;
          line-height: 165%;
        }
        .table_wrap {
          margin-top: 16px;
          thead {
            th {
              font-size: 14px;
              line-height: 140%;
              -webkit-text-size-adjust: none;
              // ios에서만폰트사이즈 적용안됨 이슈
            }
          }
          td {
            font-size: 14px;
            line-height: 140%;
            word-break: keep-all;
            -webkit-text-size-adjust: none;
            // ios에서만폰트사이즈 적용안됨 이슈
          }
        }
      }
    }
  }
  //5. 충전카드
  .buyguide-charge {
    margin-top: 68px;
    & .cont_box:last-of-type {
      padding: 81px 0 74px;
    }
    & > .tlt {
      font-weight: 700;
      font-size: 36px;
      line-height: 135%;
    }
    .desc {
      margin-top: 24px;
      font-weight: 500;
      font-size: 20px;
      line-height: 160%;
      color: #4f555d;
    }
    .cont_box {
      margin-top: 80px;
      .sub_tlt {
        font-weight: 700;
        font-size: 28px;
        line-height: 145%;
      }
      .tlt_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .btn_txt {
          span {
            color: #fff;
          }
        }
      }
      .list_stage {
        justify-content: center;
        .item_stage {
          .tlt {
          }
          .item_img {
            width: 286px;
            height: 164px;
          }
          .step1 {
            background: url('../../images/img/img_buyguide_charge1.jpg') no-repeat center center;
            background-size: 286px auto;
          }
          .step2 {
            background: url('../../images/img/img_buyguide_charge2.jpg') no-repeat center center;
            background-size: 286px auto;
          }
          .step3 {
            background: url('../../images/img/img_buyguide_charge3.jpg') no-repeat center center;
            background-size: 286px auto;
          }
        }
      }
    }
    .bg_navy {
      & + .tlt_wrap {
        .sub_tlt {
          color: #fff;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      .cont_box {
        .list_stage {
          justify-content: flex-start;
          .item_stage {
            .item_img {
              width: 100%;
              height: 164px;
              background-size: 100%;
            }
          }
        }
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 32px;
      & .cont_box:first-of-type {
        .list_stage {
          .item_stage {
            .item_img {
              width: 100%;
              height: 140px;
            }
            .tlt {
              min-height: 39px;
            }
          }
        }
      }
      & .cont_box:last-of-type {
        padding: 40px 0;
      }
      & > .tlt {
        font-size: 24px;
        line-height: 140%;
      }
      .desc {
        margin-top: 15px;
        font-weight: 600;
        font-size: 14px;
        line-height: 165%;
      }
      .cont_box {
        margin-top: 30px;
        .sub_tlt {
          font-size: 20px;
          line-height: 135%;
        }
      }
    }
  }

  // ==============================================

  // 구매가이드 공통시작
  //diagram
  .box_diagram {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    margin-top: 24px;
    text-align: center;
    .diagram_item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 200px;
      background: #222e59;
      word-break: keep-all;
      border-radius: 100px;
      .num {
        display: inline-block;
        margin-bottom: 4px;
        @include typo_title1;
        font-weight: 700;
        color: #d2ecff;
      }
      .tlt {
        max-width: 136px;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        color: #fff;
        .sub {
          display: block;
          font-weight: 600;
          font-size: 16px;
          line-height: 130%;
          color: #fff;
          margin-top: 0;
        }
      }
    }

    // mobile
    @media screen and (max-width: 768px) {
      display: flex;
      flex-direction: column;
      gap: 38px;
      margin-top: 16px;
      .diagram_item {
        &:not(:last-child)::before {
          content: '';
          position: absolute;
          display: block;
          width: 12px;
          height: 6px;
          z-index: 1;
          bottom: -20px;
          background: url('../../images/ico/ico_diagram_arrow.png') no-repeat center;
          background-size: 12px auto;
        }
        position: relative;
        z-index: 0;
        flex-direction: row;
        gap: 8px;
        width: 100%;
        height: 56px;
        .num {
          margin-bottom: 0;
          font-weight: 600;
          font-size: 14px;
          line-height: 165%;
        }
        .tlt {
          font-weight: 600;
          font-size: 14px;
          line-height: 130%;
          white-space: nowrap;
          br {
            display: none;
          }
          .sub {
            display: inline-block;
            font-weight: 400;
            font-size: 12px;
            line-height: 165%;
          }
        }
      }
    }
  }

  // infobox
  .box_information {
    margin-top: 56px;
    .info {
      dt {
        & + dd {
          margin-top: 8px;
        }
        @include typo_title1;
        i {
          margin-right: 11px;
        }
      }
      dd {
        & + dd {
          margin-top: 4px;
        }
        @include typo_body2;
        color: #727272;
      }
    }
    @media screen and (max-width: 768px) {
      margin-top: 40px;
      .info {
        dt {
          font-size: 16px;
          line-height: 150%;
        }
        dd {
          font-size: 12px;
          color: #4f555e;
        }
      }
    }
  }

  // card_layouts
  .card_layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    margin-top: 40px;
    .card_cont3 {
      align-items: center;
      min-height: 170px;
      background: #fff;
      .cont_l {
        .tlt {
          @include typo_title1;
          font-weight: 700;
        }
        .desc {
          @include typo_body1;
          margin-top: 16px;
          color: #4f555d;
          word-break: keep-all;
        }
        .sub {
          margin-top: 4px;
          font-size: 12px;
          line-height: 130%;
          color: #9b9b9b;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
    }
    @media screen and (max-width: 768px) {
      display: flex;
      justify-content: center;
      margin-top: 24px;
      .card_cont3 {
        padding: 24px;
        height: 148px;
        .cont_l {
          .tlt {
            font-size: 16px;
            line-height: 145%;
          }
          .desc {
            margin-top: 8px;
            font-size: 14px;
            line-height: 150%;
          }
          .sub {
            font-size: 11px;
            line-height: 130%;
          }
        }
      }
    }
  }

  // 이미지 리스트
  .list_stage {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 24px;
    // margin-top: 80px;
    .item_stage {
      // flex-shrink: 0;
      width: 286px;
      // margin-right: 24px;
      .item_img {
        height: 164px;
        border-radius: 4px;
      }
      .tlt {
        margin-top: 24px;
        display: block;
        min-height: 80px;
        font-weight: 700;
        font-size: 22px;
        line-height: 160%;
        text-align: center;
        border-bottom: 1px solid #dbdbdb;
        span {
          display: block;
          font-weight: 600;
          font-size: 16px;
          line-height: 140%;
        }
      }
    }
    // tb
    @media screen and (max-width: 1024px) {
      .item_stage {
        width: calc(50% - 12px);
        .item_img {
        }
      }
    }

    @media screen and (max-width: 768px) {
      flex-direction: column;
      margin-top: 16px;
      .item_stage {
        width: 100%;
        .item_img {
          height: 140px;
        }
        .tlt {
          min-height: 39px;
          font-size: 16px;
          line-height: 145%;
          border: 0;
        }
      }
    }
  }

  // 스크롤컨텐츠
  .scroll_wrap {
    margin-top: 24px;

    .swiper {
      padding-right: 27%;
      overflow: visible;
    }

    .swiper-slide {
      width: 328px;
      width: fit-content;
    }
    .scroll_cont1 {
      gap: 24px;
      overflow: visible;
      .swiper-wrapper {
        max-width: 1528px;
        margin: 0px auto;
        gap: 24px;
      }
      .item {
        width: 328px;
        .item_img {
          display: block;
          width: 100%;
          height: 164px;
          background-size: contain;
          border-radius: 4px;
        }
        .tex_wrap {
          position: relative;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          min-height: 200px;
          border-bottom: 1px solid #d5d8db;
        }
        .tlt {
          & + .desc {
            margin-top: 0;
          }
          font-weight: 700;
          font-size: 22px;
          line-height: 160%;
          color: #111;
          span {
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 160%;
          }
        }
        .desc {
          font-size: 16px;
          line-height: 150%;
          color: #4f555d;
          br {
            display: none;
          }
        }
        .btn_txt {
          position: absolute;
          bottom: 32px;
          left: 0;
          z-index: 1;
          width: 100%;
          text-align: left;
          span {
            vertical-align: middle;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
            color: #4876ee;
          }
          i {
            margin-left: 8px;
            vertical-align: middle;
          }
        }
      }
    }

    @media screen and (max-width: 1024px) {
      // width: calc(100% + ((100vw - 100%) / 2));
      &.pc_view {
        display: block !important;
      }
    }

    @media screen and (max-width: 768px) {
      &.pc_view {
        display: none !important;
      }
      width: 100%;
      .scroll_cont1 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 16px;
        & .item:last-of-type {
          padding-bottom: 24px;
          border-bottom: 1px solid #dedede;
        }
        .item {
          width: 100%;
          img {
            width: 100%;
            max-height: 140px;
            object-fit: cover;
          }
          .tex_wrap {
            min-height: 133px;
            border-bottom: 0;
          }
          .tlt {
            font-size: 16px;
            line-height: 145%;
          }
          .desc {
            margin-top: 0;
            font-size: 14px;
            line-height: 165%;
            br {
              display: block;
            }
          }
          .btn_txt {
            bottom: 8px;
            span {
              font-size: 14px;
              line-height: 165%;
            }
            i {
              vertical-align: top;
            }
          }
        }
      }
    }
  }

  .scroll_wrap {
    .scroll_cont2 {
      // display: flex;
      // flex-wrap: nowrap;
      // justify-content: flex-start;
      // margin-top: 24px;
      padding-bottom: 45px;
      // overflow: revert;
      .swiper-wrapper {
        max-width: 1528px;
        margin: 0px auto;
        gap: 24px;
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-shrink: 0;
        width: 258px;
        height: 230px;
        gap: 16px;
        padding: 41px 49px;
        text-align: center;
        background: #fff;
        border-radius: 4px;
        .ico_item_logo {
          display: block;
          margin: 0 auto;
          width: 160px;
          height: 40px;
          background-size: 160px auto;
          background-size: auto 100%;
        }
        .tlt {
          font-weight: 700;
          font-size: 20px;
          line-height: 160%;
          white-space: nowrap;
        }
        .tel {
          display: block;
          font-weight: 400;
          font-size: 14px;
          line-height: 140%;
          color: #727272;
        }
        .btn_txt {
          font-weight: 600;
          font-size: 16px;
          line-height: 150%;
          text-decoration: underline;
        }
      }
      .ev {
        background: url('../../images/ico/img_logo_ev.png') no-repeat center center;
      }
      .gs {
        background: url('../../images/ico/img_logo_gs.png') no-repeat center center;
      }
      .gsc {
        background: url('../../images/ico/img_logo_gschargev.png') no-repeat center center;
      }
      .sk {
        background: url('../../images/ico/img_logo_sk.png') no-repeat center center;
      }
      .chaevi {
        background: url('../../images/ico/img_logo_chaevi.png') no-repeat center center;
      }
      .happy {
        background: url('../../images/ico/img_logo_happy.png') no-repeat center center;
      }
      // .charg {
      //   background: url('../../images/ico/img_logo_charg.png') no-repeat center center;
      // }
      .power {
        background: url('../../images/ico/img_logo_power.png') no-repeat center center;
      }
      // .gntel {
      //   background: url('../../images/ico/img_logo_gntel.png') no-repeat center center;
      // }
      .everon {
        background: url('../../images/ico/img_logo_everon.png') no-repeat center center;
      }
      .jeju {
        background: url('../../images/ico/img_logo_jeju.png') no-repeat center center;
      }
      .klin {
        background: url('../../images/ico/img_logo_klin.png') no-repeat center center;
      }
      .manage {
        background: url('../../images/ico/img_logo_manage.png') no-repeat center center;
      }
      .cus {
        background: url('../../images/ico/img_logo_cus.png') no-repeat center center;
      }
      .ecarplug {
        background: url('../../images/ico/img_logo_ecarplug.png') no-repeat center center;
      }
    }
    @media screen and (max-width: 768px) {
      &::-webkit-scrollbar {
        display: none;
      }
      .scroll_cont2 {
        width: 100%;
        display: grid;
        gap: 20px;
        grid-template-columns: 1fr 1fr;
        .item {
          width: auto;
          min-width: 146px;
          height: auto;
          padding: 26px;
          .ico_item_logo {
            width: 96px;
            height: 28px;
            background-size: cover;
          }
          .tlt {
            font-size: 14px;
            line-height: 145%;
          }
          .tel {
            font-size: 12px;
            line-height: 150%;
          }
          .btn_txt {
            font-size: 12px;
            line-height: 165%;
          }
        }
      }
    }
  }

  //textbox
  .desc_box {
    margin-top: 24px;
    padding: 24px;
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    text-align: center;
    color: #222e59;
    background: #edf0f4;
    border-radius: 4px;
    span {
      font-weight: 400;
      color: #222e59;
    }
    br {
      display: none;
    }
    @media screen and (max-width: 768px) {
      min-height: 71px;
      font-weight: 600;
      font-size: 14px;
      line-height: 165%;
      br {
        display: block;
      }
    }
  }

  // bg_gray
  .cont_box {
    position: relative;
  }
  .bg_gray,
  .bg_navy {
    content: '';
    display: block;
    position: absolute;
    z-index: -99;
    top: 0;
    right: 0;
    bottom: 0;
    left: calc(50% - 50vw);
    width: calc(100vw - 10px);
    height: 100%;
    overflow-x: hidden;
    @media screen and (max-width: 1024px) {
      width: 100vw;
    }
  }
  .bg_gray {
    background: #f4f5f6;
  }
  .bg_navy {
    background: #222e59;
  }
}

@media screen and (max-width: 768px) {
  .estimate {
    .popup_wrap {
      top: 0;
    }
  }
}

.packages {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  padding: 40px 32px;
  gap: 24px;
  li {
    width: 48%;
    overflow: hidden;
    figure {
      float: left;
      width: 120px;
      height: 100px;
      margin-right: 24px;
      border-radius: 10px;
      background-color: lightblue;
    }
    span {
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #4f555e;
    }

    b {
      display: inline-block;
      margin-top: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 140%;
      color: #111111;
    }

    &:nth-of-type(1) {
      figure {
        background: url('../../images/img/img_buy_package1.jpg') no-repeat left center;
        background-size: 120px;
      }
    }
    &:nth-of-type(2) {
      figure {
        background: url('../../images/img/img_buy_package2.jpg') no-repeat left center;
        background-size: 120px;
      }
    }
    &:nth-of-type(3) {
      figure {
        background: url('../../images/img/img_buy_package3.jpg') no-repeat left center;
        background-size: 120px;
      }
    }
    &:nth-of-type(4) {
      figure {
        background: url('../../images/img/img_buy_package4.jpg') no-repeat left center;
        background-size: 120px;
      }
    }
    &:nth-of-type(5) {
      figure {
        background: url('../../images/img/img_buy_package5.jpg') no-repeat left center;
        background-size: 120px;
      }
    }
    &:nth-of-type(6) {
      figure {
        background: url('../../images/img/img_buy_package6.jpg') no-repeat left center;
        background-size: 120px;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .packages {
    padding: 32px 0;
    gap: 16px;
    li {
      width: 100%;
    }
  }
}
