@mixin specs {
  .con {
    flex-direction: column;
    gap: 60px 0;
    .img_slide_wrap {
      width: 100%;
      border: 0;
      .swiper-slide {
        height: 300px;
        img {
          height: 100%;
          object-fit: contain;
        }
      }
    }
    .table_wrap {
      width: 100%;
    }
  }
}

.table_wrap.th_lf.performance_table table th {
  font-weight: normal;
}
.table_wrap.th_lf.performance_table table td {
  font-weight: 600;
}

.performance {
  .con {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 0 32px;
    .img_slide_wrap {
      width: calc(60% - 32px);
      border: 1px solid $color_gray7;
      .slide_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 100px 40px;
        padding: 70px 50px;
      }
      .slide {
        width: calc(50% - 20px);
        &:last-child {
          width: 100%;
        }
      }
    }
    .table_wrap {
      width: 40%;
    }
  }

  // tablets
  // @media screen and (min-width: 769px) and (max-width: 1180px) and (orientation: portrait) {
  //   .mo_view {
  //     display: block !important;
  //   }
  //   @include specs;
  // }
  // @media screen and (min-width: 769px) and (max-width: 1180px) and (orientation: landscape) {
  //   .mo_view {
  //     display: block !important;
  //   }
  // }

  @media screen and (max-width: 1024px) {
    .pc_view {
      display: block !important;
    }
    .con {
      .img_slide_wrap {
        .slide_wrap {
          gap: 60px 40px;
          padding: 30px;
        }
      }
    }
  }

  //   mobile
  @media screen and (max-width: 768px) {
    .pc_view {
      display: none !important;
    }
    @include specs;
    .con {
      .img_slide_wrap {
        width: calc(100% + 48px);
        position: relative;
        left: -24px;
        right: -24px;
        margin: auto;
        .swiper-slide {
          padding: 0 54px;
        }
      }
    }
    .swiper-button-prev,
    .swiper-button-next {
      width: 13.333vw;
      height: 13.333vw;
    }
    .swiper-button-prev {
      left: 0;
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev:after,
    .swiper-button-next:after {
      width: 13.333vw;
      height: 13.333vw;
      background-size: 13.333vw;
    }
  }
}
