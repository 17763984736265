.service_info {
  .visual {
    // margin-bottom: 68px;
    // background: url('../../images/img/img_visual_serviceinfo.jpg') no-repeat center center;
    // background-size: cover;
    .bg_img {
      background: url(../../images/img/img_visual_serviceinfo.jpg) no-repeat 50%;
      background-size: cover;
    }
  }
  .cont + .cont {
    margin-top: 60px;
  }
  & .cont:first-of-type {
    padding: 68px 0 144px;
  }
  .cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    .name {
      @include typo_header3;
      font-size: 28px;
      line-height: 135%;
      word-break: break-all;
    }
    .bg_gray {
      content: '';
      display: block;
      position: absolute;
      z-index: -99;
      top: 0;
      right: 0;
      bottom: 0;
      left: calc(50% - 50vw);
      width: calc(100vw - 10px);
      height: 100%;
      background: #f6f7f9;
      overflow-x: hidden;
    }
  }
  .tlt_wrap {
    width: 382px;
    .tlt {
      @include typo_header2;
      font-size: 36px;
      line-height: 135%;
      color: $color_black;
      word-break: keep-all;
    }
    .desc {
      // @include typo_title1;
      margin-top: 30px;
      word-break: keep-all;
    }
  }
  .card_wrap {
    .card {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 35px 32px 75px;
        width: calc(50% - 10px);
        border-radius: 8px;
        background: #fff;
        .category {
          @include typo_title1;
          font-weight: 500;
          line-height: 160%;
          color: $color_black;
        }
        .name {
          margin-top: 4px;
        }
        .sub_de {
          margin-top: 4px;
          color: $color_gray5;
        }
      }
      @for $info from 1 to 5 {
        .item:nth-child(#{$info}) {
          background: #fff
            url('../../images/ico/ico_service_info' + $info + '.png')
            no-repeat
            right
            30px
            bottom
            34px;
          background-size: 66px;
        }
      }
    }
    .info {
      margin-top: 20px;
      li {
        color: #4f555d;
      }
    }
  }
  .btn_primary_l {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .list {
    // border-top: 1px solid $color_black;
    > li {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 35px 50px;
    }
    > li + li {
      padding-top: 50px;
      border-top: 1px solid $color_gray9;
    }
    .name {
      width: 250px;
    }
    .desc {
      // @include typo_title1;
      color: $color_grayDark;
      line-height: 160%;
      text-align: right;
      width: calc(100% - 270px);
      .link {
        display: inline-flex;
        align-items: center;
        color: $color_grayDark;
        column-gap: 5px;
        &:after {
          content: '';
          display: block;
          width: 16px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }
        &.new_window:after {
          background-image: url('../../images/ico/ico_link_new_window.png');
        }
        &.mail_to:after {
          background-image: url('../../images/ico/ico_link_mail_to.png');
        }
      }
    }
  }
  .card_wrap,
  .list {
    width: calc(100% - 516px);
  }

  @media screen and (max-width: 1400px) {
    .cont {
      flex-direction: column;

      .tlt_wrap {
        width: 100%;
      }

      .card_wrap {
        width: 100%;
        margin-top: 40px;

        .card {
          width: 100%;
          .item {
          }
        }
      }

      .list {
        width: 100%;
        margin-top: 40px;
        padding: 50px 0 0 0;
        border-top: 1px solid #000;
        li {
          padding-left: 0;
          padding-right: 0;
        }
        li + li {
          border-bottom: 1px solid #ececec;
        }
      }
    }
  }

  //   tablet
  @media screen and (max-width: 1024px) {
    .list {
      margin-top: 20px;
    }
    .btn_primary_l {
      position: static;
      margin-top: 50px;
    }
  }

  //   mobile
  @media screen and (max-width: 768px) {
    .visual {
      margin-bottom: 0;
      // background: url('../../images/img/mo_img_visual_serviceinfo.jpg') no-repeat center center;
      // background-size: cover;
      .bg_img {
        background: url(../../images/img/mo_img_visual_serviceinfo.jpg) no-repeat 50%;
        background-size: cover;
      }
    }
    & .cont:first-of-type {
      padding: 32px 0 103px;
    }
    .cont {
      & + .cont {
        margin-top: 83px;
      }
      .name {
        font-size: 24px;
        line-height: 140%;
      }
    }
    .tlt_wrap {
      .tlt {
        font-size: 24px;
        font-weight: 700;
        line-height: 140%;
      }
      .desc {
        @include typo_body1;
        margin-top: 24px;
      }
    }
    .card_wrap {
      .card {
        .item {
          width: 100%;
          padding: 24px;
          min-height: 195px;
          .category {
            @include typo_body1;
            font-weight: 700;
          }
          .sub_de {
            font-size: 12px;
            line-height: 140%;
          }
        }
      }
    }
    .cont {
      .list {
        margin-top: 20px;
        padding-top: 0;
        .name,
        .desc {
          width: 100%;
        }
        .name {
          font-size: 18px;
          line-height: 135%;
        }
        .desc {
          @include typo_body1;
          margin-top: 20px;
          text-align: left;
        }
        > li {
          flex-direction: column;
          align-items: flex-start;
          padding: 35px 0;
        }
      }
    }
  }
}
