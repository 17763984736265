@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}

// html {
//   height: 100%;
// }

.tit {
  font-weight: 700;
  font-size: 60px;
  line-height: 125%;
}

.tit2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 120%;
}

.subhead {
  font-weight: 700;
  font-size: 36px;
  line-height: 135%;
}

.subhead2 {
  font-weight: 700;
  font-size: 24px;
  line-height: 135%;
  color: #111111;
}

.desc {
  font-size: 20px;
  font-weight: 400;
  line-height: 160%;
}

// 뒤 섹션들의 공통 헤더
.detail_header {
  width: 100%;
  margin: 90px auto 0;
  text-align: center;

  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  z-index: 1001;
  .inner_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .tit {
    color: #fff;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }
  .subhead {
    margin-top: 8px;
    color: #fff;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
  }
  .tab_buttons {
    display: inline-flex;
    justify-content: center;
    min-width: 300px;
    height: 42px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 30px;

    position: relative;
    .tab_btn {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: auto;
      position: relative;
      font-weight: 700;
      font-size: 16px;
      padding: 0 24px;
      line-height: 15px;
      // height: 100%;
      // line-height: 42px;
      &.on {
        // background-color: #fff;
        // border-radius: 30px;
      }
    }
    .mover {
      width: 50%;
      transition: left ease 0.5s, width ease 0.5s;
      position: absolute;
      display: inline-flex;
      background-color: #fff;
      border-radius: 30px;
      height: 42px;
    }
  }
}

.popup_detail {
  position: fixed;
  z-index: 1002;
  width: 40%;
  max-width: 960px;
  right: -2000px;
  top: 0;
  bottom: 0;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.16);
  transition: right 0.5s ease-out;
  background-color: rgba(255, 255, 255, 0.9);
  &.active {
    right: 0;
    .popup_dim {
      transform: translateX(0);
    }
  }

  .popup_dim {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    transform: translateX(100%);
    z-index: 0;
  }

  .popup_inner {
    position: relative;
    z-index: 1;
    padding: calc(80px + 60px) 60px 80px; // lnb height 더함
    .btn_pop_close {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 60px;
      width: 64px;
      height: 64px;
      background: url(../../images/introduce/pc/ico_close.png) no-repeat center center;
    }
  }
  // .img_detail1 {
  //   width: 100%;
  //   height: 486px;
  //   background: url(../../images/introduce/pc/img_exterior.jpg) no-repeat 0 0;
  //   background-size: cover;
  // }
  // .img_detail2 {
  //   width: 100%;
  //   height: 486px;
  //   background: url(../../images/introduce/pc/img_interior.jpg) no-repeat 0 0;
  //   background-size: cover;
  // }

  dl {
    margin-top: 40px;
    dt {
      font-weight: 700;
      font-size: 36px;
      line-height: 135%;
      color: #111111;
    }
    dd {
      margin-top: 24px;
      font-weight: 400;
      font-size: 20px;
      line-height: 160%;
      color: #4f555e;
    }
  }
}

.introduce {
  --grid-margin: 40px;

  overflow: hidden;
  video {
    display: block;
    object-fit: cover;
  }

  &.stop-snap {
    scroll-snap-type: none !important;
    section,
    .relation {
      scroll-snap-align: none !important;
    }
  }

  scroll-snap-type: y mandatory;
  .section_wrap {
    // max-height: 100vh;
    // height: 100vh;
    // width: 100vw;
    // position: fixed;
    &.scrollEnd {
      overflow: hidden;
      position: absolute;
    }
  }

  section {
    position: relative;
    &.noSnap {
      scroll-snap-align: none !important;
      max-width: 100%;
    }
    &.init {
      transition: none !important;
    }
    // &.up,
    // &.active {
    //   transform: translateY(0);
    // }
    // &.up {
    //   [data-trigger] {
    //     opacity: 1;
    //     transform: translateY(0);
    //   }
    // }

    &.relationPage {
      height: auto;
    }

    // setting
    //   .object {
    //     opacity: 0;
    //     // transition: opacity 2s ease, transform 1s cubic-bezier(0.45, 0, 0.51, 0.94);
    //     &[slide-up] {
    //       @for $i from 1 to 11 {
    //         &[slide-up='#{$i}'] {
    //           transition: opacity + (' ' + $i) + s ease,
    //             transform + ' ' + ($i/2) + s cubic-bezier(0.45, 0, 0.51, 0.94);
    //         }
    //         // transform:translateY(100px);
    //       }
    //       transform: translateY(100px);
    //     }

    //     &[slide-left] {
    //       @for $i from 1 to 11 {
    //         &[slide-left='#{$i}'] {
    //           transition: opacity + (' ' + $i) + s ease,
    //             transform + ' ' + ($i/2) + s cubic-bezier(0.45, 0, 0.51, 0.94);
    //         }
    //         // transform:translateX(-100px);
    //       }
    //       transform: translateX(-100px);
    //     }

    //     &[slide-right] {
    //       @for $i from 1 to 11 {
    //         &[slide-right='#{$i}'] {
    //           transition: opacity + (' ' + $i) + s ease,
    //             transform + ' ' + ($i/2) + s cubic-bezier(0.45, 0, 0.51, 0.94);
    //         }
    //         // transform:translateX(100px);
    //       }
    //       transform: translateX(100px);
    //     }
    //   }

    //   // active
    //   &.up {
    //     .object {
    //       opacity: 1;
    //       &[slide-up] {
    //         transform: translateY(0);
    //       }
    //       &[slide-left] {
    //         transform: translateX(0);
    //       }
    //       &[slide-right] {
    //         transform: translateX(0);
    //       }
    //     }
    //   }
  }

  .relation {
    // scroll-snap-align: start;
    _::-webkit-full-page-media {
      scroll-snap-align: none !important;
    }
  }
  .section_lnb {
    width: 100%;
    height: 60px;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    position: fixed;
    left: 0;
    z-index: 2000;
    transition: top ease 0.6s;
    &_inner {
      max-width: 1920px;
      height: 100%;
      display: flex;
      align-items: center;
      .lnb_logo_t4k {
      }
      .lnb_nav_mobile {
        display: none;
      }
      .catalog_list_box {
        margin-left: 2.08%;
        position: relative;
      }
      .catalog_list {
        .desktop_br {
          display: inline-block;
        }
        li {
          a {
            line-height: 60px;
          }
        }
      }
    }
    .lnb_highlighter {
      // width: 100%;
      position: absolute;
      height: 2px;
      left: 0;
      top: 39px;
      background: #4876ee;
      transition: width 0.5s ease-out, left 0.5s ease-out;
    }
    .section_lnb_list {
      display: flex;

      li {
        a {
          cursor: pointer;
          position: relative;
          margin: 0 12px;
          font-weight: 500;
          font-size: 16px;
          line-height: 60px;
          color: #000000;
          &.is-active {
            &:after {
              position: absolute;
              left: 0;
              bottom: 0;
              content: '';
              display: block;
              width: 100%;
              height: 2px;
              left: 0;
              bottom: 0;
              background: #4876ee;
            }
          }
        }
      }
    }
    .lnb_logo_t4k {
      margin-left: 40px;
      width: 65.33px;
      height: 21px;
      background: url(../../images/introduce/pc/logo_t4k.png) no-repeat 0 0;
    }
    .catalog_list {
      // display: none;
      margin-left: auto;
      display: flex;
      margin-right: 30px;
      li {
        white-space: nowrap;
        position: relative;
        margin-right: 10px;
        margin-left: 10px;
      }
      li + li {
        &:before {
          position: absolute;
          width: 1px;
          height: 12px;
          border-left: 1px solid #999;
          content: '';
          left: -9px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .inner_box {
    position: relative;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
    padding: 0 85px;
    // margin-top: 60px;
  }

  // section1
  .section_hero {
    height: 100vh;
    background: #111;
    position: relative;
    .hero_bg {
      background: #111;
    }
    .hero_text {
      width: 100%;
      max-width: 1120px;
      margin: 0 auto;
      padding: calc(35vh + 80px) var(--grid-margin) 0;

      font-style: normal;
      font-weight: 700;
      font-size: 80px;
      line-height: 125%;
      color: #ffffff;

      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      z-index: 1;
      // span {
      //   color: #fff;
      // }
      // small {
      //   display: block;
      //   margin: 16px 0 0 0;
      //   font-style: normal;
      //   font-weight: 400;
      //   font-size: 12px;
      //   line-height: 155%;
      //   color: #ffffff;
      //   opacity: 0.8;
      // }

      .hero_logo {
        margin-top: 24px;
        width: 182px;
        height: auto;
      }

      .btn_reserv {
        display: inline-block;
        width: 230px;
        height: 60px;
        margin-top: 80px;

        border-radius: 0px;
        padding: 14px 22px;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 160%;
        /* identical to box height, or 22px */

        color: #ffffff;

        background: #000 url('../../../assets/images/introduce/pc/ico_arrow_right.png') no-repeat
          201px 50%;
        background-size: 10px auto;
      }
    }
  }
  //section2
  .section_summary {
    padding: 180px 0;
    .tit {
      text-align: center;
    }
    .desc {
      text-align: center;
      color: #4f555d;
      margin-top: 36px;
    }
    .summary_list {
      margin: 145px auto 0;
      width: 644px;
      // margin-bottom: 212px;
      ul {
        display: flex;
        justify-content: space-between;
        li {
          text-align: center;
          i {
            &.ico_summary01 {
              background: url(../../images/introduce/pc/ico_summary01.png) no-repeat 0 0;
              width: 72px;
              height: 52px;
            }
            &.ico_summary02 {
              background: url(../../images/introduce/pc/ico_summary02.png) no-repeat 0 0;
              width: 69px;
              height: 52px;
            }
            &.ico_summary03 {
              background: url(../../images/introduce/pc/ico_summary03.png) no-repeat 0 0;
              width: 64px;
              height: 52px;
            }
          }
          strong {
            margin-top: 24px;
            display: block;
            font-weight: 700;
            font-size: 36px;
            line-height: 135%;
          }
          em {
            display: block;
            margin: 0 0 0 0;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 150%;
          }
          span {
            display: block;
            margin-top: 10px;
            font-weight: 400;
            font-size: 11px;
            line-height: 135%;
            color: #9b9b9b;
          }
        }
      }
    }
  }
  // section3
  .section_battery {
    height: 970px;
    padding: 140px 0 525px;
    box-sizing: border-box;
    background-color: #000;

    .tit {
      color: #fff;
      text-align: center;
    }
    .subhead {
      padding-top: 40px;
      padding-bottom: 16px;
      color: #fff;
      text-align: center;
    }
    .desc {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      color: #fff;
      text-align: center;
    }

    .btn_movie_controler {
      width: 54px;
      height: 54px;
      background: url(../../images/introduce/pc/ico_controler.png) no-repeat 0 0%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;
    }
    .img_battery {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      // transform: translate(-50%, 0);
      background: url(../../images/introduce/pc/img_battery1.png) no-repeat center bottom;
      background-size: cover;
      // width: 1724px;
      height: 50%;
    }
  }
  // section4

  .section_battery_lfp {
    padding: 0 0 130px 0;
    background-color: #eceff2;
    background: url('../../images/introduce/pc/img_battery_bg.jpg') no-repeat center;
    background-size: cover;
    .tit2 {
      padding: 150px 0 0 0;
    }
    .desc {
      margin-top: 40px;
      color: #000000;
    }
    // .img_battery_lfp {
    //   position: absolute;
    //   top: 0;
    //   right: 0;
    //   width: 55%;
    //   height: 45vh;
    //   min-height: 370px;
    //   background: url(../../images/introduce/pc/img_battery2.png) no-repeat right top;
    //   background-size: contain;
    // }

    // }

    .swiper {
      margin-left: -56px;
      overflow: visible;
    }

    // .swiper-wrapper {
    //   max-width: calc(1430px + 133px);
    //   margin: 0px auto;
    // }
    .section_battery_swiper {
      // display: flex;
      // flex-wrap: nowrap;
      // white-space: nowrap;
      // overflow: auto;
      // scroll-behavior: smooth;
      margin-top: 164px;
      .swiper-wrapper {
        & .section_battery_slide:last-of-type {
          width: 519px;
        }
        .section_battery_slide {
          position: relative;
          width: 492px;
          height: 330px;
          padding: 56px;
          margin-right: 32px;
          box-sizing: border-box;
          background: #fafafa;
          h2 {
            font-weight: 700;
            font-size: 48px;
            line-height: 120%;
            margin-bottom: 16px;
          }
          em {
            display: block;
            margin-bottom: 56px;
            font-weight: 700;
            font-size: 28px;
            line-height: 145%;
            white-space: nowrap;
          }
          p {
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #000;
            white-space: normal;
            word-break: keep-all;
            span {
              display: block;
              margin-top: 12px;
              font-size: 12px;
              font-weight: 400;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
  // section5
  .section_protect {
    padding: 190px 0; // top 340
    background: #eceff2;
    .inner_box {
      // max-width: calc(748px * 2 + 32px);
    }
    .tit2 {
      margin-bottom: 56px;
    }
    .protect_list {
      & li:first-of-type {
        .protect_movie {
          position: relative;
          &::before {
            content: '';
            position: absolute;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // background-color: #c6b5ba;
            // mix-blend-mode: multiply;
            z-index: 2;
          }
        }
      }
      display: flex;
      li + li {
        margin-left: 32px;
      }
      li {
        position: relative;
        width: 50%;
        max-width: 748px;
        background: #fff;
        .protect_movie {
          height: 420px;
        }
        .protect_desc {
          padding: 24px 32px 40px;
          b {
            display: block;
            font-size: 28px;
            font-weight: 700;
            line-height: 32px;
          }
          p {
            margin-top: 16px;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }

  .swiper-button-prev {
    display: flex;
    width: 80px;
    height: 80px;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.3);
    &:after {
      width: 80px;
      height: 80px;
      background: url(../../../assets/images/ico/btn_arrow_left.png) no-repeat center;
      background-size: 13.33px auto;
    }
  }
  .swiper-button-next {
    display: flex;
    width: 80px;
    height: 80px;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    &:after {
      width: 80px;
      height: 80px;
      background: url(../../../assets/images/ico/btn_arrow_right.png) no-repeat center;
      background-size: 13.33px auto;
    }
  }
  .swiper-button-disabled {
    pointer-events: initial !important;
  }

  //section6
  .section_visual {
    height: calc(100vh - 1px); // ipad... 임시

    .swiper {
    }
    .visual_slider {
      video {
        display: block;
      }
      // &:hover {
      //   cursor: url('../../images/introduce/pc/ico_cursor.svg'), pointer;
      // }
      .visual_slider_movie {
      }
      // &.visual01 {
      //   background: url(../../images/introduce/pc/img_visual1.jpg) no-repeat 0 0;
      //   background-size: cover;
      // }
      // &.visual02 {
      //   background: url(../../images/introduce/pc/img_visual2.jpg) no-repeat 0 0;
      //   background-size: cover;
      // }
      .text_area {
        width: 100%;
        max-width: 1600px;
        padding: 0 85px;
        margin: 0 auto;
        position: absolute;
        z-index: 3;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        .tit {
          padding-top: 190px;
          color: #fff;
        }
        .subhead {
          padding: 40px 0 16px;
          color: #fff;
        }
        .desc {
          margin-top: 40px;
          color: #ffffff;
        }
      }
      .btn_txt {
        margin-top: 80px;
        display: inline-block;
        span {
          color: #fff;
        }
        i {
          background: url('../../images/ico/btn_main_announce.png') no-repeat center;
          background-size: 20px;
        }
      }
      .btn_volume {
        position: absolute;
        right: 202px;
        top: 858px;
        display: inline-block;
        width: 54px;
        height: 54px;
        background: url(../../images/introduce/pc/ico_volume.png) no-repeat 0 0;
      }
    }

    .swiper-pagination {
      max-width: 1600px;
      margin: 0 auto;
      left: 50%;
      transform: translate(-50%);
      padding: 0 85px;
      bottom: 70px;

      text-align: left;
      .swiper-pagination-bullet {
        width: 40px !important;
        height: 3px !important;
        margin: 0;
      }
    }
  }
  //section7,8 : interior
  .section_interior {
    padding: 0 0 145px 0;
    background: #111111;

    .tit {
      color: #fff;
      text-align: center;
      padding: 90px 0 0 0;
    }
    .desc {
      margin: 24px 0 62px;
      color: #fff;
      text-align: center;
    }

    .interior_list {
      display: flex;
      justify-content: center;
      .interior-item {
        width: 488px;
        height: 549px;
        font-weight: 400;
        font-size: 20px;
        line-height: 160%;
        color: #4f555e;
        position: relative;

        .interior-thumbnail {
          overflow: hidden;
          position: absolute;
          left: 0;
          top: 0;

          background-size: cover;
        }

        .interior_desc {
          padding: 0 32px 40px;
          position: absolute;
          bottom: 0;
          b {
            font-size: 28px;
            font-weight: 700;
            line-height: 150%;
            color: #fff;
          }
          p {
            display: block;
            margin: 16px 0 0 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            color: #fff;
          }
        }
      }
      .interior-item + .interior-item {
        margin-left: 24px;
      }

      dt {
        font-weight: 700;
        font-size: 36px;
        line-height: 135%;
        color: #111111;
        display: flex;
        // justify-content: center;
        flex-flow: column;

        strong {
          font-weight: 700;
          font-size: 60px;
          line-height: 125%;
        }
      }

      @for $i from 1 to 4 {
        .img_interior#{$i} {
          width: 100%;
          height: 100%;
          background: url('../../images/introduce/pc/img_interior' + $i + '.jpg') no-repeat 0 0;
        }
      }
    }
  }
  //section9
  .section_smartpad {
    display: flex;
    align-items: center;
    padding: 266px 0 400px;

    .smartpad_bg {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: url(../../images/introduce/pc/img_smartpad.jpg) no-repeat 0 0;
      background-size: cover;
    }
    .inner_box {
      .tit,
      .subhead {
        color: #fff;
      }
      .subhead {
        padding: 40px 0 16px;
      }
      .desc {
        color: #ffffff;
      }
      .notice {
        margin: 32px 0 0 0;
        font-size: 14px;
        color: #fff;
      }
    }
  }

  //section10
  .section_navi {
    // height: 100vh;
    padding: 80px 0;
    background: #111;

    .inner_box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .logo_navi {
      overflow: hidden;
      .ico_t {
        float: left;
        display: block;
        width: 60px;
        height: 60px;
        background: url(../../images/introduce/pc/logo_tmap.png) no-repeat 50%;
        background-size: cover;
      }
      .ico_nugu {
        display: inline-block;
        width: 173px;
        height: 60px;
        margin-left: 16px;
        background: url(../../images/introduce/pc/logo_nugu.png) no-repeat 50%;
        background-size: cover;
      }
    }
    .section_navi_movie {
      max-width: 1152px;
      margin: 0 auto;
    }
  }
  // section11
  .section_driving {
    margin: 60px 0 0 0;
    padding: 0 40px 90px;
    .driving-movie {
      position: relative;
      width: 100%;
      background-color: #000;
      overflow: hidden;
      video {
        transform: scale(1.145);
      }
    }

    .driving_inner_box {
      padding: 0 85px;
      .tit {
        margin: -120px 0 0 0;
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 125%;
        color: #ffffff;
        position: relative;
      }
      .spec_list {
        display: flex;
        margin: 108px 0 0 0;
        li {
          width: 25%;
          strong {
            display: block;
            font-size: 16px;
            line-height: 140%;
            color: #4876ee;
          }
          > span {
            font-weight: 700;
            font-size: 48px;
            line-height: 125%;
            small {
              font-size: 28px;
            }
          }
          p {
            margin: 4px 0 0 0;
            font-size: 12px;
            font-weight: 400;
            line-height: 155%;
            color: #4f555e;
            // padding-left: 40px;
          }
        }
        li + li {
          padding-left: 32px;
          padding-bottom: 16px;
          border-left: 1px solid #d5d8db;
        }
      }
    }
    .cautions {
      margin: 48px 0 0 0;
      padding: 24px 0 0 0;
      border-top: 1px solid #d5d8db;
      font-size: 11px;
      font-weight: 400;
      line-height: 16.2px;
      color: #4f555e;
    }
  }

  //section12{
  .section_feature {
    padding: 160px 0 0 0;
    .tit {
    }
    .subhead2 {
      margin: 0 0 24px 0;
    }
    .swiper {
      overflow: visible;
    }
    // todo
    .inner_box {
      max-width: 1600px;
    }
    .swiper-wrapper {
    }
    .feature_slider {
      margin-top: 80px;
    }

    .swiper-slide {
      width: 1120px;
    }
    .swiper-slide + .swiper-slide {
      margin-left: 56px;
    }
    .swiper-pagination {
      display: none !important;
    }
    @for $i from 1 to 8 {
      .img_feature#{$i} {
        padding-top: calc(100% / 19 * 9);
        background: url('../../images/introduce/pc/img_feature' + $i + '.jpg')
          no-repeat
          center
          center;
        // width: 1528px;
        background-size: cover;
      }
    }
  }
  //section13
  .section_color {
    // padding-top: 220px;
    // height: 956px;
    .inner_box {
      display: flex;
      align-items: center;
      height: 100%;
      flex-flow: column;
      padding-top: 150px;
    }
    .tit {
      display: block;
      width: 100%;
      text-align: center;
    }
    .subhead {
      margin: 8px 0 0 0;
    }

    .color_title {
      margin: 0 6px 0 0;
      position: relative;
      display: inline-block;
      vertical-align: bottom;
      overflow: hidden;
      span {
        white-space: nowrap;
        color: #9f9ebe;
        position: absolute;
        left: 0;
        opacity: 0;
        top: 50px;
        transition: opacity 0.2s ease, top 0.5s ease;
        &.active {
          position: relative;
          opacity: 1;
          top: 0;
        }
      }
    }
    .color_chip {
      margin-top: 46px;
      padding: 1rem;
      button {
        position: relative;
        display: inline-block;
        width: 42px;
        height: 42px;
        border-radius: 25px;
        & + button {
          margin-left: 22px;
        }
        &.c_roland {
          background-color: #9f9ebe;
          border: 1px solid #9f9ebe;
        }
        &.c_white {
          background-color: #ffffff;
          border: 1px solid #d8d8d8;
        }
        &.on,
        &:hover {
          &:before {
            position: absolute;
            left: -6px;
            right: -6px;
            top: -6px;
            bottom: -6px;
            display: block;
            content: '';
            border-radius: 30px;
            border: 1px solid #212121;
          }
        }
      }
    }
    .iframe-box {
      width: 60vw;
      height: 64vh;
      position: relative;
    }
    .iframe_wrap {
      z-index: 1;
      opacity: 0;
      position: absolute;
      left: 0;
      top: 10px;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      transition: opacity 0.5s ease, top 0.5s ease;
      &.active {
        z-index: 2;
        opacity: 1;
        top: 0;
      }
    }
  }
  //section14

  .btns_group {
    display: none;
  }
  .active {
    .btns_group {
      display: block;
      .btn_plus {
        &::before {
        }
        &::after {
        }
      }
    }
  }

  .btns_group {
    // position: absolute;
    // left: 0;
    // top: 0;
    // bottom: 0;
    // right: 0;
    .btn_plus {
      position: absolute;
      left: 50%;
      top: 50%;
      width: 34px;
      height: 34px;
      margin: -17px 0 0 -17px;
      overflow: hidden;
      display: flex;
      // background: url(../../images/introduce/pc/btn_more_design.png) no-repeat 0 0;
      &::before {
        content: '';
        display: block;
        width: inherit;
        height: inherit;
        border-radius: 100%;
        background-color: #7d9dee;
        animation: btnAfterAnimation 1.2s ease infinite;
      }
      &::after {
        content: '';
        display: block;
        width: inherit;
        height: inherit;
        position: absolute;
        border-radius: 100%;
        background-color: #4876ee;
        animation: btnBeforeAnimate 1.2s ease infinite;
      }
    }
    &.active {
    }
  }

  .section_design {
    overflow: hidden;
    position: relative;
  }

  .tab_content_box {
    .tab_content {
      top: 10px;
      opacity: 0;
      position: absolute;
      left: 0;
      width: 100%;
      bottom: 0;
      transition: opacity 0.5s ease, top 0.5s ease;
      z-index: 1;
      &:first-child {
        position: relative;
      }
      &.active {
        top: 0;
        opacity: 1;
        z-index: 2;
      }
    }
    .img_wrap {
      position: relative;
      img {
      }
    }
    .btns_group {
    }
  }

  // popup images
  .detail_layer_content {
    .img {
      width: 100%;
      padding-top: calc(100% / 16 * 9);

      background-size: cover;
      background-repeat: no-repeat;
      background-position: 0 0;
    }
    &.front_content {
      @for $i from 1 to 10 {
        div.img#{$i} {
          background-image: url('../../images/introduce/pc/img_front' + $i + '.jpg');
        }
      }
    }
    &.rear_content {
      @for $i from 1 to 4 {
        div.img#{$i} {
          background-image: url('../../images/introduce/pc/img_rear' + $i + '.jpg');
        }
      }
    }
    &.dashboard_content {
      @for $i from 1 to 11 {
        div.img#{$i} {
          background-image: url('../../images/introduce/pc/img_dashboard' + $i + '.jpg');
        }
      }
    }
    &.console_content {
      @for $i from 1 to 9 {
        div.img#{$i} {
          background-image: url('../../images/introduce/pc/img_console' + $i + '.jpg');
        }
      }
    }
    &.seat_content {
      @for $i from 1 to 9 {
        div.img#{$i} {
          background-image: url('../../images/introduce/pc/img_seat' + $i + '.jpg');
        }
      }
    }
    .desc {
      margin-top: 20px;
    }
  }

  //section15
  .section_space {
    > .tit {
      position: absolute;
      top: 90px;
      left: 5%;
      color: #ffffff;
      z-index: 100;
    }
  }
  //section 16
  .section_merit {
    background: #ececec;

    // detail_header 사용
    .model_header {
      width: 100%;
      max-width: 1120px;
      margin: 90px auto 0;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      .tit {
        color: #fff;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      }
      .subhead {
        margin-top: 8px;
        color: #fff;
        text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15);
      }
    }

    // 백그라운드에서 img로 변경을 위한 주석
    .swiper-slide {
      // height: 100vh;
    }

    .img_truck_1 {
      // width: 100%;
      // height: 100%;
      // background: url(../../images/introduce/pc/img_truck_1.jpg) center center;
      // background-size: cover;
      // position: absolute;
    }
    .img_truck_2 {
      // width: 100%;
      // height: 100%;
      // background: url(../../images/introduce/pc/img_truck_2.jpg) center center;
      // background-size: cover;
    }
    .img_truck {
      display: inline-block;
      width: 907px;
      height: 386px;
      background: url(../../images/introduce/pc/img_truck.png) no-repeat 0 0;
      margin: 123px auto 0;
    }
  }

  #batteryMoviePopup {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    button {
      position: absolute;
      top: 100px;
      right: 10px;
      z-index: 3;
      color: #fff;
      // border: 1px solid red;
      width: 72px;
      height: 72px;
      background: url(../../images/introduce/pc/btn_close_popup.png) no-repeat 50% 50%;
    }
  }
}

// todo 중복 제거
@media screen and (max-width: 1600px) {
  .detail_header {
  }
  .popup_detail {
    .popup_inner {
      padding-left: 40px;
      padding-right: 40px;
      dl {
        dt {
          font-size: 24px;
          line-height: 135%;
        }
        dd {
          margin-top: 16px;
          font-size: 16px;
          line-height: 150%;
        }
      }
    }
  }

  .tit {
    @include typo_header2;
  }

  .tit2 {
    font-size: 36px;
  }

  .text-white {
    color: #fff;
  }

  .subhead {
    font-size: 30px;
    font-weight: 500;
    line-height: 40.5px;
  }

  .subhead2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 135%;
    color: #111111;
  }

  .desc {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
  }

  .introduce {
    .inner_box {
      padding: 0 40px;
    }
    // section1
    .section_hero {
      .hero_text {
        font-weight: 700;
        font-size: 60px;
        line-height: 120%;
        // span {
        // }
        // small {
        //   display: block;
        //   margin: 16px 0 0 0;
        // }
        .hero_logo {
          margin-top: 16px;
          width: 148px;
        }

        .btn_reserv {
          width: 230px;
          height: 56px;
          font-weight: 500;
          font-size: 18px;
          line-height: 140%;
        }
      }
    }
    //section2
    .section_summary {
      .tit {
      }
      .desc {
        margin-top: 36px;
      }
      .summary_list {
        // margin: 145px auto 0;
        // width: 644px;
        // margin-bottom: 212px;
        ul {
          li {
            i {
              &.ico_summary01 {
                width: 72px;
                height: 52px;
              }
              &.ico_summary02 {
                width: 69px;
                height: 52px;
              }
              &.ico_summary03 {
                width: 64px;
                height: 52px;
              }
            }
            strong {
              margin-top: 24px;
              display: block;
              font-size: 24px;
              line-height: 135%;
            }
            em {
              display: block;
              margin: 16px 0 0 0;
              font-size: 16px;
              line-height: 150%;
            }
            span {
              margin-top: 10px;
              font-weight: 400;
              font-size: 11px;
              line-height: 135%;
            }
          }
        }
      }
    }
    // section3
    .section_battery {
      .subhead {
        padding-top: 40px;
        padding-bottom: 16px;
        text-align: center;
      }
      .desc {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        color: #fff;
        text-align: center;
      }

      .btn_movie_controler {
        width: 54px;
        height: 54px;
        margin-top: 240px;
        background: url(../../images/introduce/pc/ico_controler.png) no-repeat 0 0%;
      }
      .img_battery {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        // transform: translate(-50%, 0);
        background-image: url(../../images/introduce/pc/img_battery1.png);
      }
    }
    // section4

    .section_battery_lfp {
      .tit2 {
      }
      .desc {
        margin-top: 40px;
      }
      .img_battery_lfp {
      }
      .swiper {
        margin-left: -32px;
      }
      .section_battery_swiper {
        .swiper-wrapper {
          & .section_battery_slide:last-of-type {
            width: 364px;
          }
          .section_battery_slide {
            width: 364px;
            height: 273px;
            margin-right: 24px;
            padding: 40px 32px;
            h2 {
              font-size: 36px;
              line-height: 125%;
              margin-bottom: 16px;
            }
            em {
              margin-bottom: 32px;
              font-size: 20px;
              line-height: 135%;
            }
            p {
              font-weight: 400;
              font-size: 16px;
              line-height: 150%;
              white-space: normal;
              span {
                display: block;
                margin-top: 12px;
                font-size: 12px;
                font-weight: 400;
                line-height: 19px;
              }
            }
          }
        }
      }
    }
    // section5
    .section_protect {
      padding: 0 0 190px; // top 340
      .inner_box {
        max-width: calc(542px * 2 + 32px);
        margin: 0 auto;
      }
      .tit2 {
      }
      .protect_list {
        li + li {
          margin-left: 32px;
        }
        li {
          position: relative;
          width: 50%;
          max-width: 542px;
          background: #fff;
          .protect_movie {
            height: 304px;
          }
          .protect_desc {
            b {
              font-size: 24px;
            }
            p {
            }
          }
        }
      }
    }
    //section6
    .section_visual {
      .swiper {
      }
      .visual_slider {
        .visual_slider_movie {
        }
        .text_area {
          .tit {
            padding-top: 190px;
            color: #fff;
          }
          .subhead {
            padding: 40px 0 16px;
            color: #fff;
          }
          .desc {
            margin-top: 40px;
            color: #ffffff;
          }
        }
        .btn_more {
          // margin-top: 72px;
          // display: inline-block;
          // width: 42px;
          // height: 42px;
          // background: url(../../images/introduce/pc/btn_more.png) no-repeat 0 0;
        }
        .btn_volume {
          position: absolute;
          right: 202px;
          top: 858px;
          display: inline-block;
          width: 54px;
          height: 54px;
          background: url(../../images/introduce/pc/ico_volume.png) no-repeat 0 0;
        }
      }

      .swiper-pagination {
        padding: 0 85px;
        bottom: 70px;
        .swiper-pagination-bullet {
          width: 40px !important;
          height: 3px !important;
          margin: 0;
        }
      }
    }
    //section7,8 : interior
    .section_interior {
      .tit {
        padding: 90px 0 0 0;
      }
      .desc {
        margin: 24px 0 62px;
      }

      .interior_list {
        .interior-item {
          width: 360px;
          height: 405px;

          .interior-thumbnail {
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
          }

          .interior_desc {
            b {
              font-size: 24px;
              line-height: 32px;
            }
            p {
              margin: 16px 0 0 0;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
            }
          }
        }

        dt {
          font-weight: 700;
          font-size: 36px;
          line-height: 135%;
          color: #111111;
          display: flex;
          // justify-content: center;
          flex-flow: column;

          strong {
            font-weight: 700;
            font-size: 60px;
            line-height: 125%;
          }
        }

        @for $i from 1 to 4 {
          .img_interior#{$i} {
            width: 100%;
            height: 100%;
            background: url('../../images/introduce/pc/img_interior' + $i + '.jpg') no-repeat 0 0;
          }
        }
      }
    }
    //section9
    .section_smartpad {
      display: flex;
      align-items: center;

      .smartpad_bg {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background: url(../../images/introduce/pc/img_smartpad.jpg) no-repeat 0 0;
        background-size: cover;
      }
      .inner_box {
        .tit,
        .subhead {
          color: #fff;
        }
        .subhead {
          padding: 40px 0 16px;
        }
        .desc {
          color: #ffffff;
        }
      }
    }

    //section10
    .section_navi {
      .section_navi_movie {
        width: 970px;
      }
    }

    // section11
    .section_driving {
      padding: 0 40px 90px;
      .driving-movie {
      }

      .driving_inner_box {
        padding: 0 85px;
        .tit {
        }
        .spec_list {
          display: flex;
          margin: 108px 0 0 0;
          li {
            width: 25%;
            strong {
              display: block;
              font-size: 14px;
              line-height: 140%;
              color: #4876ee;
            }
            > span {
              font-size: 36px;
              small {
                font-size: 24px;
              }
            }
            p {
              font-size: 11px;
            }
          }
        }
      }
      .cautions {
      }
    }

    //section12{
    .section_feature {
      .feature_slider {
        margin-top: 48px;
      }
    }

    //section13
    .section_color {
      // padding-top: 220px;
      // height: 956px;
      .tit {
        display: block;
        width: 100%;
        margin: 0 0 8px 0;
        text-align: center;
      }
      .subhead {
        margin: 8px 0 0 0;
      }

      .color_title {
        span {
          &:first-child {
          }
          position: absolute;
          left: 0;
          opacity: 0;
          top: 50px;
          transition: opacity 0.2s ease, top 0.5s ease;
          &.active {
          }
        }
      }
      .color_chip {
        margin-top: 46px;
        padding: 1rem;
        button {
          & + button {
            margin-left: 22px;
          }
          &.c_roland {
            background-color: #9f9ebe;
            border: 1px solid #9f9ebe;
          }
          &.c_white {
            background-color: #ffffff;
            border: 1px solid #d8d8d8;
          }
          &.on,
          &:hover {
            &:before {
            }
          }
        }
      }
      .iframe-box {
        min-width: 1024px;
      }
      .iframe_wrap {
        z-index: 1;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 10px;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        transition: opacity 0.5s ease, top 0.5s ease;
        &.active {
          z-index: 2;
          opacity: 1;
          top: 0;
        }
      }
    }
    //section14

    .section_design {
    }

    //section15
    .section_space {
    }
    //section 16
    .section_merit {
      > .tit {
        position: absolute;
        top: 90%;
        left: 5%;
        color: #ffffff;
        z-index: 100;
      }

      .img_truck_1 {
        // background: url(../../images/introduce/tablet/img_truck_1.jpg) center center;
        // background-size: cover;
        // position: absolute;
      }
      .img_truck_2 {
        // background: url(../../images/introduce/tablet/img_truck_2.jpg) center center;
        // background-size: cover;
        // position: absolute;
      }
      .img_truck {
        display: inline-block;
        width: 907px;
        height: 386px;
        background: url(../../images/introduce/pc/img_truck.png) no-repeat 0 0;
        margin: 123px auto 0;
      }
    }

    #batteryMoviePopup {
      position: fixed;
      z-index: 2;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.5);
      button {
        position: absolute;
        top: 100px;
        right: 10px;
        z-index: 3;
        color: #fff;
        // border: 1px solid red;
        width: 72px;
        height: 72px;
        background: url(../../images/introduce/pc/btn_close_popup.png) no-repeat 50% 50%;
      }
    }
  }
}

// tablet narrow
@media screen and (max-width: 1160px) {
  .detail_header {
    // width: auto;
    width: fit-content;
    margin-top: 0;
    .inner_box {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin: 24px 0 0;
      padding: 0 24px;
    }
    .tit {
      margin: 0 0 16px 0;
    }

    .tab_buttons {
      .tab_btn {
        &.on {
          // background-color: #fff;
          // border-radius: 30px;
        }
      }
      .mover {
      }
    }
  }

  .popup_detail {
    top: auto;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    height: auto;

    .popup_inner {
      padding: 60px 40px;
      .btn_pop_close {
        top: 0;
      }
      .detail_layer_content {
        display: flex;
        > div {
          width: 50%;
          height: auto;
        }
        dl {
          width: 50%;
          margin-top: 0;
          padding-left: 32px;
          box-sizing: border-box;
        }
      }
    }
  }

  .inner_box {
    max-width: 100%;
    padding: 0 40px;
  }

  .introduce {
    .section_lnb {
      .lnb_logo_t4k {
      }
      .catalog_list_box {
        position: absolute;
        top: 52px;
        left: 120px;
        margin-left: 0;
        width: 200px;
        height: 0;
        border-radius: 8px;
        transition: height 0.5s ease;
        overflow: hidden;
        background: #ffffff;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);

        // margin-top: 300px;
        &.active {
          height: auto;
        }
        .section_lnb_list {
          .lnb_nav_mobile {
            display: block;
            font-size: 20px;
            font-weight: 700;
            margin-left: 20px;
          }
          display: block;
          li {
            &:first-child {
              margin-top: 10px;
            }
            &:last-child {
              margin-bottom: 10px;
            }
            height: 40px;
            display: flex;
            align-items: center;
            a {
              display: block;
              width: 100%;
              height: 100%;
              margin: 0;
              padding: 0 12px;
              font-size: 14px;
              line-height: 40px;
              &.is-active {
                background: url(../../images/ico/mo_ico_introduce_lnb.png) no-repeat right 12px
                  center;
              }
              &::after {
                content: none;
              }
            }
          }
        }
        .lnb_highlighter {
          display: none;
        }
      }
      .lnb_nav_mobile {
        display: block;
        font-size: 20px;
        font-weight: 700;
        margin-left: 20px;
        .ico_drop_menu {
          display: inline-block;
          margin-left: 24px;
          width: 24px;
          height: 24px;
          background: url('../../images/ico/btn_arrow_select2.png') no-repeat center;
          background-size: 24px;
        }
      }
    }

    // section 1
    .section_hero {
      .hero_bg {
      }
      .hero_text {
        font-weight: 700;
        font-size: 60px;
        line-height: 120%;

        left: auto;
        top: auto;
        bottom: 32vh;
        transform: none;
        b {
          font-size: 28px;
          line-height: 145%;
        }
        small {
          font-weight: 400;
          font-size: 12px;
          line-height: 155%;
          margin-top: 16px;
        }
        .hero_logo {
        }

        .btn_reserv {
          margin-top: 54px;
          width: 230px;
          height: 60px;
          font-weight: 500;
          font-size: 20px;
          line-height: 160%;
          background-position: 201px 50%;
          background-size: 6.5px 13px;
        }
      }
    }
    // section 2
    .section_summary {
    }

    // section 3
    .section_battery {
      height: 720px;
      padding: 100px 0 349px;
    }

    // section 4
    .section_battery_lfp {
      background: url('../../images/introduce/tablet/img_battery_bg_tb.jpg') no-repeat center;
      background-size: cover;
      // .img_battery_lfp {
      //   top: 50px;
      //   min-height: 200px;
      //   height: 260px !important;
      //   background-size: contain !important;
      //   background-position: right center !important;
      // }
      .tit2 {
      }
      .desc {
        margin-top: 24px;
        line-height: 150%;
      }
      .swiper {
        margin-left: 0;
      }
      .section_battery_swiper {
        .section_battery_slide {
          margin-right: 16px;
        }
      }
      // .img_battery_lfp {
      //   width: 594px;
      //   height: 338px;
      //   background: url(../../images/introduce/tablet/img_battery2.png) no-repeat 0 0;
      //   right: -145px;
      //   z-index: 100;
      // }
    }
    // section 5
    .section_protect {
      .tit2 {
        margin-bottom: 40px;
      }
    }

    .swiper-button-prev {
      display: flex;
      width: 48px;
      height: 48px;
      left: 0;
      // top: 37vh;
      background-color: rgba(0, 0, 0, 0.3);
      &:after {
        width: 48px;
        height: 48px;
        background-size: 8px auto;
        // background: url(../../../assets/images/introduce/pc/ico_arrow_left.png) no-repeat center
        //   center;
        // 시안에는 검정배경흰화살표
        // disabled opacity 0.3
      }
    }
    .swiper-button-next {
      display: flex;
      width: 48px;
      height: 48px;

      right: 0;
      // top: 37vh;
      background-color: rgba(0, 0, 0, 0.3);
      right: 0;
      &:after {
        width: 48px;
        height: 48px;
        background-size: 8px auto;
        // background: url(../../../assets/images/introduce/pc/ico_arrow_right.png) no-repeat center
        //   center;
      }
    }

    // section 6
    .section_visual {
      .visual_slider {
        .text_area {
          padding-left: 24px;
          padding-right: 24px;
          text-align: center;
        }
      }
      .swiper-pagination {
        display: none;
      }
    }
    //section7,8 : interior
    .section_interior {
      .inner_box {
        padding: 0;
      }
      .interior_list_box {
        overflow-x: auto;
      }

      .interior_list {
        display: inline-flex;
        padding-left: 40px;
        padding-right: 40px;
      }
      .interior_desc {
      }
      dt {
        width: 100%;
        strong {
          font-size: 48px;
          line-height: 125%;
        }
      }
      dd {
        width: 100%;
        font-size: 20px;
        line-height: 150%;
        margin-top: 0;
        .txt_interior1 {
          font-size: 30px;
          line-height: 140%;
        }
        .txt_interior2 {
          font-size: 20px;
          line-height: 150%;
          word-break: keep-all;
        }

        @for $i from 1 to 4 {
          .img_interior#{$i} {
            background-size: cover;
          }
        }
      }
    }

    .section_smartpad {
      padding: 100px 0 189px;
    }

    //section12{
    .section_feature {
      padding-top: 100px;

      .swiper {
        padding-bottom: 24px;
      }
      .swiper-slide {
        width: 626px;
      }
      .swiper-slide + .swiper-slide {
        margin-left: 24px;
      }
      .swiper-pagination {
        display: flex !important;
        margin: 0 0;
        bottom: 0;
        .swiper-pagination-bullet {
          flex: auto;
          margin: 0;
          height: 4px;
          background-color: #d5d8db !important;
          opacity: 1;
        }
        .swiper-pagination-bullet-active {
          background-color: #111 !important;
        }
      }
    }

    //section 10
    .section_navi {
      .section_navi_movie {
        width: auto;
      }
      .logo_navi {
        max-width: 477px;
        margin: 60px auto 0;
        .ico_t {
          width: 60px;
          height: 60px;
          background-size: 100% 100%;
        }
        .ico_nugu {
          width: 173px;
          height: 60px;
          background-size: 100% 100%;
        }
      }
      .desc {
      }
    }
    // section 11
    .section_driving {
      background: #f6f7f9;
      margin-top: 0;
      padding: 100px var(--grid-margin);
      .driving-movie {
        display: none;
      }
      .driving_inner_box {
        display: flex;
        justify-content: space-between;
        padding: 0;

        .tit {
          padding-left: 0;
          margin-top: 0;
          color: #111;
        }

        .spec_list {
          margin-top: 0;
          display: block;
          width: 55%;
          li {
            width: 100%;
            position: relative;
            strong {
              margin-bottom: 4px;
              font-weight: 400;
              font-size: 14px;
              line-height: 140%;
            }
            > span {
              display: block;
              position: relative;
              p {
                // width: 100%;
                position: absolute;
                left: 152px;
                top: 0;
              }
            }
          }
          li + li {
            border-left: none;
            padding-left: 0;
            padding-bottom: 0;

            margin-top: 32px;
            padding-top: 32px;
            border-top: 1px solid #d5d8db;
          }
        }
      }
      .cautions {
        margin-top: 120px;
      }
    }

    .section_design {
      .tab_content.active {
        // z-index: 1000;
      }
    }

    .section_color {
      .iframe-box {
        min-width: auto;
        width: 100%;
      }
    }

    // section16
    .section_merit {
      // padding-top: 190px;
      .model_header {
        margin-top: 24px;
      }
      .img_truck {
        // margin-top: 80px;
        // width: 100%;
        // height: 226px;
        // background-size: contain;
        // background-position: 50% 50%;
      }
    }
  }
}

//   mobile 769px
@media screen and (max-width: 770px) {
  .detail_header {
    width: 100%;
    .tab_buttons {
      .tab_btn {
        font-size: 14px;
      }
    }
  }
  .popup_detail {
    .popup_inner {
      min-height: 212px;
      padding: 24px;

      .btn_pop_close {
        width: 56px;
        height: 56px;
      }
      .detail_layer_content {
        display: block;
        > div {
          width: 60%;
          margin-bottom: 16px;
        }
        dl {
          width: 100%;
          padding-left: 0;
          dt {
            font-size: 18px;
          }
          dd {
            margin-top: 4px;
            font-size: 15px;
          }
        }
      }
    }
  }

  .tit {
    font-size: 30px !important;
    line-height: 125%;
  }

  .tit2 {
    font-size: 26px;
  }
  .subhead {
    font-size: 24px;
    line-height: 140%;
  }
  .desc {
    // todo check
    font-weight: 400;
    font-size: 16px;
    line-height: 145%;
  }

  .introduce {
    .section_lnb {
      // height: 40px;
      .lnb_logo_t4k {
        display: none;
      }
      .catalog_list_box {
        &.active {
          height: 420px;
        }
        left: 8px;
        top: 56px;
      }
      .catalog_list {
        margin-right: 10px;
        span {
          display: none;
        }
        .desktop_br {
          display: none;
        }
      }
    }
    .inner_box {
      padding: 0 24px;
    }
    --grid-margin: 24px;
    // section 1
    .section_hero {
      height: calc(100vh - 56px);
      overflow: hidden;

      min-height: 100vh;
      min-height: -webkit-fill-available;
      height: fill-available;
      .hero_bg {
      }
      .hero_text {
        padding: 0 24px; // todo
        font-weight: 700;
        font-size: 36px;
        line-height: 125%;

        padding-top: 0;

        bottom: 120px;
        // span {
        //   font-size: 24px;
        // }
        // small {
        //   margin-top: 20px;
        // }

        .hero_logo {
          width: 86px;
        }
        .btn_reserv {
          margin-top: 56px;
          width: 156px;
          height: 48px;

          font-weight: 500;
          font-size: 15px;
          line-height: 150%;

          background-position: 128px 50%;
          background-size: 6px auto;
        }
      }
    }
    // section 2
    .section_summary {
      padding: 60px 0;
      overflow: hidden;
      .desc {
        margin-top: 16px;
      }
      .summary_list {
        width: 100%;
        height: auto;
        margin-top: 40px;
        ul {
          display: block;
          li + li {
            margin: 26px 0 0 0;
          }
          li {
            strong {
              font-size: 22px;
              line-height: 140%;
              margin-top: 8px;
            }
            em {
              margin-top: 7px;
              font-weight: 600;
              font-size: 14px;
              line-height: 150%;
              .desktop_br {
                display: none;
              }
            }
            i {
              width: 72px;
              height: 52px;
              transform: scale(0.9);
            }
            > span {
              font-size: 11px;
              line-height: 140%;
              margin-top: 4px;
              color: #9b9b9b;
            }
          }
        }
      }
    }
    // section 3
    .section_battery {
      height: 552px;
      padding: 80px 0 160px;
      .desc {
        font-size: 15px;
      }
      .inner_box {
        min-height: auto;
      }
    }

    // section 4
    .section_battery_lfp {
      height: auto;
      padding-bottom: 140px;
      background: #eceff2;
      .tit2 {
      }
      .desc {
        font-size: 15px;
        line-height: 145%;
      }

      .swiper {
      }
      .section_battery_swiper {
        margin-top: 40px;
        .swiper-wrapper {
          .section_battery_slide {
            width: 296px;
            height: 221px;
            padding: 24px;
            & + li {
              margin-left: 16px;
            }
            h2 {
              // font-size: 26px;
              // line-height: 125%;
            }
            em {
              // font-size: 16px;
              // line-height: 145%;
            }
            // // p {
            // //   font-size: 14px;
            // //   line-height: 150%;
            //   span {
            //   }
            // }
          }
        }
      }
      // .img_battery_lfp {
      //   width: 387px;
      //   height: 253px;
      //   background: url(../../images/introduce/mobile/img_battery2.png) no-repeat 0 0;
      //   bottom: -57%;
      //   right: -176px !important;
      //   top: 0;
      //   z-index: 100;
      // }

      .section_battery_slide {
        h2 {
          font-size: 26px !important;
          line-height: 125%;
        }
        em {
          font-size: 16px !important;
        }
        p {
          font-size: 14px !important;
        }
      }
    }

    // section 5
    .section_protect {
      .protect_list_box {
        margin-right: -24px;
        padding-right: 24px;
        margin-left: -24px;
        padding-left: 24px;
        overflow-x: auto;
        .protect_list {
        }
      }
      .protect_list {
        width: 650px;
        li {
          .protect_movie {
            height: 166px;
          }
          .protect_desc {
            padding: 24px;
            b {
              font-size: 18px;
              line-height: 145%;
            }
            p {
              font-size: 14px;
              line-height: 140%;
            }
          }
        }
        li + li {
          margin-left: 16px;
        }
      }
    }

    // section 6
    .section_visual {
      .visual_slider {
        .text_area {
          .btn_txt {
            padding: 10px;
          }
        }
      }
    }

    //section7,8 : interior
    .section_interior {
      .inner_box {
      }

      .interior_list {
        padding-left: 24px;
        padding-right: 24px;
        dt {
          width: 100%;
          margin-top: 60px;
          strong {
            font-size: 26px;
            line-height: 125%;
          }
          em {
            font-size: 15px;
            margin-top: 16px;
          }
        }
        dd {
          width: 360px;
          height: 380px;
          font-size: 18px;
        }
        em {
          font-size: 16px;
          line-height: 145%;
        }

        .interior_desc {
          padding: 24px;
          strong {
            font-size: 18px;
            margin-bottom: 6px;
          }
          em {
            font-size: 14px;
          }
        }
      }
    }

    .section_smartpad {
      padding: 80px 0;
      text-align: center;
      .inner_box {
        .notice {
          margin-top: 24px;
          font-size: 12px;
        }
      }
    }
    //section 10
    .section_navi {
      .section_navi_movie {
      }
      .logo_navi {
        margin-top: 15px;
        .ico_t {
          width: 35px;
          height: 35px;
          background-size: 100% 100%;
        }
        .ico_nugu {
          width: 98px;
          height: 35px;
          background-size: 100% 100%;
        }
      }
      .desc {
      }
    }
    // section 11
    .section_driving {
      padding: 80px var(--grid-margin);
      // padding-top: 0px;
      // align-items: start;
      .inner_box {
        margin-top: 0;
      }
      .driving_inner_box {
        display: block;
        .tit {
          padding-left: 0;
          margin-bottom: 30px;
        }
        .spec_list {
          width: 100%;
          li {
            height: auto;
            strong {
              font-size: 12px;
            }
            span {
              font-size: 30px;

              small {
                font-size: 18px;
              }
              p {
                width: 69%;
                left: 31%;
              }
            }
          }
          li + li {
            padding-top: 24px;
            margin-top: 24px;
          }
        }
      }
    }
    // section 12
    .section_feature {
      .tit {
        margin-top: 0px;
        margin-bottom: 18px;
        padding: 0;
      }
      .subhead2 {
        font-size: 18px;
      }
      .swiper-wrapper {
      }
      .swiper-slide {
        width: 296px;
      }
      @for $i from 1 to 8 {
        .img_feature#{$i} {
          background: url('../../images/introduce/pc/img_feature' + $i + '.jpg');
          background-size: cover;
        }
      }

      .feature_slider {
      }

      .swiper-pagination {
        display: flex;
        bottom: 0;
        .swiper-pagination-bullet {
          flex: auto;
          height: 4px !important;
          margin: 0;
        }
        .swiper-pagination-bullet-active {
          background: #111 !important;
        }
      }
    }
    // section 13
    .section_color {
      .inner_box {
        .subhead2 {
          font-size: 18px;
        }
      }
      .color_chip {
        margin-top: 36px;
      }
      .iframe-box {
        // width: 100%;
        // height: 200px;
      }
      .color_chip button {
        width: 28px;
        height: 28px;
      }
    }
    // section 14

    .tab_content {
      .img_wrap {
        &.design-front {
          background: #747883;
        }
        &.design-rear {
          background: #7f8690;
        }
      }
      .btns_group {
        .btn_plus {
          width: 32px;
          height: 32px;
          background-size: 32px 32px;
        }
      }
    }

    .section_design {
    }

    .detail_layer_content {
      .flex-right {
        .tit {
          font-size: 24px;
        }
      }
    }

    // section15
    .section_space {
      background: #000;
      overflow: hidden;
      .tab_content {
        .img_wrap {
          &.space-dashboard {
            background: #1e1f21;
          }
          &.space-console {
            background: #070b0e;
          }
          &.space-seat {
            background: #101113;
          }
        }
      }
      .btns_group {
        // width: 198vh;
        // todo ?? why..
      }
    }
    // section16
    .section_merit {
      // padding-top: 190px;
      .img_truck {
        // margin-top: 80px;
        // width: 100%;
        // height: 226px;
        // background-size: contain;
        // background-position: 50% 50%;
      }
      .img_truck_1 {
        // background: url(../../images/introduce/mobile/img_truck_1.jpg) center center;
        // background-size: cover;
      }
      .img_truck_2 {
        // background: url(../../images/introduce/mobile/img_truck_2.jpg) center center;
        // background-size: cover;
      }
    }
  }
}

@keyframes btnBeforeAnimate {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(0.25);
    opacity: 0.5;
  }

  100% {
    transform: scale(0.75);
  }
}

@keyframes btnAfterAnimation {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 0.2;
  }
  100% {
    transform: scale(0.5);
    opacity: 1;
  }
}
