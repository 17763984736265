.guide {
  .visual {
    // background: url('../../images/img/img_visual_guide.jpg') no-repeat center center;
    // background-size: cover;
    .bg_img {
      background: url(../../images/img/img_visual_guide.jpg) no-repeat 50%;
      background-size: cover;
    }
    > .form_wrap {
      margin-top: 20px;
    }
  }
  .card_layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
  }
  .table_wrap {
    table {
      thead {
        th + th {
          border-left: 1px solid $color_gray8;
        }
        .diagonal_layout + th {
          border-left: 0;
        }
      }
      tbody {
        td + td {
          border-left: 1px solid $color_gray8;
        }
        [rowspan] + td {
          border-left: 0;
        }
      }
    }
    .com {
      justify-content: start;
    }
  }
  [data-cont] {
    display: none;
    &.active {
      display: block;
    }
  }
  .info_guide {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 56px;
    dt {
      @include typo_title1;
      word-break: keep-all;
    }
  }
  .dummy_con_box {
    padding: 64px 0;
    background: #f6f7f9;
    text-align: center;
    border-top: 1px solid #000;
    strong {
      font-weight: 700;
      font-size: 28px;
      line-height: 145%;
      color: #111;
    }
    span {
      margin-top: 16px;
      display: block;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      color: #4f555e;
    }
  }

  .guide_wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .card_cont3 {
      width: 496px;
    }
  }

  @media (max-width: 1620px) {
    .guide_wrap {
      max-width: 1012px;
      margin: 0 auto;

      .card_cont3 {
        width: 496px;
      }
    }
  }

  @media (max-width: 1098px) {
    .guide_wrap {
      max-width: 496px;
      gap: 16px;
    }
  }

  //   tablet
  @media screen and (min-width: 769px) and (max-width: 1080px) {
    .card_layout {
      grid-template-columns: 1fr;
    }
    .pc_view {
      display: block !important;
    }
  }

  // mobile
  @media screen and (max-width: 768px) {
    .guide_wrap {
      max-width: 100%;
      .card_cont3 {
        width: 100%;
      }
    }
    .visual {
      // background: url('../../images/img/mo_img_visual_guide.jpg') no-repeat center center;
      // background-size: cover;
      .bg_img {
        background: url(../../images/img/mo_img_visual_guide.jpg) no-repeat 50%;
        background-size: cover;
      }
    }
    .tab-head {
      margin-top: 32px;
    }
    .card_layout {
      grid-template-columns: 1fr;
    }
    .dummy_con_box {
      strong {
        font-size: 18px;
        line-height: 145%;
      }
      span {
        font-size: 14px;
        line-height: 150%;
      }
    }
  }
}
