// popup
.popup_wrap {
  .replace_page {
    .cont {
      .tlt {
        @include typo_title1;
        font-weight: 700;
        & + [class*='list'],
        & + .sm_tlt {
          margin-top: 12px;
        }
      }
      & + .cont {
        margin-top: 20px;
      }
      .sm_tlt {
        & + [class*='list'] {
          margin-top: 12px;
        }
      }
      .list_dl3 {
        dt {
          @include typo_body1;
          font-weight: 600;
        }
      }
      @media screen and (max-width: 768px) {
        .tlt {
          font-size: 18px;
          line-height: 135%;
        }
      }
    }
  }
}
.replace_page {
  .ver_wrap {
  }
  .cont {
    & + .cont {
      margin-top: 60px;
    }
    .tlt {
      @include typo_header4;
      & + [class*='list'] {
        margin-top: 16px;
      }
      & + .sm_tlt {
        margin-top: 16px;
      }
    }
    .sm_tlt {
      color: $color_black;
      & + [class*='list'] {
        margin-top: 16px;
      }
    }
  }
  .txt_wrap {
    @include center;
    padding: 0 24px;
    width: 100%;
    text-align: center;
    .tlt {
      @include typo_header2;
      color: $color_black;
      & + .desc {
        margin-top: 40px;
      }
    }
    .desc {
      color: $color_black;
      & + [class*='btn'],
      & + .info_box {
        margin-top: 40px;
      }
    }
    .info_box {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 24px;
      margin: 0 auto;
      width: 412px;
      border: 1px solid $color_gray8;
      border-radius: 4px;
      dt {
        width: 60px;
        color: $color_gray5;
      }
      dd {
        width: calc(100% - 68px);
        text-align: left;
        color: $color_black;
      }
    }
  }
  &.no_page,
  &.access,
  &.system_fix,
  &.open {
    position: relative;
    z-index: 0;
    margin-top: -88px;
    height: 100vh;
    .txt_wrap {
      .tlt {
        color: $color_black;
      }
      .desc {
        color: $color_black;
      }
    }
  }
  &.access,
  &.no_page,
  &.system_fix {
    margin-top: 0;
  }
  &.no_page {
    background: url('../../images/img/img_error_bg1.png') no-repeat center center;
    background-size: cover;
  }
  &.access {
    background: url('../../images/img/img_error_bg2.png') no-repeat center center;
    background-size: cover;
  }
  &.system_fix {
    background: url('../../images/img/img_error_bg3.png') no-repeat center center;
    background-size: cover;
  }
  &.open {
    margin-top: 0;
    background: url('../../images/img/img_error_bg4.png') no-repeat center center;
    background-size: cover;
    .txt_wrap {
      .tlt {
        color: $color_white;
      }
      .desc {
        color: $color_white;
      }
    }
  }

  //   mobile
  @media screen and (max-width: 768px) {
    &.no_page,
    &.access,
    &.system_fix,
    &.open {
      margin-top: -56px;
    }
    &.no_page {
      background: url('../../images/img/mo_img_error_bg1.png') no-repeat center center;
      background-size: cover;
    }
    &.access {
      background: url('../../images/img/mo_img_error_bg2.png') no-repeat center center;
      background-size: cover;
    }
    &.system_fix {
      background: url('../../images/img/mo_img_error_bg3.png') no-repeat center center;
      background-size: cover;
    }
    &.open {
      background: url('../../images/img/mo_img_error_bg4.png') no-repeat center center;
      background-size: cover;
    }
    .txt_wrap {
      .tlt {
        font-size: 30px;
        line-height: 125%;
        & + .desc {
          margin-top: 20px;
        }
      }
      .desc {
        @include typo_body2;
        line-height: 165%;
        & + [class*='btn'] {
          margin-top: 80px;
        }
      }
      [class*='btn'] {
        padding: 12px 16px;
        span {
          @include typo_body1;
        }
      }
      .info_box {
        flex-direction: column;
        width: 100%;
        dt,
        dd {
          @include typo_body2;
          width: 100%;
          text-align: left;
          line-height: 165%;
        }
        dd {
          margin-top: 4px;
          font-weight: 600;
          & + dt {
            margin-top: 12px;
          }
        }
      }
    }
    .cont {
      & + .cont {
        margin-top: 40px;
      }
      .tlt {
        font-size: 18px;
        line-height: 135%;
        & + [class*='list'] {
          margin-top: 12px;
        }
        & + .sm_tlt {
          margin-top: 12px;
        }
      }
      .sm_tlt {
        @include typo_body2;
        line-height: 165%;
      }
    }
  }
}
