.part {
  .visual {
    // background: url('../../images/img/img_visual_part.jpg') no-repeat center center;
    // background-size: cover;
    .bg_img {
      background: url(../../images/img/img_visual_part.jpg) no-repeat 50%;
      background-size: cover;
    }
  }
  .table_wrap {
    table {
      th,
      td {
        -webkit-text-size-adjust: none;
        padding: 12px 0;
        white-space: nowrap;
      }
    }
  }
}
// Service Network
.newwork {
  .visual {
    margin-bottom: 20px;
    padding-bottom: 0;
  }
  .tab-head {
    margin-bottom: 0;
  }
  & > .inner {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    max-width: none;
  }
  .relation_wrap.inner {
    // padding-bottom: 56px;
    // max-width: 1608px;
  }
  .popup_wrap {
    .pop_l_inner {
      width: 100% !important;
      max-width: 1200px;
    }
    .pop_cont {
      min-height: calc(100vh - 100px);
    }
  }
}
// map
.map_wrap {
  .readonly {
    border-color: #c7c7c7;
    background-color: #f4f4f4;
  }
  .map_inner {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 800px;
    //border-top: 1px solid $color_gray8;
    border-bottom: 1px solid $color_gray8;
  }
  .info {
    padding: 24px 40px;
    background: #fafafa;
    li {
      color: #727272;
    }
  }
  .search_bar {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 436px;
    height: 100%;
    background: #fff;
    .top {
      display: none;
      flex-direction: column;
      gap: 10px 0;
      padding: 20px;
      background-color: $color_gray10;
      [class*='btn'] {
        width: 70px;
      }
      &.active {
        display: flex;
      }
    }
    .btm {
      @include scroll_custom;
      height: calc(100% - 134px);
      max-height: 670px;
      overflow-y: auto;
      background-color: $color_white;
      text-align: center;
      .search_list {
        text-align: left;
        .list {
          @include scroll_custom;
          padding: 24px 24px 32px;
          border-bottom: 1px solid $color_gray8;
          .tlt_wrap {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
            .tlt {
              @include typo_title1;
              font-weight: 700;
            }
          }
          .com {
            .go_to {
              cursor: default;
            }
            [class*='btn_com'] + .go_to {
              margin-left: 4px;
            }
          }
          &.pr0 {
            overflow-y: auto;
            // padding-right: 0;
            // max-height: 180px;
          }
          .adress {
            margin-top: 8px;
          }
          .tel {
            display: inline-block;
            margin-top: 4px;
            width: 100%;
          }
          .button-group {
            display: flex;
            justify-content: space-between;
            margin: 24px 0 0 0;
            gap: 8px;
            > * {
              flex: auto;
              // m도 s도 아닌 height 40px
              display: flex;
              align-items: center;
              justify-content: center;
              height: 40px;
            }
            .btn_tertiary_s {
              // todo...
              height: 40px;
              background: #ffffff;
              border: 1px solid #d5d8db;
              border-radius: 4px;
              color: #4f555e;
            }
            .btn_primary_s,
            .btn_second_s {
            }
          }
        }
        > p {
          padding: 60px 0;
          text-align: center;
        }
      }
      > [class*='btn_txt'] {
        margin-top: 20px;
        margin-bottom: 28px;
      }
    }
  }
  .map_item {
    position: relative;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: $color_gray5;
  }
  .list_open {
    position: sticky;
    left: 50%;
    bottom: 24px;
    z-index: 1;
    transform: translateX(-50%);
    padding: 8px 12px;
    border-radius: 50px;
    background-color: rgba($color: $color_white, $alpha: 0.8);
    box-shadow: 0px 2px 6px rgba($color_black, 0.1);
    transition: position 0.2s ease-in-out right 0.2s ease-in-out;

    margin: -16px 0 16px;
    span {
      @include typo_body2;
      color: $color_gray4;
    }
    &.move {
      position: absolute;
      bottom: -18px;
    }
  }
  //   popup
  .popup_wrap [class*='pop'][class*='inner'][class*='l'] .pop_cont {
    max-height: none;
    .tab_cont {
      @include scroll_custom;
      margin-top: 40px;
      overflow-y: auto;
    }
  }
  // map popup
  .tab_list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0 8px;
    .list {
      padding-bottom: 6px;
      font-weight: 600;
      color: #4f555d;
      &.active {
        color: $color_primary;
        border-bottom: 2px solid $color_primary;
      }
    }
    & + .tab_cont {
      margin-top: 40px;
    }
  }

  .tab_cont {
    min-height: 505px;
    [data-cont='detail'] {
      flex-wrap: wrap;
      gap: 0 40px;
      max-height: 505px;
      .img_wrap {
        overflow: hidden;
        width: 390px;
        height: 100%;
        min-height: 202px;
        border-radius: 8px;
        background: url(../../images/img/img_empty.png) no-repeat 50%;
        background-size: cover;
        img {
          object-fit: cover;
        }
      }
    }
    [data-cont='person'] {
      flex-wrap: wrap;
      gap: 20px;
      max-height: 505px;
      > li {
        width: calc(50% - 20px);
      }
      .img_wrap {
        overflow: hidden;
        min-height: 105px;
        background: url(../../images/img/img_pop_person.png) no-repeat 50%;
        background-size: cover;
        img {
          object-fit: cover;
        }
      }
    }
    .cont {
      display: none;
      .img_wrap + .detail_info {
        padding: 20px 0 0 0;
        width: calc(100% - 430px);
        border-top: 2px solid #222e59;
      }
      .detail_info {
        .tlt {
          @include typo_title1;
          font-weight: 700;
        }
        .sub_tlt {
          margin-top: 10px;
          font-weight: 600;
          & + .list_dl1 {
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #d5d8db;
          }
        }
      }
      &.active {
        display: flex;
      }
    }
  }
  //   tb
  @media screen and (max-width: 1024px) {
  }

  //   mobile
  @media screen and (max-width: 768px) {
    .map_inner {
      height: auto;
      border: 0;
    }
    .search_bar {
      position: static;
      margin-bottom: 32px;
      width: 100%;
      .top {
        padding: 0;
        background-color: transparent;
      }
      .btm {
        margin: 0 -24px;
        height: auto;
        max-height: none;
        .search_list {
          .list {
            .tlt_wrap {
              .tlt {
                font-size: 20px;
                font-weight: 700;
                line-height: 160%;
              }
            }
            .adress {
              @include typo_body2;
              margin-top: 8px;
              line-height: 165%;
            }
            .tel {
              @include typo_body2;
              margin-top: 0;
              line-height: 165%;
            }
          }
        }
        &.hide {
          display: none;
        }
        &.active {
          display: block;
        }
      }
    }
    // .map_item {
    //   min-height: 540px;
    //   &.hide {
    //     display: none;
    //   }
    //   &.active {
    //     display: block;
    //   }
    // }

    // popup
    .tab_list {
      position: sticky;
      justify-content: flex-start;
      margin-top: 32px;
      .list {
        font-weight: 500;
      }
      & + .tab_cont {
        margin-top: 94px;
        min-height: auto;
      }
    }
    .tab_cont {
      overflow-y: auto;
      padding-bottom: 60px;
      [data-cont='detail'] {
        max-height: none;
        .img_wrap {
          & + .detail_info {
            border-top: none;
            width: 100%;
          }
        }
      }
      [data-cont='person'] {
        flex-direction: column;
        flex-wrap: nowrap;
        max-height: none;
        > li {
          width: 100%;
        }
      }
      .cont {
        .detail_info {
          .tlt {
            font-size: 18px;
          }
          .sub_tlt {
            margin-top: 18px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .newwork {
    .visual {
      padding-left: 24px;
      padding-right: 24px;
    }
    .tab-head {
      margin-bottom: 32px;
    }
    .inner {
    }
    .map_wrap {
      padding-left: 24px;
      padding-right: 24px;
    }
    .relation_wrap.inner {
      padding-left: 24px;
      padding-right: 24px;
    }
    .popup_wrap {
      align-items: flex-start;
      [class*='pop'][class*='inner'] {
        .pop_cont {
          position: static;
          padding: 6.667vw !important;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) and (max-width: 1080px) {
  .newwork {
    .map_wrap {
      .tab_cont {
        margin-top: 24px !important;
        font-size: 15px;
        [data-cont='detail'] {
          flex-direction: column;
          flex-wrap: nowrap;
          gap: 32px 0;
          .img_wrap {
            width: 100%;
            & + .detail_info {
              width: 100%;
            }
          }
        }
        [data-cont='person'] {
          > li {
            width: 100%;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .newwork {
    .map_wrap {
      .tab_cont {
        // margin-top: 100px !important;
      }
    }
  }
}

// PartsLogistics
.part { overflow-x:hidden;}
.PartsLogistics{
  .title_wrap{
    .tlt{
      font-weight:700;
      font-size: 48px;
      line-height: 135%;
      color: $color_black;
      word-break: keep-all;
    }
    p{
      font-size:20px; 
      line-height:1.5;
      margin-top:16px;
      margin-bottom:30px;
    }
  }
  
  .card_wrap {
    .card {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;
      margin-top:24px;
      .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 35px 32px 100px;
        width: calc(50% - 10px);
        border-radius: 8px;
        background: #f6f7f9;           
        &:nth-child(3) {
          width: 100%; 
          padding-bottom:35px;
        }
        @for $info from 1 to 3 {
          &:nth-child(#{$info}) {
            background: #f6f7f9
              url('../../images/ico/ico_parts#{$info}.png')
              no-repeat
              right
              30px
              bottom
              34px;
            background-size: 90px;
          }
        } 
        .tlttle {
          font-size:28px;
          font-weight: 700;
          line-height: 160%;
          color: $color_black;
        }
        .desc{
          margin-top: 4px;
          font-size: 18px;
          line-height: 160%;
          color: $color_gray5;
        }
        .partsCenterImg{
          margin-top:10px;
          display:flex;
          flex-wrap:wrap;
          gap:2px;
          .imgBox{
            aspect-ratio: 365/200;
            width:calc(25% - 6px/4);
            background: #fff;
            
            @for $i from 1 through 4 {
              &:nth-child(#{$i}) {
                background-image: url('../../images/img/parts_center#{$i}.jpg');
                background-size: cover; 
                background-position: center; 
              }
            }
          }
        }
      }
           
    }
  }

  .partsCenterMovie{
    margin-top:120px;
    .partsCenterVideo{
      width:100%;      
      .videoBox { 
        position:relative;
        aspect-ratio: 1280/720;
        width:100%;
        video { width:100%; height:100%; }
      }
    }
  }

  .partsCenterWrap{
    margin-top:120px;
    .partsCenter{
      display:flex;
      flex-wrap:wrap;
      align-items:center;
      
      .partsCenterImg{
        width:50%;
        aspect-ratio: 764/460;        
      }

      @for $info from 1 to 4 {
        &:nth-child(#{$info}) .partsCenterImg {
          background:
            url('../../images/parts/partsCenter_img0#{$info}.jpg')
            no-repeat
            center;
          background-size: cover;
        }
      } 

      &:nth-child(2){
        .partsCenterImg{
          order:2;
        }        
      }

      .partsCenterInfo {
        width:50%;
        padding-left:120px;
        strong{
          font-size: 36px;
          font-weight: 700;
          line-height: 160%;
          color: $color_black;
        }
        p{
          margin-top: 20px;
          font-size: 18px;
          line-height: 160%;
          color: $color_gray5;
        }
      }
    }
  }

  .partsBuyInquiry{
    margin-top:120px;

    &.cont_information {
      .scroll_wrap {
        .scroll_cont1 {          
          .item {
            .item_img {
              width: 328px;
              height: 164px;
            }
            .step1 {
              background: url('../../images/img/img_buyguide_info1.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step2 {
              background: url('../../images/img/img_buyguide_info2.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step3 {
              background: url('../../images/img/img_buyguide_info3.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step4 {
              background: url('../../images/img/img_buyguide_info4.jpg') no-repeat center center;
              background-size: 328px auto;
            }
            .step5 {
              background: url('../../images/parts/img_partsbuyguide_info5.jpg') no-repeat center center;
              background-size: 328px auto;
            }
          }
        }
      }
    }

    // 스크롤컨텐츠
  .scroll_wrap {
    margin-top: 24px;

    .swiper {
      padding-right: 27%;
      overflow: visible;
    }

    .swiper-slide {
      width: 328px;
      width: fit-content;
    }
    
    .scroll_cont1 {
      gap: 24px;
      overflow: visible;
      .swiper-wrapper {
        max-width: 1528px;
        margin: 0px auto;
        gap: 24px;
      }
      .item {
        width: 328px;
        .item_img {
          display: block;
          width: 100%;
          height: 164px;
          background-size: contain;
          border-radius: 4px;
        }
        .tex_wrap {
          position: relative;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .tlt {
          & + .desc {
            margin-top: 0;
          }
          font-weight: 700;
          font-size: 22px;
          line-height: 160%;
          color: #111;
          span {
            display: block;
            font-weight: 600;
            font-size: 16px;
            line-height: 160%;
          }
        }
        .desc {
          font-size: 16px;
          line-height: 150%;
          color: #4f555d;
          br {
            display: none;
          }
        }
        
      }
    }
  } 

  }  
}

@media screen and (max-width: 1080px) {
  .partsBuyInquiry { 
    .pc_view{
      display:block !important;
    }    
  }
} 

// PartsLogistics_mobile
@media screen and (max-width: 768px) {
  .PartsLogistics{
    .title_wrap{
      .tlt{
        font-size: 24px;
        line-height: 140%;
      }
      p{
        font-size:16px; 
        margin-top:10px;
        margin-bottom:20px;
      }
    }

    .card_wrap {
      .card {
        .item {   
          padding:24px 24px 70px;   
          width:100%;    
          .tlttle {
            font-size:18px;
          }
          .desc{
            font-size: 16px;
          }
          .partsCenterImg{
            gap:2px;
            .imgBox{
              aspect-ratio: 365/200;
              width:calc(50% - 1px);
            }
          }
        }
        @for $info from 1 to 3 {
          .item:nth-child(#{$info}) {
            background: #f6f7f9
              url('../../images/ico/ico_parts#{$info}.png')
              no-repeat
              right
              24px
              bottom
              24px;
            background-size: 60px;
          }
        }
      }
    }

    .partsCenterMovie{
      margin-top:60px;
      .partsCenterVideo{
        width:100%;      
        .videoBox { 
          position:relative;
          aspect-ratio: 1280/720;
          width:100%;
          video { width:100%; height:100%; }
        }
      }
    }

    .partsCenterWrap{
      margin-top:60px;
      .partsCenter{   
        &:not(:first-child){
          margin-top:30px; 
        }           
        .partsCenterImg{
          width:100%; 
          order:2;   
          aspect-ratio: 764/360;
        }  

        .partsCenterInfo {
          width:100%;
          padding-left:0px;
          strong{
            font-size: 16px;
            line-height: 145%;
          }
          p{
            margin-top: 10px;
            font-size: 14px;
            line-height: 165%;
            margin-bottom:10px;
          }
        }
      }
    }


    .partsBuyInquiry{  
      .pc_view{
        display:none !important;
      }         
      margin-top:60px; 
      &.cont_information {        
         .scroll_wrap {
           .scroll_cont1 {
             .item {
               .item_img {
                 width: 100%;
                 height: 140px;
                 background-size: 100%;
               }
             }
           }
           .scroll_cont1 {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            gap: 24px;
            margin-top: 16px;            
            .item {
              width: 100%;
              img {
                width: 100%;
                max-height: 140px;
                object-fit: cover;
              }
              .tex_wrap {
                margin-top:16px;
                border-bottom: 0;
              }
              .tlt {
                font-size: 16px;
                line-height: 145%;
              }
              .desc {
                margin-top: 0;
                font-size: 14px;
                line-height: 165%;
                br {
                  display: block;
                }
              }              
            }
          }
         }

      }
    }



  }
} 