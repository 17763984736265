@media screen and (max-width: 768px) {
  .popup_wrap [class*='pop'][class*='inner'][class*='l'] .pop_cont {
    max-height: none !important;
    height: calc((var(--vh, 1vh) * 100) - 120px);
  }
  .popup_wrap [class*='pop'][class*='inner'][class*='m'] .pop_cont {
    height: calc((var(--vh, 1vh) * 100) - 56px);
  }
  .popup_wrap.reserve .conf_inner .conf_cont {
    height: calc((var(--vh, 1vh) * 100) - 269px) !important;
  }
  .react-player {
    height: calc(var(--vh, 1vh) * 100) !important;
  }
  .map_wrap .tab_cont {
    max-height: calc((var(--vh, 1vh) * 100) - 152px) !important;
  }

  /* estimatebar */
  .estimatebar .detail.active {
    overflow-y: auto;
    max-height: calc((var(--vh, 1vh) * 100) - 400px) !important;
  }
}
