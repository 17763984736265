.AlartPopUp_wrap {
  .conf_inner {
    .conf_cont {
      .tlt {
      }
      .desc {
      }
    }
  }
}
.popup_wrap {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  bottom: 0px;
  &.AlartPopUp_wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    .conf_inner {
      .conf_cont {
        .tlt {
          font-weight: 700;
          font-size: 18px;
          line-height: 145%;
        }
        .desc {
          font-weight: 500;
          font-size: 15px;
          line-height: 150%;
        }
      }
    }
  }
  .dim {
    // @include center;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba($color: $color_black, $alpha: 0.5);
  }
  .conf_inner,
  [class*='pop'][class*='inner'] {
    //@include center;
    position: relative;
    z-index: 1;
    margin: 80px auto;
    background-color: $color_white;
    border-radius: 8px;
  }
  //   confirm
  .conf_inner {
    width: 320px;
    &.notice {
      width: 440px;
      .conf_cont {
        height: 550px;
        > img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .conf_header {
      padding: 12px 16px;
      text-align: right;
      & + .conf_cont {
        padding-top: 0;
      }
    }
    .conf_cont {
      padding: 32px 20px;
      text-align: center;
      line-height: 150%;
      .sub_visual {
        overflow: hidden;
        margin: 0 auto;
        width: 72px;
        height: 72px;
        border-radius: 50%;
        img {
          height: 100%;
          object-fit: cover;
        }
        & + .tlt {
          margin-top: 24px;
        }
      }
      .tlt {
        font-weight: 600;
        & + .desc {
          margin-top: 8px;
        }
      }
      .desc {
        color: $color_gray3;
      }
    }
    .conf_footer {
      padding-bottom: 32px;
      text-align: center;
      .btn_layout {
        gap: 8px;
        [class^='btn'] {
          min-width: 90px;
        }
      }
    }

    // notice
    &.notice {
      overflow: hidden;
      .conf_cont {
        padding: 0;
      }
      .conf_footer {
        display: flex;
        justify-content: space-between;
        padding: 16px 20px;
        .close_ck {
          input {
            vertical-align: middle;
            margin-right: 8px;
            width: 20px;
            height: 20px;
            background: url('../../images/ico/ico_input_check.png') no-repeat center center;
            background-size: 20px;
            &:checked {
            }
          }
        }
      }
    }
  }
  //   popup
  [class*='pop'][class*='inner'] {
    .pop_header {
      display: flex;
      justify-content: space-between;
      padding: 18px 40px;
      .tlt {
        font-size: 18px;
        font-weight: 700;
        line-height: 145%;
        color: $color_black;
      }
      button {
        margin-right: -0.5rem;
        padding: 0.25rem;
      }
    }
    .pop_cont {
      @include scroll_custom;
      overflow-y: auto;
      padding: 0 40px 40px;
      color: $color_gray15;
      line-height: 150%;
      // 할부 선택
      &.plan {
        .tlt_wrap {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding: 24px 20px;
          background-color: $color_bgray10;
          border-radius: 4px;
          .tlt {
            font-weight: 700;
            span {
              @include typo_body2;
              margin-left: 8px;
              color: $color_gray5;
            }
          }
          .price {
            @include typo_title1;
            color: $color_primary;
            font-weight: 700;
            span {
              color: $color_primary;
            }
          }
        }
        .plan_wrap {
          > li {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            gap: 40px 20px;
            padding: 24px 0;
            border-bottom: 1px solid $color_gray8;
            .tlt {
              width: calc(100% - 282px);
              font-weight: 600;
            }
            > .input_wrap {
              display: flex;
              flex-wrap: wrap;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
              position: relative;
              z-index: 0;
              width: 100%;
              // .input_range {
              //   width: 100%;
              //   height: 20px;
              //   &::-webkit-slider-runnable-track {
              //     overflow: hidden;
              //     height: 20px;
              //     border-radius: 3px;
              //     background-color: #ebebeb;
              //   }
              //   &::-webkit-slider-thumb {
              //     position: relative;
              //     z-index: 0;
              //     -webkit-appearance: none;
              //     width: 20px;
              //     height: 20px;
              //     background-color: $color_white;
              //     border: 5px solid $color_primary;
              //     border-radius: 50%;
              //     box-shadow: -100vw 0 0 100vw $color_primary;
              //   }
              //   &:focus {
              //     &::-ms-fill-lower {
              //       height: 6px;
              //       border-radius: 3px;
              //       background-color: red;
              //     }
              //   }
              // }
              .ran_val {
                position: absolute;
                top: -20px;
                padding: 2px 6px;
                font-size: 10px;
                font-weight: 700;
                color: $color_white;
                background-color: $color_bgray4;
                border-radius: 3px;
              }
              span {
                @include typo_body3;
                color: $color_gray5;
              }
            }
            .input_wrap {
              .input_txt {
                padding: 8px 12px 8px 40px;
                width: 200px;
                text-align: right;
              }
              .txt_unit {
                display: inline-block;
                margin-left: 8px;
                flex-shrink: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 150%;
              }
            }
            .price {
              color: $color_primary;
              font-weight: 600;
              span {
                color: $color_primary;
                font-weight: 600;
              }
            }
          }
          > li:nth-of-type(1) {
            border-bottom: 0;
          }
          > li.before {
            justify-content: right;
            column-gap: 16px;
            row-gap: 0;
            margin-top: -18px;
            padding: 0 0 16px;
            .tlt {
              width: auto;
            }
            .price {
              color: #404040;
              span {
                color: inherit;
              }
            }
          }
        }
        .total_wrap {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          margin-top: 28px;
          .total {
            span {
              font-weight: 700;
            }
            span + span {
              position: relative;
              z-index: 0;
              padding-left: 24px;
              &::before {
                @include pseudo;
                @include center_top;
                left: 12px;
                width: 1px;
                height: 12px;
                background-color: $color_gray7;
              }
            }
          }
          .month {
            color: $color_primary;
            font-weight: 700;
            span {
              margin-left: 8px;
              color: $color_primary;
              font-weight: 700;
              font-size: 20px;
            }
          }
        }
      }
      // TCO 계산결과
      &.calctco {
        padding: 0 20px 0;
        min-width: 600px;
        @media screen and (max-width: 768px) {
          padding-top: 90px;
          width: 100%;
          min-width: 0;
        }
        .text_infor {
          font-size: 20px;
          line-height: 160%;
          font-weight: 500;
          color: $color_gray5;
          text-align: center;
          strong {
            display: inline-block;
            margin: -4px 4px 0;
            font-size: 36px;
            font-weight: 700;
            color: $color_black;
            line-height: 100%;
            vertical-align: middle;
            input {
              font-size: 36px;
              font-weight: 700;
              color: $color_black;
              text-align: right;
            }
          }
          @media screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 150%;
            strong {
              font-size: 24px;
            }
          }
        }
        .img_block {
          margin: 0 -20px 0 -20px;
          width: calc(100% + 40px);
          height: 198px;
          background: url(../../images/img/img_buy_tco_resultcalc.png) no-repeat 50%;
          background-size: cover;
          @media screen and (max-width: 768px) {
            // margin-top: 30px;
            // margin-bottom: 30px;
            height: 320px;
            background: url(../../images/img/mo_img_buy_tco_resultcalc.png) no-repeat center center;
            background-size: contain;
          }
        }
        .result_calc {
          position: relative;
          margin: 0;
          padding: 20px 0 0;
          dt {
            margin: 0 0 8px 0;
            font-size: 20px;
            font-weight: 700;
            line-height: 160%;
            color: $color_black;
            [class*='res_won'] {
              margin: 0 3px 3px 0;
            }
          }
          dd {
            &.result_value {
              position: absolute;
              right: 0;
              top: 20px;
              display: flex;
              align-items: center;
              font-size: 20px;
              font-weight: 700;
              line-height: 160%;
              color: $color_black;
              span {
                margin: 0 4px -2px 0;
                font-size: 16px;
              }
            }
            &.result_infor {
              ul {
                li {
                  padding: 0 0;
                  font-size: 14px;
                  font-weight: 400;
                  color: $color_gray5;
                  line-height: 140%;
                }
              }
            }
          }
          & + .result_calc {
            margin-top: 20px;
            border-top: 1px solid $color_gray17;
          }
          @media screen and (max-width: 768px) {
            padding: 20px 0 40px 0;
            dd {
              &.result_value {
                top: auto;
                bottom: 0;
                span {
                  margin: 0 4px -2px 0;
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
      // 완료 화면
      &.complete {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        margin-top: 0;
        width: 600px;
        max-height: none !important;
        text-align: center;
        .tlt {
          display: block;
          // padding-top: 185px;
          margin-top: 54px;
          font-size: 36px;
          font-weight: 700;
          line-height: 135%;
          // background: url('../../images/img/img_done.png') no-repeat center top;
          // background-size: 400px;
        }
        .desc {
          @include typo_title1;
          margin-top: 24px;
          font-weight: 400;
        }
        .conf_footer {
          margin-top: 60px;
          padding: 0;
          width: 100%;
          button {
            width: 100%;
          }
        }
        @media screen and (max-width: 768px) {
          display: flex;
          align-items: center;
          width: auto;
          .tlt {
            width: 100%;
          }
        }
      }
    }
    .pop_footer {
      padding: 16px 24px;
      text-align: right;
      @media screen and (max-width: 768px) {
        padding: 0 16px 120px;
      }
    }
    &[class*='s'] {
      width: 440px;
      .pop_cont {
        min-height: 206px;
        max-height: 526px;
      }
    }
    &[class*='m'] {
      max-width: 600px;
      .pop_cont {
        min-height: 286px;
        max-height: 526px;
      }
    }
    &[class*='l'] {
      max-width: 900px;
      .pop_cont {
        min-height: 172px;
        max-height: 526px;
      }
    }
  }

  .logo_wrap {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 14px;
    gap: 0 48px;

    .logo {
      width: 100px;
      height: 32px;
      background: url('../../images/ico/logo_t4k_01.svg') no-repeat center center;
      background-size: 75px auto;
    }
  }

  // 사전 예약 팝업
  &.reserve {
    justify-content: flex-end;
    .form_wrap {
      .select_layout {
        .select {
          width: 100%;
        }
        // &::after {
        //   @include pseudo;
        //   @include center;
        //   width: 100%;
        //   height: 1px;
        //   background-color: $color_gray3;
        // }
      }
    }
    .conf_inner {
      margin: 0;
      width: 560px;
      top: 0;
      right: 0;
      left: auto;
      height: 100%;
      border-radius: 0;
      transform: none;
      .logo_wrap {
        margin-top: 14px;
        gap: 0 48px;

        .logo {
          width: 100px;
          height: 32px;
          background: url('../../images/ico/logo_t4k_01.svg') no-repeat center center;
          background-size: 75px auto;
        }
      }
      .conf_header {
        padding: 16px 16px 0;
      }
      .conf_cont {
        margin-top: 30px;
        padding: 0 80px 60px;
        min-height: 445px;
        .tlt {
          @include typo_header3;
          line-height: 135%;
        }
        .desc {
          margin-top: 32px;
          .form_wrap + .form_wrap {
            margin-top: 0;
            margin-bottom: 0;
          }
          .form_wrap.line_ty {
            display: block;
            margin-bottom: 7px;
            .err_msg {
              text-align: left;
            }
            .input_wrap {
              align-items: flex-end;
              .label {
                width: 70px;
                height: 68px;
                font-size: 18px;
                color: $color_gray4;
                font-weight: 600;
                text-align: left;
              }
              .label + input,
              .label + .input_select {
                padding: 15px 0;
                font-size: 22px;
                &::placeholder {
                  font-size: 22px;
                }
              }
            }
            .input_select {
              font-size: 22px;
            }
            .select {
              padding: 13px 0;
            }
          }
        }
        .input_checkbox_basic {
          margin-top: 22px;
          label {
            margin-left: -30px;
            padding-left: 30px;
            color: $color_bgray3;
            button {
              color: $color_bgray3;
              text-decoration: underline;
            }
          }
          & + .input_checkbox_basic {
            margin-top: 12px;
          }
        }
        // 완료 화면
        &.complete {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          margin-top: 0;
          text-align: center;
          .tlt {
            padding-top: 185px;
            margin-top: 54px;
            background: url('../../images/img/img_done.png') no-repeat center top;
            background-size: 400px;
          }
          .desc {
            @include typo_title1;
            margin-top: 24px;
            font-weight: 400;
          }
          .conf_footer {
            margin-top: 108px;
            padding: 0;
            width: 100%;
          }
        }
      }
      .conf_footer {
        padding: 0 80px;
        .btn_primary_l {
          width: 100%;
        }
      }
    }
    //Tablet
    @media screen and (max-width: 1024px) {
      .conf_inner .conf_cont .tlt {
        font-size: 30px;
      }
    }
    // mobile
    @media screen and (max-width: 768px) {
      top: 0;
      left: 0;
      transform: none;
      background-color: $color_white;
      .form_wrap {
        .select_layout {
          gap: 0 24px;
          margin-left: 0;
          border-bottom: 1px solid #404040;
          .select {
            padding: 8px 0 !important;
            width: 100% !important;
            .input_select {
              font-size: 18px !important;
            }
            & + .select {
              margin-top: 18px !important;
            }
          }
          &::after {
            top: 45px;
            left: -120px;
            width: calc(100% + 120px);
            transform: none;
          }
          &::before {
            content: '';
            display: block;
            position: absolute;
            bottom: 56px;
            left: -100px;
            right: 0;
            height: 1px;
            background: #404040;
          }
          & + .select {
            padding-top: 30px !important;
          }
        }
        &.line_ty {
          & + .line_ty + .line_ty {
            .input_wrap {
              border: none;
            }
          }
          input {
            font-size: 18px !important;
            font-weight: 400;
            &::placeholder {
              font-size: 18px !important;
            }
          }
          .input_wrap {
            align-items: flex-start !important;
            .label {
              width: 100px !important;
              height: 44px !important;
              font-size: 16px !important;
              font-weight: 400 !important;
            }
            .label + input {
              padding: 10px 0 !important;
            }
          }
        }
      }
      .dim {
        display: none;
      }
      .conf_inner {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        border-radius: 0;
        transform: none;
        .logo_wrap {
          padding: 0;
          margin-top: 4px;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          .logo {
            width: 60px;
            height: 20px;
            background-size: 60px;
          }
        }
        .conf_header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 16px;
          width: 100%;
        }
        form {
          overflow-y: auto;
        }
        .conf_cont {
          overflow-y: auto;
          padding: 0 24px;
          width: 100%;
          height: auto;
          margin-top: 26px;
          .tlt {
            width: 100%;
            font-size: 24px;
            line-height: 125%;
          }
          .desc {
            margin-top: 30px;
            .form_wrap + .form_wrap {
              margin-top: 16px;
            }
          }
          // 완료 화면
          &.complete {
            .tlt {
              padding-top: 184px;
            }
            .desc {
              @include typo_body1;
            }
            .conf_footer {
              padding: 0 24px 24px;
              margin: 0;
            }
          }
        }
        .conf_footer {
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          padding: 0 24px 24px;
          margin: 0;
          width: 100%;
          .btn_primary_l {
            padding: 18px;
          }
          .logo_wrap {
            margin-top: 92px;
          }
        }
      }
    }
  }

  &.cookie_wrap {
    top: auto;
    bottom: 0;
    height: 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  // 쿠키
  .cookie_cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin: 0 auto 40px;
    z-index: 1;
    padding: 24px 40px;
    width: calc(100% - 40px);
    max-width: 970px;
    background-color: $color_white;
    box-shadow: 0px 0px 24px rgba($color: $color_black, $alpha: 0.12);
    border-radius: 4px;
    .desc {
      width: calc(100% - 42px);
      color: $color_black;
      [class*='go_to'] {
        color: $color_black;
        text-decoration: underline;
      }
    }
  }

  // tablet
  @media screen and (max-width: 1024px) {
    .cookie_cont {
      gap: 40px;
      max-width: 610px;
      .desc {
        width: calc(100% - 62px);
      }
    }
  }

  // mobile
  @media screen and (max-width: 768px) {
    // position: absolute;
    background-color: $color_white;
    &.AlartPopUp_wrap,
    &.NoticePopUp_wrap,
    &.cookie_wrap {
      background: transparent;
    }
    &.NoticePopUp_wrap {
      .conf_inner {
        width: 312px;
        .conf_cont {
          height: 390px;
        }
      }
    }
    .conf_inner {
      box-shadow: 0 5px 15px rgba($color: $color_black, $alpha: 0.16);
      .tlt {
        font-size: 18px;
        line-height: 135%;
        color: $color_black;
      }
      .conf_footer {
        padding-bottom: 24px;
        .btn_layout {
        }
      }
    }
    .dim {
      display: none;
    }
    [class*='pop'][class*='inner'][class*='s'],
    [class*='pop'][class*='inner'][class*='m'],
    [class*='pop'][class*='inner'][class*='l'] {
      width: 100%;
      .pop_cont {
        max-height: calc(100vh - 100px); // popup head 임시 100px
        &.plan {
          height: 100%;
          margin-top: 0;
          overflow: auto;
          padding-top: 130px;
        }
      }
    }
    [class*='pop'][class*='inner'] {
      position: static;
      transform: none;
      margin: 0;
      .pop_header {
        display: flex;
        align-items: center;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0 24px;
        width: 100%;
        height: 56px;
        @media screen and (max-width: 768px) {
          strong {
            font-weight: 700;
            font-size: 24px !important;
            line-height: 140%;
          }
        }
      }
      .pop_cont {
        position: absolute;
        max-height: none;
        left: 0;
        right: 0;
        top: 57px;
        bottom: 76px;
        padding: 24px 24px !important;
        height: auto !important;
        &.plan {
          .tlt_wrap {
            padding: 16px 20px;
            .tlt {
              @include typo_body2;
              font-weight: 700;
              line-height: 165%;
              display: flex;
              flex-wrap: wrap;
              flex-direction: column;
              span {
                @include typo_body3;
                margin-left: 0;
              }
            }
            .price {
              @include typo_body1;
              font-weight: 700;
            }
          }
          .plan_wrap {
            > li {
              gap: 50px 0;
              padding: 16px 0;
              .tlt {
                @include typo_body2;
                width: 82px;
                font-weight: 600;
              }
              .form_wrap {
                width: calc(100% - 82px);
              }
              .input_wrap {
                .ran_val {
                  top: -30px;
                }
                .input_txt {
                  width: calc(100% - 36px);
                }
                .txt_unit {
                  display: inline-block;
                  margin-left: 8px;
                  flex-shrink: 0;
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 150%;
                }
              }
            }
            > li.before {
              column-gap: 8px;
              padding-top: 8px;
            }
          }
          .total_wrap {
            gap: 20px 0;
            padding-bottom: 24px;
            margin-top: 16px;
            border-bottom: 1px solid $color_gray8;
            .total {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              width: 100%;
              span {
                @include typo_body2;
                font-weight: 600;
              }
              span + span {
                &::before {
                  display: none;
                }
              }
            }
            .month {
              display: flex;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }
          }
          & + .pop_footer {
            [class*='btn'] {
              width: 100%;
            }
          }
        }
        &.nofoot {
          bottom: 0;
        }
      }
      .pop_footer {
        position: fixed;
        left: 0;
        bottom: 0;
        padding: 4px 24px 24px;
        width: 100%;
        background: $color_white;
        .btn_layout {
          display: flex;
          justify-content: space-between;
          [class^='btn'] {
            width: 100%;
          }
        }
        [class^='btn'] {
          width: 100%;
        }
      }
    }

    // 쿠키
    .cookie_cont {
      gap: 20px;
      width: 100%;
      max-width: none;
      left: 0px;
      bottom: 0;
      margin: 0;
      flex-direction: column;
      align-items: flex-end;
      padding: 20px 24px;
      border-radius: 0;
      [class*='btn'] {
        order: 1;
      }
      .desc {
        @include typo_body2;
        order: 2;
        width: 100%;
        line-height: 165%;
      }
    }
  }
}

.NoticePopUp_wrap .swiper-button-prev {
  width: 42px;
  left: 0;
}
.NoticePopUp_wrap .swiper-button-next {
  width: 42px;
  right: 0;
}

.NoticePopUp_wrap .swiper-button-prev:after,
.swiper-button-next:after {
  width: 56px;
  height: 56px;
  background-size: 56px;
  opacity: 0.5;
}


