.inner {
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
  max-width: 1608px;
}
.content {
  .inner {
    padding-top: 140px;
    padding-bottom: 120px;
    .center_cont {
      padding: 60px 0;
      &.pdt0{
        padding-top: 0;
      }
    }
    &.with-tab {
      padding-top: 0;
      margin-top: 0;
    }
    &.event_inner {
      padding-top: 44px;
      padding-bottom: 80px;
    }
  }
  .visual.img_bg + .inner {
    padding-top: 0;
  }
}
.wrap {
  width: 100%;
  .content {
    // margin-top: 88px;
    // min-height: calc(100vh - 440px);
  }
}
// tablet
@media screen and (max-width: 1024px) {
  .content {
    .inner {
      padding-top: 120px;
      padding-bottom: 90px;
      .center_cont {
        padding: 24px 0;
      }
    }
  }
}

// mobile
@media screen and (max-width: 768px) {
  .inner {
    padding: 0 24px;
  }
  .content {
    .inner {
      padding-top: 28px;
      padding-bottom: 80px;
    }
  }
  .wrap {
    .content {
      margin-top: 56px;
      min-height: calc(100vh - 474px);
    }
  }
}
