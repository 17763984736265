.qna_write, .review_write {
  max-width: 1200px;
  .form {
    .label3 {
      width: 150px;
      flex-shrink:0;
    }
    .textarea_wrap textarea, .ck-editor{
      height: 300px;
      width: 100%;
      border : 0;
      resize: none;

      .ck-editor__main {
        height: 250px;

        .ck-editor__editable {
          height: 100%;
        }

        .ck-content img {
          max-width: 100%; 
          height: auto;
        }
      }
    }
    .input_checkbox_basic{
      margin: 20px 0;
    }
  }
  .btn_layout {
    margin: 20px 0;
  }

  @media screen and (max-width: 768px) {     
    .form_wrap {
      &.line_ty {
        textarea {
          @include typo_body1;
          width: calc(100% - 92px);
          font-weight: 700;
          &::placeholder {
            @include typo_body1;
          }
        }
      }
      .label3 {
        width:100px;
      }
    }
  }
}

.qna_write, .review_write{
  .form_wrap {
    .input_wrap{  
      align-items: center; 
      padding-bottom:5px;
      .textarea_txt{
        font-family: pretendard, 'Pretendard Variable', 'Pretendard-Regular', sans-serif;
        font-size:20px;        
      }  
      .input_txt2,.textarea_txt {
        flex-grow:1;
        width:calc(100% -150px);
      }   
    }
  }
  .form_wrap + .form_wrap{
    margin-top:10px;
  }
  .form_wrap.typeTop{
    .input_wrap{ 
      align-items:start;
      .label3{
        margin-top:3px;
      }
    }
  }
}

.qna_detail, .review_detail{
  img {
    width: auto !important;
    height: auto !important;
    max-width: 100%; 
  }
}

//댓글작성
.comment-section{
  .comment-form{
    h4 { display:none}
    .comment-form-box{
      display:flex;
      width:100%;
      justify-content:space-between;
      gap:3px;
      .comment-input{
        flex-grow:1;
        display:flex;
        justify-content: space-between;
        gap:3px;

        .ck-editor { 
          flex-grow:1;
          min-height: 150px;
          max-height: 250px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
        }

        .ck-editor__main {
          flex-grow: 1;
          overflow-y: auto;
          .ck-editor__editable_inline{
            min-height:110px;
          }
        }
        
        .input01{
           display:flex;
           flex-direction: column;
           gap:3px;
           width:20%;
           max-width:300px;
           input{
            width:100%;
            height:50px;
            line-height: 50px;
            background:#f5f8fa;
            padding:0 15px;
            border:0;
            border-radius:8px;
           }
        }
        textarea{
          flex-grow: 1;
          background:#f5f8fa;
          border:0;
          border-radius:8px;
          padding:10px 15px;
          box-sizing: border-box;
          height:103px;
          overflow-y: auto;
        }
      }
      .submitBtn{
        width:110px;
        height:103px;
        color:#fff;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius:8px;
        }
    }
  }
  
  .comment-list{
    margin-top:30px;
    h4{
      font-size:20px;
      font-weight:700;
      border-bottom:1px solid #4f555e;
      padding-bottom:10px;
    }
    .comment-item {
      padding:20px 0 25px;
      border-bottom:1px solid #f5f8fa;
      position:relative;
      .comment-header{
        display:flex;
        justify-content: space-between;
        width:100%;  
        margin-bottom:5px;              
        .comment-info {
          display:flex;
          span {
            font-size:16px;
            color:#888;
          }
          .comment-date {
            display:flex;
            align-items: center;
          }
          .comment-date:before{
            content:'';
            display:block;
            width:1px; 
            height:12px;
            background:#efefef;
            margin:0 10px;
          }
        }        
      }
      .comment-content{
        font-size:18px;
        color:#010101;
        line-height: 150%;
      }
      .edit-comment-box{
        .edit-comment-input{          
          width:100%;
          background:#f5f8fa;
          border:0;
          border-radius:8px;
          padding:10px 15px;
          box-sizing: border-box;
        }

        .ck-editor { 
          flex-grow:1;
          min-height: 150px;
          max-height: 250px;
          overflow-y: auto;
          display: flex;
          flex-direction: column;
        }

        .ck-editor__main {
          flex-grow: 1;
          overflow-y: auto;
          .ck-editor__editable_inline{
            min-height:110px;
          }
        }
      }
      .comment-actions{
        position:absolute;
        right:0;
        top:15px;
        display:flex;
        gap:3px;
        button{
          background:#ececec;
          border:0;
          border-radius:3px;
          padding:0 10px;
          color:#010101;
          font-size:16px;
          height:30px;
          line-height:30px;
          display:flex;
          justify-content: center;
          align-items: center;
          gap:3px;
          &.modify:before{
            background: url('../../images/ico/btn_mod_ico.png') no-repeat center;
            background-size: contain;
            content:'';
            width:14px;
            height:14px;
          }
          &.delete:before{
            background: url('../../images/ico/btn_del_ico.png') no-repeat center;
            background-size: contain;
            content:'';
            width:14px;
            height:14px;
          }
          &.edit{
            background:#222e59;
            color:#fff;
          }
          &.cancel{
            background:#999;
            color:#fff;
          }
        }
      }
    } 
  }

  .btn-group{
    display:flex;
    justify-content: center;
    gap:3px;
    margin-top:8px;
    button{
      background: #222e59;
      color: #fff;
      cursor: pointer;
      height: 44px;
      line-height: 44px;
      border-radius: 3px;
      padding:0 20px;
      &.cancel-btn{
        background:#999;        
      }
    }
  }
}
 
@media screen and (max-width: 1080px) {      
  .comment-section{
    .comment-form{
      .comment-form-box{  
        flex-direction: column;      
        .comment-input{
          flex-direction: column;            
          .input01{
             flex-direction: row;
             gap:2px;
             width:100%;
             max-width:100%;
             input{
              width:calc(50% - 1px);
              height:40px;
              line-height: 40px;
              padding:0 10px;
              border:0;
              border-radius:3px;
             }
          }
          textarea{
            border-radius:3px;
            padding:10px;
            height:80px;
            width:100%;
          }
        }
        .submitBtn{
          width:100%;
          height:40px;
          border-radius:3px;
          margin:0 auto;
          }
      }
    }
    
    .comment-list{
      h4{
        font-size:16px;
      }
      .comment-item {
        padding:15px 0 20px;
        .comment-header{
          flex-direction:column;             
          .comment-info {
            display:flex;
            span {
              font-size:14px;
            }
          }          
        }
        .comment-content{
          font-size:16px;
        }
        .edit-comment-box{
          .edit-comment-input{ 
            border-radius:3px;
            padding:10px;
          }         
        }
        .comment-actions{
          position:relative;
          top:0;
          margin-top:8px;
          button{
            font-size:14px;
            height:26px;
            line-height:26px;
            &.modify:before{
              background: url('../../images/ico/btn_mod_ico.png') no-repeat center;
              background-size: contain;
              content:'';
              width:10px;
              height:10px;
            }
            &.delete:before{
              background: url('../../images/ico/btn_del_ico.png') no-repeat center;
              background-size: contain;
              content:'';
              width:10px;
              height:10px;
            }            
          }
        }
      } 
    }    
  }   
}
.box_checkbox.flexTop { align-items:flex-start; }
.box_checkbox.flexTop label { margin-top: -3px; }
.box_checkbox.flexTop label .point { color:red}

.ico_admin { 
  display:inline-block; 
  width:40px; height:24px; 
  line-height:24px; 
  text-align:center; 
  font-size:14px; 
  font-weight:400; 
  color:#222e59; 
  background:#e8eaee; 
  vertical-align:middle; 
  margin-right:3px;
}