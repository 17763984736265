@mixin taleAccordion {
  .table_wrap {
    table {
      td {
        padding: 0;

        dl {
          padding-bottom: 0;
        }

        dt {
          padding: 4px 12px;
          height: 31px;
          line-height: 165%;

          &+dd {
            margin-top: 12px;
          }
        }

        dd {
          +dt {
            margin-top: 28px;
          }
        }

        .tlt {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          margin: 0;

          i {
            transition: transform 0.2s ease-in-out;
          }

          &+dl {
            margin-top: 0;
          }

          &.active {
            i {
              transform: rotate(180deg);
            }
          }
        }
      }

      dl {
        overflow: hidden;
        height: 0;
        transition: height 0.2 ease-in-out;

        &.active {
          height: 100%;
          padding-bottom: 16px;
        }
      }

      tbody {
        tr {
          &:last-of-type {
            dt {
              +dd {
                margin-top: 0;
              }
            }

            dl {
              padding-bottom: 0;

              &.active {
                height: 100%;
                padding-bottom: 16px;
              }
            }
          }
        }
      }
    }

    .info {
      width: 100%;

      li {
        text-indent: -8px;
      }
    }

    [class*='btn'] {
      order: 3;
    }
  }
}

.default_price {
  .tab-head {
    width: calc(100vw - 10px);
    position: absolute;
    left: 50%;
    margin-left: -50vw;
    overflow: hidden;
    margin-top: 32px;
    margin-bottom: 36px;
  }

  .btn_layout {
    margin-top: 170px;
  }

  .btn_second_m {
    flex-shrink: 0;
  }

  .table_wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    gap: 24px 0;

    table {
      tbody {}

      th+th {
        border-left: 1px solid $color_gray8;
      }

      td {
        vertical-align: baseline;
        text-align: left;

        &+td {
          border-left: solid 1px #d5d8db;
        }

        .tlt {
          display: block;
          width: 200px;
          margin-top: 28px;
          font-weight: 700;
          font-size: 28px;
          line-height: 145%;
        }

        .option {
          margin-top: 32px;
          font-weight: 400;
          font-size: 18px;
          line-height: 150%;
          word-break: keep-all;
        }

        .price {
          margin-top: 8px;
          font-weight: 700;
          font-size: 28px;
          line-height: 145%;
          color: #4876ee;

          span {
            display: block;
            font-weight: 400;
            font-size: 14px;
            line-height: 140%;
            color: #4876ee;
            word-break: keep-all;
          }
        }

        dl {
          padding-bottom: 40px;
        }

        dt {
          display: inline-block;
          font-weight: 700;
          font-size: 18px;
          line-height: 150%;

          &+dd {
            margin-top: 16px;
          }
        }

        dd {
          font-weight: 400;
          font-size: 16px;
          line-height: 165%;

          &+dt {
            margin-top: 24px;
          }
        }

        .packages {
          padding: 0;

          li {
            width: 100%;
          }
        }
      }
    }
  }

  .info {
    margin-top: 32px;
    text-align: left;

    .sub {
      color: #727272;
    }
  }

  .price_infor {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #4f555e;

    .info {
      text-align: left;
    }

    .btn_primary_m {
      white-space: nowrap;
    }

    .btn_primary_s {
      white-space: nowrap;
      flex-shrink: 0;
    }
  }

  [data-contain='cargo'] {
    display: block;
  }

  [data-contain='special'] {
    display: none;
  }

  [data-table1='premium'] {
    display: block;
  }

  [data-table1='long'] {
    display: none;
  }

  [data-table1='wing'] {
    display: none;
  }

  [data-table2='premium'] {
    display: block;
  }

  [data-table2='long'] {
    display: none;
  }

  [data-table2='wing'] {
    display: none;
  }

  @media screen and (max-width: 1360px) {
    .packages {
      & li:last-of-type {
        b {
          display: block;
          text-indent: 144px;
        }
      }
    }
  }

  // tablets
  @media screen and (max-width: 1080px) {

    // .table_wrap {
    //   table {
    //     td {
    //       .tlt {
    //         display: flex;
    //         align-items: center;
    //         justify-content: space-between;
    //       }
    //     }
    //   }
    // }
    .pc_view {
      display: none !important;
    }

    .mo_view {
      display: block !important;
    }

    .cont {
      border-top: 2px solid #000;
      padding-top: 24px;
      text-align: left;

      .tit_wrap {
        .tlt {
          font-weight: 700;
          font-size: 24px;
          line-height: 140%;
        }

        .option {
          margin-top: 32px;
          font-weight: 400;
          font-size: 14px;
          line-height: 150%;
        }

        .price {
          margin-top: 8px;
          font-weight: 700;
          font-size: 24px;
          line-height: 140%;
          color: #4876ee;

          span {
            display: block;
            font-weight: 400;
            font-size: 12px;
            line-height: 140%;
            color: #4876ee;
          }
        }
      }

      .sub_tlt {
        display: block;
        margin-top: 32px;
        padding-top: 32px;
        font-weight: 500;
        font-size: 18px;
        line-height: 145%;
        border-top: 1px solid #dedede;

        &+.list_dl2 {
          margin-top: 32px;
        }
      }

      .list_dl2 {
        &+.list_dl2 {
          margin-top: 32px;
        }

        dt {
          font-weight: 700;
          font-size: 15px;
          line-height: 145%;
        }

        dd {
          margin-top: 16px;
          font-weight: 400;
          font-size: 15px;
          line-height: 165%;
        }
      }

      &+.info {
        border-top: 1px solid #dedede;
        padding-top: 32px;

        .sub {
          color: #727272;
        }
      }

      .packages {
        & li:last-of-type {
          b {
            display: inline-block;
            text-indent: inherit;
          }
        }
      }
    }
  }
}

/*////////////////////// 20241022 t4k 특장 페이지 생성 //////////////////////*/

.features_wrap {
  .visual {
    .bg_img {
      background: url(../../images/img/img_visual_feature.jpg) no-repeat 50%;
      background-size: cover;
    }
  }
  .tab-head {
    margin-bottom: 0px;
  }
  .features_tab {
    * {
      word-break: keep-all;
    }
  }
}

/* visual_title ( 비주얼 + 타이틀)*/
.visual_title {
  width: 100%;
  max-width: 1800px;
  margin: 60px auto;
  display: flex;
  align-items: center;
  aspect-ratio: 1800 / 850;
  position:relative;
  .visual_tit_img{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    &.t4k_cold {
      background: url(../../images/features/cold_visual.jpg) no-repeat 50%;
      background-size: cover;
    }  
    &.t4k_box {
      background: url(../../images/features/box_visual.jpg) no-repeat 50%;
      background-size: cover;
    }  
    &.t4k_etc {
      background: url(../../images/features/etc_visual.jpg) no-repeat 50%;
      background-size: cover;
    }  
  }
  .inner_box {
    text-align: right;
    padding:62px 24px;
    width:100%;
    max-width:1600px;
    margin:0 auto;    
    .title {
      font-size: 60px;
      line-height: 1.25;
      color: #fff;
      font-weight: 700;  
      span {
        display: block;
        font-weight: 300;
        color: #fff;
        font-size:58px;
      }
    }  
    .desc {
      font-size: 20px;
      line-height: 1.5;
      color: #fff;
      margin-top:36px;
    }
  }  
}
@media screen and (max-width: 1600px) {
  .visual_title {    
    margin: 0 auto;  
    .inner_box {
      .title {
        font-size: 48px;
        line-height:120%;
        span{
          font-size:46px;
        }
      }  
      .desc {
        font-size: 18px;
        line-height: 27px;
      } 
    }  
  }
}
@media screen and (max-width: 768px) {
  .visual_title {    
    margin: 0 auto;
    flex-wrap:wrap; 
    align-items:end;  
    aspect-ratio: 1080 / 800; 
    .visual_tit_img{      
      width:100%;
      &.t4k_cold {
        background: url(../../images/features/cold_visual_m.jpg) no-repeat 50%;
        background-size: cover;
      }  
      &.t4k_box {
        background: url(../../images/features/box_visual_m.jpg) no-repeat 50%;
        background-size: cover;
      }  
      &.t4k_etc {
        background: url(../../images/features/etc_visual_m.jpg) no-repeat 50%;
        background-size: cover;
      }  
    }
    .inner_box {
      text-align:center;
      padding:24px;
      width:100%;
      z-index:2;
      position:relative;      
      .title {
        font-size: 30px;
        line-height:120%;
        // color:#050d1b;
        span{
          // color:#050d1b;
          font-size:28px;
        }
      }  
      .desc {
        font-size: 16px;
        line-height:140%;
        margin-top:20px;
        // color:#4f555d;
        br{
          display:none;
        }
      }
    } 
    .inner_box:before { 
      position:absolute;
      top:0; 
      left:0;
      content:'';
      display:block;
      width:100%;
      height:100%;
      background: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }   
  }
}
@media screen and (max-width: 560px) {
  .visual_title {  
    aspect-ratio: 1080 / 1140;     
    }
}    

.sectionDiv {
  width: 100%;
  overflow: hidden;  
  .inner_box{
    width:100%;
    max-width:1600px;
    margin:0 auto;
    padding:140px 40px;  
    &.pdt0{
      padding-top:0 !important;
    }  
  }
  .title_wrap {
    .title {
      color: #050d1b;
      font-size: 60px;
      line-height: 1.3;
      margin-bottom: 62px;
      font-weight:700;
      &.center{
        text-align:center;
      }
    }
    .tit_s {
      font-size: 36px;
      color: #050d1b;
      font-weight: 700;
      display: block;
      line-height: 1.3;
      &.border_line {
        border-bottom: 1px solid #050d1b;
        padding-bottom: 10px;
        margin-bottom:1px;
      }
    }
    p {
      color: #0d0d12;
      font-size: 20px;
      line-height: 1.6;
      margin-top: 20px;
    }
    &.controller_txt {
      .title,
      .tit_s,
      p {
        color: #fff;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .sectionDiv {  
    .inner_box{
      padding:100px 40px;
    }
    .title_wrap {
      .title {
        font-size: 48px;
        margin-bottom:50px;
      }  
      .tit_s {
        font-size: 30px; 
      }  
      p {
        font-size: 16px;
      }        
    }
  }
} 
@media screen and (max-width: 768px) {
  .sectionDiv {
    .inner_box{
      padding:60px 24px;
    }  
    .title_wrap {
      text-align:center !important;
      .title {
        font-size: 30px;
        margin-bottom:24px;
      }  
      .tit_s {
        font-size: 20px; 
      }  
      p {
        font-size: 16px;
      }        
    }
  }
} 

/* 냉동탑차 특장점 */
.cold_feature {
  background: #eceff2;
  .features_slider {
    .features_swiper {
      margin-left: -52px;
      overflow: visible;
      display: flex;
      align-items: stretch;

      .section_features_slide {
        width: 540px;
        margin-right: 32px;
        padding: 56px 56px 105px 56px;
        height: auto;

        @for $number from 1 through 5 {
          &:nth-child(#{$number}) {
            background:#fff url(../../images/features/icon_cold_f0#{$number}.png) no-repeat;
            background-size: 72px;
            background-position: right 56px bottom 46px;
          }
        }
        h3 {
          font-size: 36px;
          line-height: 1.3;
          color: #050d1b;
          font-weight: 700;
          margin-bottom: 36px;
          word-break: keep-all;
        }
        p {
          font-size: 18px;
          line-height: 1.6;
          color: #4f555d;
          word-break: keep-all;
          em {
            font-size: 28px;
            font-weight: 600;
          }
          small {
            font-size: 16px;
            color: #4f555d;
          }
        }
      }
    }

  }
}
@media screen and (max-width: 1600px) {
  .cold_feature {
    .features_slider {
      .features_swiper {
        margin-left: -32px;
        .section_features_slide {
          width: 460px;
          margin-right: 24px;
          padding: 40px 32px 90px 32px;  

          @for $number from 1 through 5 {
            &:nth-child(#{$number}) {
              background:#fff url(../../images/features/icon_cold_f0#{$number}.png) no-repeat;
              background-size: 60px;
              background-position: right 32px bottom 26px;
            }
          }
          h3 {
            font-size: 30px;
          }  
          p {
            font-size: 16px;  
            em {
              font-size: 24px;
            }  
            small {
              font-size: 14px;
            }
          }
        }       
      }  
    }
  }
}
@media screen and (max-width:768px) {
  .cold_feature {
    .features_slider {
      .features_swiper {
        margin-left: 0px;
        .section_features_slide {
          width: 296px;
          padding: 24px 24px 70px 24px;  

          @for $number from 1 through 5 {
            &:nth-child(#{$number}) {
              background:#fff url(../../images/features/icon_cold_f0#{$number}.png) no-repeat;
              background-size: 50px;
              background-position: right 24px bottom 20px;
            }
          }
          h3 {
            font-size: 26px;
            margin-bottom:24px;
          }  
          p {
            font-size: 14px;  
            em {
              font-size: 20px;
            }  
            small {
              font-size: 13px;
            }
          }
        }
      }  
    }
  }
}  

/* 냉동탑차 보다 편리한 충전 */
.byd_controller {
  background: #fff url(../../images/features/byd_bg.jpg) no-repeat 50%;
  background-size: cover;
  .inner_box {
    display: flex;
    justify-content: space-between;
    .controller_txt {
      width: 50%;
      padding-right: 100px;
    }
    .controller_img_wrap {
      width: 50%;
      flex-shrink: 0;
      padding:56px;  
      background:#0d0d12;   
      .controller_img{
        width:100%;
        max-width:656px;
        margin:0 auto;
        aspect-ratio: 656/503;
        background:url(../../images/features/byd_img.jpg) no-repeat 50%;
        background-size:100%;
      }      
    }
  }
}
@media screen and (max-width: 1600px) {
  .byd_controller {
    .inner_box {
      .controller_txt {
        padding-right: 50px;
      }
    }
  }  
}
@media screen and (max-width:1080px) {
  .byd_controller {
    .inner_box {
      flex-wrap:wrap;
      .controller_txt {
        width: 100%;
        padding-right: 0;
      }
      .controller_img_wrap {
        width:100%;
        margin:48px auto 0; 
        padding:24px;
      }
    }
  }  
}

/* 냉동탑차 압도적인 냉동 성능 */
.cold_performance {
  background: #f4f4f4;
  .inner_box {
    display: flex;
    justify-content: space-between;
    .cold_per_title {
      width: 50%;
      padding-right: 100px;
    }
    .cold_per_desc {
      width: 50%;
      flex-shrink: 0;
      .cold_per_box {
        background: #fff;
        padding: 36px;
        &.first {
          margin-bottom: 60px;
        }
        .descBox {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 40px;
          margin-bottom: 20px;
          p {
            font-size: 18px;
            em {
              font-size: 30px;
              font-weight: 500;
            }
          }
          .arw {
            background: url(../../images/features/cold_p_arw.png) no-repeat right bottom;
            background-size: auto 22px;
            flex-grow: 1;
            max-width: 370px;
            height: 34px;
            text-align: center;
          }
          .time {
            display: flex;
            align-items: center;
            gap: 15px
          }
          .time:before {
            content: '';
            display: block;
            width: 88px;
            aspect-ratio: 1/1;
            background: url(../../images/features/cold_p_time.png) no-repeat 50%;
            background-size: 100%;
          }
          .energy {
            display: flex;
            align-items: center;
            gap: 15px
          }
          .energy:before {
            content: '';
            display: block;
            width: 88px;
            aspect-ratio:1/1;
            background: url(../../images/features/cold_p_elet.png) no-repeat 50%;
            background-size: 100%;
          }
          .temp {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
          }
          .temp:before {
            content: '';
            display: block;
            width: 56px;
            aspect-ratio:56/66;
            background: url(../../images/features/cold_p_temp.png) no-repeat 50%;
            background-size: 100%;
          }
          .energy01 {
            display: flex;
            align-items: center;
            gap: 15px
          }
          .energy01:before {
            content: '';
            display: block;
            width: 92px;
            aspect-ratio:92/86;
            background: url(../../images/features/cold_p_elet_2.png) no-repeat 50%;
            background-size: 100%;
          }
        }
        .addText {
          color: #4f555d;
          font-size:18px;
          margin-top: 30px;
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .cold_performance {  
    .inner_box {  
      .cold_per_title {
        width: 50%;
        padding-right: 50px;
      }  
      .cold_per_desc {  
        .cold_per_box {
          padding: 24px;  
          &.first {
            margin-bottom: 40px;
          }  
          .descBox {
            gap:30px;
            margin-bottom: 20px;  
            p {
              font-size: 16px;  
              em {
                font-size: 24px;
              }
            }  
            .arw {
              background: url(../../images/features/cold_p_arw.png) no-repeat right bottom;
              background-size: auto 20px;
              text-align: center;
            }  
            .time {
              gap: 10px
            }  
            .time:before {
              width: 72px;
            }  
            .energy {
              gap: 10px
            }  
            .energy:before {
              width: 72px;
            }  
            .temp {
              gap:10px;
            }  
            .temp:before {
              width: 46px;
            }  
            .energy01 {
              gap: 10px
            }  
            .energy01:before {
              width: 72px;
            }
          }  
          .addText {
            font-size:16px;
          }
        }
      }
    }
  }  
}
@media screen and (max-width:1080px) {
  .cold_performance {  
    .inner_box { 
      flex-wrap:wrap; 
      .cold_per_title {
        width:100%;
        padding-right:0;
        margin-bottom:40px;
      }  
      .cold_per_desc { 
        width:100%; 
        .cold_per_box {
          padding: 24px;  
          &.first {
            margin-bottom: 40px;
          }  
          .descBox {
            gap:30px;
            margin-bottom: 20px;  
            p {
              font-size: 16px;  
              em {
                font-size: 24px;
              }
            }  
            .arw {
              background: url(../../images/features/cold_p_arw.png) no-repeat right bottom;
              background-size: auto 20px;
            }  
            .time {
              gap: 10px
            }  
            .time:before {
              width: 72px;
            }  
            .energy {
              gap: 10px
            }  
            .energy:before {
              width: 72px;
            }  
            .temp {
              gap:10px;
            }  
            .temp:before {
              width: 46px;
            }  
            .energy01 {
              gap: 10px
            }  
            .energy01:before {
              width: 72px;
            }
          }  
          .addText {
            font-size:16px;
          }
        }
      }
    }
  }  
}
@media screen and (max-width:768px) {
  .cold_performance {  
    .inner_box { 
      .cold_per_title {
        margin-bottom:24px;
      }  
      .cold_per_desc { 
        .cold_per_box {
          &.first {
            margin-bottom: 24px;
          }  
          .descBox {
            margin-bottom: 20px;  
            flex-wrap:wrap;
            p {
              font-size: 14px;  
              em {
                font-size: 20px;
              }
            }  
            .arw {
              height:26px;
              background: url(../../images/features/cold_p_arw.png) no-repeat right bottom;
              background-size: auto 14px;
            }  
            .time {
              gap: 10px;
            }  
            .time:before {
              width: 60px;
            }  
            .energy {
              gap: 10px
            }  
            .energy:before {
              width: 60px;
            }  
            .temp {
              gap:10px;
            }  
            .temp:before {
              width: 46px;
            }  
            .energy01 {
              gap: 10px;
            }  
            .energy01:before {
              width: 60px;
            }
          }  
          .addText {
            font-size:14px;
          }
        }
      }
    }
  }  
}
@media screen and (max-width:560px) {
  .cold_performance {  
    .inner_box {
      .cold_per_desc { 
        .cold_per_box {            
          .descBox {              
            .time {
              width:100%;
              gap: 10px;
            }                
            .energy {
              width:100%;
              gap: 10px
            }                              
            .energy01 {
              gap: 10px;
              width:100%;
            }             
          }            
        }
      }
    }
  }  
}

/* 제원 및 가격 */
.spec_section {
  background: #fff;
  .inner_box {
    max-width:1800px;
    &.narrow{
      max-width:1600px;
      margin:0 auto;
    }
  }
  .spec_wrap {
    display: flex;    
    gap: 60px;
    .specImgWrap {      
      border: 1px solid #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      padding:36px 24px;
    }
    /* 냉동탑차 */
    &.cold_spec{
      .specImgWrap {
        width:40%;
        .specImg {
          width: 100%;
          max-width: 392px;
          aspect-ratio: 392/478;
          background: url(../../images/features/cold_spec_img.png) no-repeat 50%;
          background-size:contain;
        }
      }
    }
    /* 내장탑차 */
    &.box_spec {
      flex-wrap:wrap;
      .specImgWrap {
        width:100%;
        gap:50px;
        padding:56px 24px;
        @for $number from 1 through 2 {
          .specImg#{$number} {
            width:100%;
            max-width: 608px;
            aspect-ratio: 608 / 302;
            background: url(../../images/features/box_spec_img#{$number}.png) no-repeat 50%;
            background-size: contain;
          }
        }
      }
    }
    .specTableWrap {
      flex-grow:1;
      .specTitle {
        font-size: 26px;
        font-weight:500;
        padding-bottom: 10px;
      }
      .table_scroll_wrap {
        table {
          th{
            text-align:center;
            font-size:16px;
            font-weight:400;
          }
          td{
            text-align:center;
            font-weight:600;
          }
          thead {            
            th:not(:last-child) {
              border-right: 1px solid #dedede;
            }
            .borderRight{
              border-right: 1px solid #dedede !important;
            }
          }
          tbody {
            td:not(:last-child) {
              border-right: 1px solid #dedede;
            }
            .borderRight{
              border-right: 1px solid #dedede !important;
            }
          }
          .remarks{
            li{
              text-align:left;
            }
            li + li {
              margin-top:5px;
            }
          }
        }
      }
      .table_scroll_wrap + .specTitle, 
      .table_scroll_wrap + .table_scroll_wrap { 
        margin-top:30px;
      }
    }
  } 
}
@media screen and (max-width: 1600px) {   
  .spec_section {    
    .spec_wrap {      
      flex-wrap:wrap;
      &.cold_spec{
        .specImgWrap {
          width:100%;
          .specImg {
            max-width: 300px;
          }
        }
      } 
      /* 내장탑차 */
      &.box_spec {
        .specImgWrap {
          gap:24px;
          padding:56px 24px;          
        }
      }     
      .specTableWrap {
        width:100%;   
        .specTitle {
          font-size: 20px;
        } 
      }
    }    
  } 
}
@media screen and (max-width: 1080px) {   
  .spec_section {    
    .spec_wrap {      
      gap: 30px;
      /* 내장탑차 */
      &.box_spec {
        .specImgWrap {
          gap:12px;
          padding:36px 24px;          
        }
      } 
      .specTableWrap {
        .table_scroll_wrap {
          margin-left:0 !important;
          table {
            th,td{
              padding:12px;
              font-size:14px;
            }
          }
        }
      }
    }
  } 
}
@media screen and (max-width: 768px) {   
  .spec_section {    
    .spec_wrap {
      .specImgWrap {
        padding:36px;   
      }
      &.cold_spec{
        .specImgWrap {
          .specImg {
            max-width: 260px;
          }
        }
      } 
      /* 내장탑차 */
      &.box_spec {
        .specImgWrap {
          flex-wrap:wrap;
          gap:30px;
          padding:36px 24px; 
          @for $number from 1 through 2 {
            .specImg#{$number} {
              max-width: 400px;
            }
          }        
        }
      } 
      .specTableWrap {
        width:100%;   
        .specTitle {
          font-size: 18px;
        } 
        .table_scroll_wrap {
          table {
            min-width:768px;
            th,td{
              padding:10px;
              font-size:14px;
            }
          }
        }
      }
    }
  } 
}

/* 내장탑차, 기타특장차 특장점 특징,분야 */
.features_section{  
  background:#eceff2;
  .flexBox {
    display:flex;
    > .title_wrap{
      width:50%;
      padding-right:100px;
    } 
  }
  .box_features {
    width:50%;
    display:flex;
    justify-content: space-between;
    gap:60px;
    flex-wrap:wrap;
    .box_features_box{
      width:100%;   
      .box_features_list{
        margin-top:0; 
        background:#ffff;
        padding:36px;
        li{
          display:flex;
          gap:8px;  
          color:#4f555d;
          font-size:20px;
          line-height:140%;   
          font-weight:300;   
        }
        li:before {
          content: '';
          width:3px;
          height:3px;
          display:block;
          background:#333;
          border-radius:50%; 
          margin-top:11px;  
          margin-left:2px;     
        }
        li + li {
          margin-top:10px; 
        }
      }
    }   
  }   
}

@media screen and (max-width: 1600px) { 
  .features_section{
   .flexBox {
      > .title_wrap{
        padding-right:50px;
      } 
    }
    .box_features {      
      gap:40px;
      .box_features_box{        
        .box_features_list{
          li{
            font-size:18px;  
          }
          li + li {
            margin-top:8px; 
          }
        }
      }   
    }     
  }    
}
@media screen and (max-width: 1080px) { 
  .features_section{
    .flexBox {
      flex-wrap:wrap;
      > .title_wrap{
        width:100%;
        padding-right:0px;
      } 
    }
    .box_features { 
      margin-top:24px; 
      width:100%; 
      gap:24px;
      .box_features_box{
        width:100%;
        .tit_s{
          text-align:left;
        }
        .box_features_list{
          padding:24px;
          li{
            font-size:16px;  
          }
          li:before {
            margin-top:6px;     
          }
          li + li {
            margin-top:5px; 
          }
        }
      }   
    }     
  }    
}
@media screen and (max-width: 768px) { 
  .features_section{
    .box_features { 
      .box_features_box{        
        .box_features_list{
          li{
            font-size:14px;  
          }
          li:before {
            margin-top:6px;     
          }
          li + li {
            margin-top:5px; 
          }
        }
      }   
    }      
  }    
}

/* 내장탑차, 기타특장차 특장점 슬라이드 */
.features_slide_section{          
  background:#fff;
  .box_feature_img{
    width:100%;
    aspect-ratio: 1920/800;
    background: url(../../images/features/box_feature_img.jpg) no-repeat 50%;
    background-size:cover;    
    &.wingBody{
      background: url(../../images/features/wingbody_feature_img.jpg) no-repeat 50%;
      background-size:cover; 
    }
    &.powerGate{
      background: url(../../images/features/powerGate_feature_img.jpg) no-repeat 50%;
      background-size:cover; 
    }
  }
  .box_feature_img + .inner_box {
    padding-top:0;
    margin-top:40px;
  }
  .box_features_slider {
    .box_features_swiper {
      margin-left: -52px;
      overflow: visible;
      display: flex;
      align-items: stretch;
      .section_boxf_slide {
        width: 540px;
        margin-right: 32px;
        padding:0;
        height: auto;        
        background:#f6f7f8;
        @for $number from 1 through 4 {
          &:nth-child(#{$number}) {
            .box_features_img{
              aspect-ratio: 600/372;
              background:#fff url(../../images/features/boxf_0#{$number}.jpg) no-repeat center;
              background-size:cover;
            }
          }
        }
        &.wBody{
          @for $number from 1 through 4 {
            &:nth-child(#{$number}) {
              .box_features_img{
                background:#fff url(../../images/features/wingf_0#{$number}.jpg) no-repeat center;
                background-size:cover;
              }
            }
          }
        }
        &.powerG{
          @for $number from 1 through 4 {
            &:nth-child(#{$number}) {
              .box_features_img{
                background:#fff url(../../images/features/powerf_0#{$number}.jpg) no-repeat center;
                background-size:cover;
              }
            }
          }
        }
        &.foodT{          
          width:816px;
          @for $number from 1 through 2 {
            &:nth-child(#{$number}) {
              .box_features_img{
                aspect-ratio: 1080/612;
                background:#fff url(../../images/features/foodT_0#{$number}.jpg) no-repeat center;
                background-size:cover;
              }
            }
          }
        }
        .box_features_desc{
          padding:24px 24px 36px 24px;
          h3 {        
            font-size: 28px;
            line-height: 1.3;
            color: #050d1b;
            font-weight: 700;
            margin-bottom: 16px;
            word-break: keep-all;
          }
          p {
            font-size: 18px;
            line-height: 1.6;
            color: #4f555d;
            word-break: keep-all;     
          }
        }        
      }
    }
  }   
}

@media screen and (max-width: 1600px) { 
  .features_slide_section{   
    .box_feature_img{
      aspect-ratio: 1800/850;
    }
    .box_features_slider {
      .box_features_swiper {
        margin-left: -32px;
        .section_boxf_slide {
          width: 460px;
          margin-right: 24px;
          &.foodT{          
            width:800px;           
          }
          .box_features_desc{
            h3 {  
              font-size: 22px;
              margin-bottom: 10px;
            }
            p {
              font-size: 16px;     
            }
          }           
        }
      }
    }  
  }    
}
@media screen and (max-width: 1080px) { 
  .features_slide_section{    
    .box_feature_img{
      aspect-ratio: 1800/1200;
    }
    .box_feature_img + .inner_box {
      margin-top:24px;
    }
    .box_features_slider {
      .box_features_swiper {
        margin-left: 0px;
        .section_boxf_slide {
          width: 296px;
          margin-right: 24px; 
          &.foodT{          
            width:600px;           
          }
          .box_features_desc{
            h3 {  
              font-size: 18px;
              margin-bottom: 10px;
            }
            p {
              font-size: 14px;     
            }
          }      
        }
      }
    }  
  }    
}
@media screen and (max-width: 768px) { 
  .features_slide_section{    
    .box_feature_img{
      aspect-ratio: 1800/1500;
    }
    .box_feature_img + .inner_box {
      margin-top:24px;
    } 
    .box_features_slider {
      .box_features_swiper {       
        .section_boxf_slide { 
          &.foodT{          
            width:460px;           
          }        
          .box_features_desc{
            padding:24px;
          }        
        }
      }
    }    
  }    
}
@media screen and (max-width: 768px) { 
  .features_slide_section{      
    .box_features_slider {
      .box_features_swiper {       
        .section_boxf_slide { 
          &.foodT{          
            width:calc(50% - 12px);
            min-width:296px;
          }          
        }
      }
    }    
  }    
}

/* 푸드트럭  */
.foodT_section{
  .foodT_point{
    display:flex;
    align-items:center;
    width:100%;
    .foodT_img{
      width:60%;      
    }
    .foodT_title {
      display:flex;
      width:40%;
      .foodT_title_box{  
        width:100%;
      }
    }
    &:nth-child(1){
      .foodT_title {
        order:2;
        .foodT_title_box{          
          padding-left:100px;          
        }
      }
      .foodT_img{
        order:1;
      }
    }
    &:nth-child(2){
      margin-top:80px;
      .foodT_title {
        order:1;
        justify-content:end;
        .foodT_title_box{
          padding-right:100px;
          text-align:right;          
        }
      }
      .foodT_img{
        order:2;
      }
    }

    @for $number from 1 through 2 {
      &:nth-child(#{$number}) {
        .foodT_img{
          aspect-ratio: 1000/612;
          background:#fff url(../../images/features/foodT_0#{$number}.jpg) no-repeat center;
          background-size:cover;
        }
      }
    }
  }
}
@media screen and (max-width: 1600px) { 
  .foodT_section{
    .foodT_point{      
      &:nth-child(1){
        .foodT_title {
          order:2;
          .foodT_title_box{          
            padding-left:60px;          
          }
        }
      }
      &:nth-child(2){
        margin-top:60px;
        .foodT_title {
          .foodT_title_box{
            padding-right:60px;         
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1080px) { 
  .foodT_section{
    .foodT_point{
      flex-wrap:wrap;
      .foodT_img{
        width:100%;      
      }
      .foodT_title {
        width:100%;
      }
      &:nth-child(1){
        .foodT_title {
          order:2;
          justify-content:start;
          .foodT_title_box{          
            padding-left:0;
            text-align:left;          
          }
        }
        .foodT_img{
          order:1;
        }
      }
      &:nth-child(2){
        margin-top:40px;
        .foodT_title {
          order:2;
          justify-content:start;
          .foodT_title_box{
            padding-right:0px;
            text-align:left;          
          }
        }
        .foodT_img{
          order:1;
        }
      }
    }
  }    
}

/* 탭 고정*/
.features_wrap{
  .tabs-container {
    position: relative;

    .tab-head {
      transition: all 0.3s ease;
      width: 100%;
      z-index: 1000;
      background:#fff;
    }

    .tab-head.sticky {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 1000;
    }
    .tab-head.sticky + .with-tab {
      margin-top:72px;
    }
  }
}

.btmText { padding-top:20px; font-size:18px; }
@media screen and (max-width: 768px) { 
  .btmText { font-size:16px; }
}







