.boardpop_wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  transform: translate(-50%, -50%);
  .conf_inner {
    //@include center;
    position: relative;
    z-index: 1;
    margin: auto;
    padding: 36px;
    background-color: $color_bgray10;
    border-radius: 8px;
    width: 300px;
    box-shadow: 0px 3px 3px rgba(0,0,0,0.15);
    .tlt {
      font-size: 18px;
      font-weight: 700;
      line-height: 145%;
      color: $color_black;
      text-align: center;
      margin-bottom:15px;
    }
    .input_wrap {
      align-items: center;
      justify-content: space-between;
      background-color: $color_white;
      width: 100%;
      height:44px; line-height:44px;
      border-radius:3px;
      padding:0 5px;
    }
    .btn_wrap {
      margin-top:8px;
      width:100%;
      background:#222e59;
      color:#fff;
      cursor:pointer;
      height:44px; line-height:44px;
      border-radius:3px;
    }
  }
}

.detail_pagination{ 
  margin-top:28px !important;
}

.qna_detail .board { min-height:50vh; }

.pwPopClose {
  position:absolute;
  top:12px; 
  right:12px;
  z-index:10;
}