.advance {
  //margin-top: -88px;
  max-height: 100vh;
  background-color: $color_black;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    padding: 60px;
    box-shadow: none;
    &.active {
      height: 88px !important;
    }
    &::after {
      content: none;
    }
  }
  .intro {
    position: relative;
    z-index: 0;
    background-color: #000;
    .react-player {
      video {
        object-fit: cover;
      }
    }

    > .tlt {
      @include center;
      @include typo_display1;
      color: rgba($color: $color_white, $alpha: 0.3);
    }
    .event_line {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 122px;
      background-color: rgba($color: $color_gray1, $alpha: 0.6);
      .item {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 0 32px;
        //padding-top: 16px;
        height: 100%;
        .img_wrap {
          margin-top: -37px;
          width: 182px;
          height: 114px;
          img {
            height: 100%;
            object-fit: cover;
          }
        }
        .txt_wrap {
          width: 624px;
          text-align: center;
          .tlt {
            @include typo_header5;
            display: inline-block;
            margin-top: 4px;
            width: 100%;
            color: $color_white;
          }
          .info {
            @include typo_body3;
            line-height: 155%;
            margin-top: 4px;
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }
    }
  }
  .wrap {
    height: calc(100vh * 5);
    .detail {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 8px;
      padding: 0;
      height: 100vh;
      background-color: #000000;
      .player-wrapper.insection {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }
      .item {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 80px;
        width: calc(50% - 4px);
        //max-width: 1528px;
        height: calc(100vh - 110px);
        //background-color: red;
        .item_tlt {
          @include typo_header2;
          color: $color_white;
          text-align: left;
        }
        .desc {
          width: 400px;
          text-align: left;
          .desc_tlt {
            @include typo_header3;
            color: $color_white;
          }
          .desc_txt {
            @include typo_title1;
            margin-top: 24px;
            font-weight: 400;
            color: $color_white;
          }
        }
        &.full {
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          .box-desc {
            width: 100%;
            max-width: 1528px;
          }
        }
      }
    }
    @for $detail from 1 to 6 {
      .detail:nth-child(#{$detail}) {
        .item {
          background: url('../../images/img/img_advance1_' + $detail + '.jpg')
            no-repeat
            center
            center;
          background-size: cover;
        }
      }
    }
  }

  .slide {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    //padding-left: 40px;
    width: 100%;
    height: 100vh;
    background-color: #000000;
    .item.full {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }
    ul {
      display: flex;
      flex-wrap: nowrap;
      position: relative;
      top: 40px;
      z-index: 0;
      overflow-x: auto;
      overflow-y: hidden;
      margin: 0 40px;
      padding-bottom: 40px;
      width: 100vw;
      max-width: 1528px;
      height: calc(100vh - 160px);
      .item {
        width: 900px;
        img {
          width: auto;
          height: 100%;
        }
      }
      li {
        position: relative;
      }
      &::-webkit-scrollbar {
        height: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba($color: $color_white, $alpha: 0.8);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-track {
        background-color: rgba($color: $color_white, $alpha: 0.2);
      }
    }
  }
  .inner {
    max-width: 100%;
    background-color: #000000;
    .logo_wrap {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin-top: 64px;
      .logo {
        width: 147px;
        height: 88px;
        background: url('../../images/ico/ico_logo_byd3.svg') no-repeat center;
        background-size: 147px;
      }
    }
  }

  @media screen and (max-width: 1622px) {
    .slide {
      width: 100%;
    }
  }
  // tablet
  @media screen and (max-width: 1024px) {
    header {
      padding: 60px 40px;
    }

    .intro {
      .circle_progress_wrap {
        right: 40px;
        bottom: 183px;
      }
      .event_line {
        padding: 0 40px;
        height: 110px;
        .item {
          gap: 0 40px;
          //align-items: flex-end;
          .img_wrap {
            margin-top: -60px;
            width: 182px;
            height: 114px;
          }
          .txt_wrap {
            width: calc(100% - 222px);
            .tlt {
              @include typo_title1;
              //margin-top: 8px;
              font-weight: 700;
              line-height: 140%;
            }
            .info {
              margin-top: 6px;
              font-size: 11px;
              line-height: 135%;
            }
          }
        }
      }
    }

    .wrap {
      .detail {
        padding: 0;
        .item {
          height: 435px;
          .item_tlt {
            margin: 60px 0 14px 0;
            font-size: 48px;
            line-height: 135%;
          }
          .desc {
            .desc_tlt {
              @include typo_body1;
              font-weight: 600;
            }
            .desc_txt {
              margin-top: 8px;
              color: $color_gray8;
              font-size: 20px;
              line-height: 135%;
            }
          }
        }
        @for $item from 1 to 5 {
          .item:nth-child(#{$item}) {
            .desc {
              background-size: 24px;
            }
          }
        }
      }
      @for $detail from 1 to 5 {
        .detail:nth-child(#{$detail}) {
          .item {
            //background-size: auto 80%;
            background-position: center center;
          }
        }
      }
    }

    .inner {
      .logo_wrap {
        margin-top: 0px;
        padding-bottom: 160px;
        .logo {
          width: 114px;
          height: 68px;
          background-size: 114px;
        }
      }
    }

    .slide {
      .item.full {
        position: relative;
        ul {
          margin: 0;
          position: absolute;
          top: 140px;
          bottom: 80px;
          width: 100%;
          height: auto;
        }
      }
    }
  }
  //   mobile
  @media screen and (max-width: 768px) {
    margin-top: -56px;
    .reserve_mo {
      position: fixed;
      bottom: 24px;
      left: 24px;
      z-index: 100;
      width: 100%;
      > .btn_wrap {
        > [class*='btn'] {
          padding: 18px;
          width: calc(100% - 48px);
          border: 1px solid $color_gray5;
          border-radius: 8px;
          background-color: $color_gray10;
          > span {
            font-size: 18px;
            line-height: 135%;
            font-weight: 700;
          }
        }
      }
    }
    header {
      padding: 18px 24px;
      justify-content: center;
    }
    .intro {
      .tlt {
        font-size: 40px;
      }
      .circle_progress_wrap {
        right: 24px;
        bottom: 100px;
        width: 40px;
        height: 40px;
        .ctrl {
          width: 9px;
          height: 12px;
          background-size: 9px;
          &.stop {
            width: 10px;
            height: 11px;
            background-size: 10px;
            margin-left: 1px;
          }
        }
      }
    }
    .wrap {
      .detail {
        gap: 0;
        padding: 0;
        .item {
          padding: 90px 24px 24px;
          width: 100%;
          .item_tlt {
            @include typo_header4;
            margin-top: 10px;
          }
          .desc {
            width: auto;
            .desc_tlt {
              font-size: 24px;
              line-height: 140%;
            }
            .desc_txt {
              @include typo_body1;
              margin-top: 12px;
            }
          }

          &.full {
            justify-content: flex-start;
          }
        }
        @for $item from 1 to 5 {
          .item:nth-child(#{$item}) {
            background-position: center bottom;
          }
        }
      }
    }

    .slide {
      padding: 0;
      height: 100vh;
      .item.full {
        ul {
          top: 90px;
          bottom: 130px;
          .item {
            width: 100vw;
          }
        }
      }
    }
    .inner {
      .logo_wrap {
        margin-top: 0px;
        padding: 0px 0 170px;
        .logo {
          width: 63px;
          height: 38px;
          background-size: 63px;
        }
      }
    }
    .popup_wrap.reserve .form_wrap .select_layout::after {
      content: none;
    }
  }
  section[data-snap] {
    scroll-snap-align: start;
  }
  .select_layout {
    display: flex;
    .select {
      width: 45% !important;
      min-width: 0;
    }
  }
}

.noscroll {
  scroll-snap-type: y mandatory;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.noscroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera*/
}
