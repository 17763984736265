.privacy {
  @include typo_body1;
  color: #000;
  h3 {
    margin-top: calc(40px - 8px);
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 24px;
    line-height: 160%;
  }
  p {
    margin-bottom: 8px;
  }
  div {
    margin-top: 8px;
    margin-left: 8px;
    p {
      margin-bottom: 0;
      font-size: 16px;
      line-height: 150%;
      color: #4f555e;
    }
  }
  ol {
    list-style: decimal;
    li {
      margin-bottom: 8px;
      margin-left: 20px;

      .table_wrap {
        margin-left: 0;
        margin-left: -18px;
        margin-bottom: 18px;
        .table_scroll_wrap {
          margin-left: 0;
        }
        table {
          margin-top: 18px;
        }
      }
    }
  }

  /*20230605*/
  .mlTit { margin-top:50px; margin-bottom:10px; display:inline-block; }
  .markLabelList { display:flex; flex-wrap:wrap; gap:10px 10px; margin:0; }
  .markLabelList .markLabel { width:calc(100%/6 - 50px/6); border:1px solid #efefef; padding:17px; }
  .markLabelList .markLabel .labelTit { font-weight:700; color:#000; margin-bottom:15px; text-align:center; font-size:18px; }
  .markLabelList .markLabel .lableIcon { width:100px; height:100px; margin:0 auto 15px; display:block;  }  
  .markLabelList .markLabel .icon01 { background:url(../../images/ico/pv_icon01.gif)no-repeat center; background-size:contain; }
  .markLabelList .markLabel .icon02 { background:url(../../images/ico/pv_icon02.gif)no-repeat center; background-size:contain; }
  .markLabelList .markLabel .icon03 { background:url(../../images/ico/pv_icon03.gif)no-repeat center; background-size:contain; }
  .markLabelList .markLabel .icon04 { background:url(../../images/ico/pv_icon04.gif)no-repeat center; background-size:contain; }
  .markLabelList .markLabel .icon05 { background:url(../../images/ico/pv_icon05.gif)no-repeat center; background-size:contain; }
  .markLabelList .markLabel .icon06 { background:url(../../images/ico/pv_icon06.gif)no-repeat center; background-size:contain; }
  .markLabelList ul li { padding-left:12px; position:relative; color:#000; font-size:15px; }
  .markLabelList ul li:before { content:''; display:block; width:3px; height:3px; background:#333; border-radius:50%; 
    position:absolute; top:10px; left:0; }
  .markLabelList .etcText { margin-top:5px; font-size:14px; color:#777; }
  .markLabelList ul li small { font-size:14px; color:#777; }
  .smallFont { font-size:14px; color:#777; display:inline-block; margin-top:5px; }
  
  @media screen and (max-width: 1440px) {
    .markLabelList .markLabel .labelTit { font-size:16px; }
    .markLabelList .markLabel .lableIcon { width:80px; height:80px; }
  }
  @media screen and (max-width: 1280px) {
    .markLabelList .markLabel { width:calc(100%/3 - 20px/3);}
  }
  @media screen and (max-width: 640px) {
    .markLabelList .markLabel { width:calc(100%/2 - 10px/2); padding:10px; }
  } 
}
